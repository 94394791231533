import BaseListPresenter from "../../base/BaseListPresenter";
import { getCurrentUserUseCase } from "../../usecases/user";
import {
  format,
  startOfDay,
  addHours,
  addMinutes,
  addDays,
  parseISO,
} from "date-fns";

class CustomerPresenter extends BaseListPresenter {
  init() {
    this.store = [];
    this.limit = 2000000;
    this.where = {};
    this.search = {};
    this.filter = {};
    this.filterDate = {};
    this.filterStore = {};
    this.include = ["all"];
    this.keys = undefined; // if keys are specified, only those keys will be returned
    this.sort = { createdAt: -1 };
    this.progress = true;
    this.reset();
  }

  createQuery() {
    const skip = (this.current - 1) * this.limit;
    const query = {
      limit: this.limit,
      skip: skip,
      where: {
        ...this.where,
        ...this.search,
        ...this.filter,
        ...this.filterDate,
      },
      include: this.include,
    };
    if (this.sort) {
      query.sort = this.sort;
    }
    // const keys = this.keys || this.view.getKeys() || [];
    // if (keys.length > 0) {
    //   query.keys = keys;
    // }
    return query;
  }

  async findObjects() {
    // Fetch the current user and store information
    try {
      const user = await getCurrentUserUseCase().execute();
      const storeQuery = {
        include: ["all"],
        where: { email: user.email },
      };
      this.store = await this.findObjectUseCase.execute("users", storeQuery);
      // const store = this.store[0].storeAnalytic;
      const store = this.store[0].storeAnalytic.sort((a, b) => {
        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0; // names must be equal
      });
      this.view.setStore(store);
    } catch (error) {
      console.error("Error fetching user or store data:", error);
      return;
    }

    // Prepare the query for finding objects
    const skip = (this.current - 1) * this.limit;
    const storeCodes = this.store?.flatMap((storeItem) =>
      storeItem.storeAnalytic?.map((analytic) => analytic?.name)
    );

    const query = {
      limit: this.limit,
      skip: skip,
      where: {
        // store_code: storeCodes,
        ...this.filterDate,
        store_code:
          this.filterStore === "AllStore" ? storeCodes : this.filterStore,
      },
      include: ["all"],
      sort: this.sort,
      keys: [
        "cust_name",
        "store_code",
        "tran_date",
        "cust_phone",
        "total",
        "cust_mobile",
        "cust_email",
        "items",
      ],
    };

    // Execute the query and update the view
    try {
      this.showProgress();
      this.findObjectUseCase.abort(); // Abort any previous use case execution
      const objects = await this.findObjectUseCase.execute(
        this.view.getCollectionName(),
        query
      );
      if (Object.keys(this.filterDate).length === 0) {
        console.log("data");
      } else {
        this.objects = this.objects.concat(objects);
        this.view.setTotal(this.objects.length);
        this.view.setObjects(this.objects);
      }
    } catch (error) {
      console.error("Error fetching objects:", error);
      this.view.showError(error);
    } finally {
      this.hideProgress();
      this.progress = false;
    }
  }

  // onChangeDate(where) {
  //   this.filterDate = where;
  //   console.log("date", this.filterDate);
  //   this.getObjects();
  // }
  onChangeDate(where) {
    const { tran_date } = where;
    console.log("tran", tran_date);

    if (tran_date && tran_date.$gte && tran_date.$lte) {
      const startDate = addHours(startOfDay(parseISO(tran_date.$gte)), 5);
      const endDate = addMinutes(
        addHours(addDays(startOfDay(parseISO(tran_date.$lte)), 0), 4),
        59
      );

      const formattedStartDate = format(startDate, "yyyy-MM-dd HH:mm:ss.SSS");
      const formattedEndDate = format(endDate, "yyyy-MM-dd HH:mm:ss.SSS");

      this.filterDate = {
        tran_date: {
          $gte: formattedStartDate,
          $lte: formattedEndDate,
        },
      };
    }

    console.log("date", this.filterDate);
    this.getObjects();
  }

  onChangeStore(where) {
    this.filterStore = where;
    this.getObjects();
  }

  calculateSums(objects) {
    const sumReducer = (acc, object, key) => acc + Number(object[key] || 0);

    const sum_total = objects?.reduce(
      (acc, obj) => sumReducer(acc, obj, "total"),
      0
    );

    const sum_discount = objects?.reduce(
      (acc, obj) => sumReducer(acc, obj, "discount_amount"),
      0
    );

    const sum_taxes = objects?.reduce(
      (acc, obj) => sumReducer(acc, obj, "total_tax"),
      0
    );

    return {
      sum_total,
      sum_discount,
      sum_taxes,
      grossSum: sum_total + sum_discount,
      grossSales: sum_total + sum_discount - sum_taxes,
    };
  }

  calculateTotalItemSoldSalesInfo() {
    const itemSold = this.objects;

    let totalQuantity = 0;

    if (Array.isArray(itemSold)) {
      for (let transaction of itemSold) {
        if (transaction.items && Array.isArray(transaction.items)) {
          for (let item of transaction.items) {
            let qty = Number(item.quantity);
            if (!isNaN(qty)) {
              totalQuantity += qty;
            }
          }
        }
      }
    } else {
      console.error("itemSold is not an array");
    }

    return {
      value: totalQuantity,
    };
  }

  calculateTotalTransactionInfo() {
    const prevTransaction = 5;
    const totalTransaction = this.objects;
    return {
      value: totalTransaction?.length,
      percentage: this.calculatePercentage(
        totalTransaction?.length,
        prevTransaction
      ),
    };
  }

  calculateTotalGuestInfo() {
    const prevCust = 6;
    const custCount = this.countCustomers();
    return {
      value: custCount,
      percentage: this.calculatePercentage(custCount, prevCust),
    };
  }

  calculateDiscountCost() {
    const prevCust = 6;
    const discountSum = this.objects
      ?.map((cust) => Number(cust.discount_amount))
      .reduce((acc, prev) => acc + prev, 0);
    return {
      value: discountSum,
      percentage: this.calculatePercentage(discountSum, prevCust),
    };
  }

  calculatePercentage(current, previous) {
    if (previous > 0 && current !== undefined) {
      return parseFloat(((current - previous) / previous) * 100).toFixed(2);
    }
    return 0;
  }

  countCustomers() {
    return this.objects
      ?.filter((cus) => cus.cust_name !== "-")
      .map((cust) => cust.cust_name).length;
  }
}

export default CustomerPresenter;
