import BaseListPresenter from "../../../../base/BaseListPresenter";
import { getCurrentUserUseCase } from "../../../../usecases/user";
import {
  format,
  startOfDay,
  addHours,
  addMinutes,
  addDays,
  parseISO,
} from "date-fns";

class TotalSalesWeekDayPresenter extends BaseListPresenter {
  init() {
    this.store = [];
    this.limit = 100000;
    this.where = {};
    this.search = {};
    this.filter = {};
    this.filterDate = {};
    this.filterStore = {};
    this.include = ["all"];
    this.keys = undefined; // if keys are specified, only those keys will be returned
    this.sort = { createdAt: -1 };
    this.progress = true;
    this.reset();
  }
  async findObjects() {
    try {
      const user = await getCurrentUserUseCase().execute();
      const storeQuery = {
        include: ["all"],
        where: { email: user.email },
      };
      this.store = await this.findObjectUseCase.execute("users", storeQuery);
      const store = this.store[0].storeAnalytic;
      this.view.setStore(store);
    } catch (error) {
      console.error("Error fetching user or store data:", error);
      return; // Exit if there's an error fetching user or store data
    }

    // Prepare the query for finding objects
    const skip = (this.current - 1) * this.limit;
    const storeCodes = this.store?.flatMap((storeItem) =>
      storeItem.storeAnalytic?.map((analytic) => analytic?.name)
    );

    const query = {
      limit: this.limit,
      skip: skip,
      where: {
        // store_code: storeCodes,
        store_code:
          this.filterStore === "AllStore" ? storeCodes : this.filterStore,
        ...this.filterDate,
      },
      include: ["all"],
      sort: this.sort,
    };

    // Execute the query and update the view
    try {
      this.showProgress();
      this.findObjectUseCase.abort(); // Abort any previous use case execution
      const objects = await this.findObjectUseCase.execute(
        this.view.getCollectionName(),
        query
      );
      this.objects = this.objects.concat(objects);
      this.view.setTotal(this.objects.length);
      this.view.setObjects(this.objects);
    } catch (error) {
      console.error("Error fetching objects:", error);
      this.view.showError(error);
    } finally {
      this.hideProgress();
      this.progress = false;
    }
  }

  onChangeStore(where) {
    this.filterStore = where;
    this.getObjects();
  }

  // onChangeDate(where) {
  //   this.filterDate = where;
  //   this.getObjects();
  // }
  // onChangeDate(where) {
  //   const { tran_date } = where;
  //   console.log("tran", tran_date);

  //   if (tran_date && tran_date.$gte && tran_date.$lte) {
  //     const startDate = addHours(startOfDay(parseISO(tran_date.$gte)), 5);
  //     const endDate = addMinutes(addHours(addDays(startDate, 1), -1), 59);

  //     const formattedStartDate = format(startDate, "yyyy-MM-dd HH:mm:ss.SSS");
  //     const formattedEndDate = format(endDate, "yyyy-MM-dd HH:mm:ss.SSS");

  //     this.filterDate = {
  //       tran_date: {
  //         $gte: formattedStartDate,
  //         $lte: formattedEndDate,
  //       },
  //     };
  //   }

  //   console.log("date", this.filterDate);
  //   this.getObjects();
  // }

  onChangeDate(where) {
    const { tran_date } = where;
    console.log("tran", tran_date);

    if (tran_date && tran_date.$gte && tran_date.$lte) {
      const startDate = addHours(startOfDay(parseISO(tran_date.$gte)), 5);
      const endDate = addMinutes(
        addHours(addDays(startOfDay(parseISO(tran_date.$lte)), 0), 4),
        59
      );

      const formattedStartDate = format(startDate, "yyyy-MM-dd HH:mm:ss.SSS");
      const formattedEndDate = format(endDate, "yyyy-MM-dd HH:mm:ss.SSS");

      this.filterDate = {
        tran_date: {
          $gte: formattedStartDate,
          $lte: formattedEndDate,
        },
      };
    }

    console.log("date", this.filterDate);
    this.getObjects();
  }
}

export default TotalSalesWeekDayPresenter;
