import BaseListPresenter from "../../base/BaseListPresenter";
import saveAs from "../../saveAs";
import Papa from "papaparse";

import { exportCSVUseCase } from "../../usecases/csv/usecases";

class TransactionPresenter extends BaseListPresenter {
  constructor(
    view,
    findObjectUseCase,
    countObjectUseCase,
    deleteObjectUseCase,
    upsertUseCase,
    exportCSVUseCase,
    addSchemaUseCase,
    updateSchemaUseCase,
    deleteSchemaUseCase
  ) {
    super(view, findObjectUseCase, countObjectUseCase, deleteObjectUseCase);
    this.upsertUseCase = upsertUseCase;
    this.exportCSVUseCase = exportCSVUseCase;
    this.addSchemaUseCase = addSchemaUseCase;
    this.updateSchemaUseCase = updateSchemaUseCase;
    this.deleteSchemaUseCase = deleteSchemaUseCase;
    this.view = view;
  }

  // async onClickExport() {
  //   this.view.showProgress();
  //   const collection = this.view.getCollectionName();
  //   let objects = this.view.getSelected();
  //   if (objects.length === 0) {
  //     try {
  //       const query = {
  //         where: { ...this.where, ...this.search, ...this.filter },
  //         include: this.include,
  //       };
  //       await this.view.showConfirmDialog(
  //         "Export all data take longer!",
  //         "Export all data?",
  //         "EXPORT"
  //       );
  //       objects = await this.findObjectUseCase.execute(collection, query);
  //     } catch (e) {
  //       return;
  //     }
  //   }
  //   const blob = new Blob([JSON.stringify(objects, null, 2)], {
  //     type: "application/json",
  //   });
  //   const date = new Date();
  //   const day = date.toISOString().slice(0, 10);
  //   const time = date.toLocaleTimeString("en-GB").replaceAll(":", "");
  //   saveAs(blob, `${collection}-${day}-${time}.json`);
  //   this.view.hideProgress();
  // }

  // async onClickExport() {
  //   const objects = this.view.getSelected();
  //   console.log("export", objects);
  //   const collection = this.view.getCollectionName();

  // }

  async onClickExport() {
    const objects = this.view.getSelected();
    console.log("export", objects);
    const collection = this.view.getCollectionName();

    // Check if objects are selected
    if (objects.length === 0) {
      console.log("No objects selected for export.");
      return;
    }

    // Convert objects to CSV
    const csv = Papa.unparse(objects);

    // Create a Blob from the CSV string
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

    // Create a link element
    const link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      // Create a URL for the Blob and set it as the href attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      // Set the download attribute with a filename
      link.setAttribute("download", `${collection}_export.csv`);
      // Append the link to the document body
      document.body.appendChild(link);
      // Programmatically click the link to trigger the download
      link.click();
      // Clean up and remove the link
      document.body.removeChild(link);
    }
  }
}
export default TransactionPresenter;
