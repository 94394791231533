import BaseListPresenter from "../../../base/BaseListPresenter";
import { getCurrentUserUseCase } from "../../../usecases/user";

class TotalGuestDayPresenter extends BaseListPresenter {
  init() {
    this.store = [];
    this.limit = 2000000;
    this.where = {};
    this.search = {};
    this.filter = {};
    this.filterDate = {};
    this.filterStore = {};
    this.include = ["all"];
    this.keys = undefined; // if keys are specified, only those keys will be returned
    this.sort = { createdAt: -1 };
    this.progress = true;
    this.reset();
  }

  async findObjects() {
    // Fetch the current user and store information
    try {
      const user = await getCurrentUserUseCase().execute();
      const storeQuery = {
        include: ["all"],
        where: { email: user.email },
      };
      this.store = await this.findObjectUseCase.execute("users", storeQuery);
      // const store = this.store[0].storeAnalytic;
      const store = this.store[0].storeAnalytic.sort((a, b) => {
        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0; // names must be equal
      });
      this.view.setStore(store);
    } catch (error) {
      console.error("Error fetching user or store data:", error);
      return; // Exit if there's an error fetching user or store data
    }

    // Prepare the query for finding objects
    const skip = (this.current - 1) * this.limit;
    const storeCodes = this.store?.flatMap((storeItem) =>
      storeItem.storeAnalytic?.map((analytic) => analytic?.name)
    );

    const query = {
      limit: this.limit,
      skip: skip,
      where: {
        store_code:
          this.filterStore === "AllStore" ? storeCodes : this.filterStore,
        ...this.filterDate,
      },
      include: ["all"],
      sort: this.sort,
      keys: [
        "store_code",
        "tran_date",
        "total",
        "total_taxfree",
        "total_tax",
        "discount_amount",
        "createdAt",
        "cust_name",
        "tran_type",
        "paymenttype",
        "cust_count",
        "status",
        // "items",
      ],
    };

    // Execute the query and update the view
    try {
      this.showProgress();
      this.findObjectUseCase.abort(); // Abort any previous use case execution
      const objects = await this.findObjectUseCase.execute(
        this.view.getCollectionName(),
        query
      );

      if (Object.keys(this.filterDate).length === 0) {
        console.log("data");
      } else {
        this.objects = this.objects.concat(objects);
        this.view.setTotal(this.objects.length);
        this.view.setObjects(this.objects);
      }
    } catch (error) {
      console.error("Error fetching objects:", error);
      this.view.showError(error);
    } finally {
      this.hideProgress();
      this.progress = false;
    }
  }

  onChangeStore(where) {
    this.filterStore = where;
    this.getObjects();
  }

  onChangeDate(where) {
    console.log("time", where);
    // const timeStart = "16:00:00.000Z"
    // const timeEnd = "15:59:59.999Z"

    // const timeL = where.createdAt.$gte.split("T")[0];
    // const timeR = where.createdAt.$lte.split("T")[0];

    // const createdAts = {
    //     $gte: timeL + "T" + timeStart,
    //     $lte: timeR + "T" + timeEnd,
    // }
    this.filterDate = where;
    this.getObjects();
  }
}

export default TotalGuestDayPresenter;
