import React from "react";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import TotalGuestDayPresenter from "./TotalGuestDayPresenter";
import BaseListPage from "../../../base/BaseListPage";
import { findObjectUseCase } from "../../../usecases/object";
import withRouter from "../../../withRouter";
import Export from "../../../components/Export";
import printChart from "../../../PrintChart";
import { Progress } from "nq-component";
import DateRange from "../../../components/DateRange";

class TotalGuestDayPage extends BaseListPage {
  constructor(props) {
    super(props);
    this.presenter = new TotalGuestDayPresenter(this, findObjectUseCase());
    this.state = {
      objects: [],
      startDate: "",
      endDate: "",
      selectedStoreCode: "",
      store: [],
      storess: "",
      range: "",
    };
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.handleStoreCodeChange = this.handleStoreCodeChange.bind(this);
  }

  getCollectionName() {
    return "transactions";
  }

  setStore(store) {
    console.log("storessss", store);
    this.setState({ store });
  }

  onChangeStore(where) {
    console.log("store Onchange", where);
    this.setState({ storess: where });
    this.presenter.onChangeStore(where);
  }

  onChangeDate(where) {
    this.presenter.onChangeDate(where);
    this.setState({ range: where });
  }

  getUniqueStoreCodes() {
    const storeCodes = this.state.objects.map((obj) =>
      // Remove "ANGELSPIZZA_" from the start and return the rest
      obj.store_code.replace("ANGELSPIZZA_", "")
    );

    return [...new Set(storeCodes)]; // Remove duplicates
  }

  handleStartDateChange(event) {
    this.setState({ startDate: event.target.value });
  }

  handleEndDateChange(event) {
    this.setState({ endDate: event.target.value });
  }

  handleStoreCodeChange(event) {
    this.setState({ selectedStoreCode: event.target.value });
  }

  filterTransactions() {
    const { objects, startDate, endDate, selectedStoreCode } = this.state;

    return objects.filter(obj => obj.status === "C").filter((transaction) => {
      const transactionDate = new Date(transaction.createdAt);
      const start = startDate
        ? new Date(startDate)
        : new Date(-8640000000000000);
      const end = endDate ? new Date(endDate) : new Date();
      end.setHours(23, 59, 59, 999);
      const isDateInRange =
        (!startDate || transactionDate >= start) &&
        (!endDate || transactionDate <= end);
      const isStoreCodeMatch = selectedStoreCode
        ? transaction.store_code.replace("ANGELSPIZZA_", "") ===
        selectedStoreCode
        : true;

      return isDateInRange && isStoreCodeMatch;
    });
  }

  countTransactionTypesPerDayOfWeek = (transactions) => {
    const counts = {
      solo: new Array(7).fill(0),
      couple: new Array(7).fill(0),
      smallGroup: new Array(7).fill(0),
      meduimGroup: new Array(7).fill(0),
      largeGroup: new Array(7).fill(0),
    };

    transactions.forEach((transaction) => {
      const dayOfWeek = new Date(transaction.createdAt).getDay();
      switch (transaction.cust_count) {
        case "1":
          counts.solo[dayOfWeek]++;
          break;
        case "2":
          counts.couple[dayOfWeek]++;
          break;
        case "3":
        case "4":
        case "5":
          counts.smallGroup[dayOfWeek]++;
          break;
        case "6":
        case "7":
        case "8":
        case "9":
        case "10":
          counts.meduimGroup[dayOfWeek]++;
          break;
        case "11":
        case "12":
        case "13":
        case "14":
        case "15":
          counts.largeGroup[dayOfWeek]++;
          break;
        default:
          break;
      }
    });

    return counts;
  };

  getChartData() {
    const filteredTransactions = this.filterTransactions();
    const newCounts = this.countTransactionTypesPerDayOfWeek(
      filteredTransactions,
      new Date(this.state.startDate).getMonth(),
      new Date(this.state.startDate).getFullYear()
    );

    return {
      labels: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      datasets: [
        {
          label: "Solo",
          data: newCounts.solo,
          borderColor: "#1b74bb",
          backgroundColor: "rgba(53, 162, 235, 0.5)",
          fill: true,
        },
        {
          label: "Couple",
          data: newCounts.couple,
          borderColor: "rgb(255, 205, 86)",
          backgroundColor: "rgba(255, 205, 86, 0.5)",
          fill: true,
        },
        {
          label: "Small Group",
          data: newCounts.smallGroup,
          borderColor: "rgb(75, 192, 192)",
          backgroundColor: "rgba(75, 192, 192, 0.5)",
          fill: true,
        },
        {
          label: "Medium Group",
          data: newCounts.meduimGroup,
          borderColor: "rgb(52, 64, 84)",
          backgroundColor: "rgba(52, 64, 84,0.5)",
          fill: true,
        },
        {
          label: "Large Group",
          data: newCounts.largeGroup,
          borderColor: "rgb(208, 213, 221)",
          backgroundColor: "rgba(208, 213, 221,0.5)",
          fill: true,
        },
      ],
    };
  }

  getChartOptions() {
    return {
      chartOptions: {
        responsive: true,
        plugins: {
          legend: {
            position: "top",
          },
          title: {
            display: true,
            text: "Total Transactions vs Time of Day (Hours)",
          },
        },
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
          },
        },
      },
    };
  }

  convertToCSV(objArray) {
    const array =
      typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
    let str =
      `${Object.keys(array[0])
        .map((value) => `"${value}"`)
        .join(",")}` + "\r\n";
    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in array[i]) {
        if (line !== "") line += ",";
        line += `"${array[i][index]}"`;
      }
      str += line + "\r\n";
    }
    return str;
  }

  exportToCSV = () => {
    const { objects, startDate, endDate, selectedStoreCode } = this.state;
    const transactionData = this.filterTransactions(); // Assuming this method filters your transactions based on state criteria

    // CSV headers
    const csvHeaders = [
      "Date",
      "Store",
      "Solo",
      "Couple",
      "Small Group",
      "Medium Group",
      "Large Group",
    ];
    const csvRows = [csvHeaders.join(",")];

    // Aggregate data per day
    const dataByDay = {}; // This will hold data in the form { 'DayOfWeek': { 'Solo': count, 'Couple': count, ... } }

    // Populate dataByDay based on transactions
    transactionData.forEach((transaction) => {
      const dayOfWeek = new Date(transaction.createdAt).toLocaleString(
        "default",
        { weekday: "long" }
      );
      if (!dataByDay[dayOfWeek]) {
        dataByDay[dayOfWeek] = {
          Solo: 0,
          Couple: 0,
          "Small Group": 0,
          "Medium Group": 0,
          "Large Group": 0,
        };
      }
      // Increment counts based on your transaction data structure
      // For example:
      dataByDay[dayOfWeek]["Solo"] += transaction.cust_count === "1" ? 1 : 0;
      // ... and so on for other categories
    });

    // Convert aggregated data into CSV rows
    for (const [day, counts] of Object.entries(dataByDay)) {
      csvRows.push(
        [
          day,
          this.state.storess || "All Stores",
          counts["Solo"],
          counts["Couple"],
          counts["Small Group"],
          counts["Medium Group"],
          counts["Large Group"],
        ].join(",")
      );
    }

    // Create a Blob from the CSV string
    const blob = new Blob([csvRows.join("\n")], {
      type: "text/csv;charset=utf-8;",
    });

    // Create a link element, use it to download the Blob, and remove it
    const link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "TotalGuestsPerDay.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  render() {
    const chartData = this.getChartData();
    const chartOptions = this.getChartOptions();
    const { startDate, endDate } = this.state;
    const uniqueStoreCodes = this.getUniqueStoreCodes();
    const chartId = "chart-container13";

    return (
      <>
        <div className="d-flex justify-content-between align-items-center mb-1 mt-4">
          <h2 className="fw-bold text-capitalize">
            <span style={{ color: "black" }}>Total</span>
            <span style={{ color: "#83c14a" }}> Guest</span>
            <span style={{ color: "black" }}> vs.</span>
            <span style={{ color: "#c5da55" }}> Day of Week</span>
          </h2>
          <Export
            exportCsv={() => this.exportToCSV()}
            exportPdf={() => printChart(chartId)}
          />
        </div>
        <div className="row mb-2">
          <div className="col-sm-4" style={{ maxWidth: "max-content" }}>
            <DateRange
              onChange={this.onChangeDate.bind(this)}
              field="createdAt"
            />
          </div>
          <div className="col-sm-3">
            <select
              className="form-select"
              // value={this.state.selectedStoreCode}
              onChange={(e) => this.onChangeStore(e.target.value)}
              disabled={this.state.range ? false : true}
            >
              <option value="">Select Store</option>
              <option value="AllStore">All Store</option>
              {this.state.store.map((code, index) => (
                <option key={index} value={code.name}>
                  {code.name}
                </option>
              ))}
            </select>
          </div>
          {/* <div className="col-sm-2">
            <input
              className="form-control"
              type="date"
              value={startDate}
              onChange={this.handleStartDateChange}
            />
          </div>
          <div className="col-sm-2">
            <input
              className="form-control"
              type="date"
              value={endDate}
              onChange={this.handleEndDateChange}
            />
          </div> */}
        </div>
        {this.state.objects && this.state.objects.length > 0 ? (
          <div ref={this.chartRef1} id="chart-container13">
            <div className="card">
              <Line data={chartData} options={chartOptions} />
            </div>
            <div className="print-only-content">
              <h2>Report Details</h2>
              <div className=" print-only-content">
                <div className="">
                  <h3>Brand: Angels Pizza</h3>
                  <h3>Store: {this.state.storess}</h3>
                  <h3>
                    Date: {`${this.state.range.createdAt["$lte"].split("T")[0]} ${this.state.range.createdAt["$gte"].split("T")[0]}`}
                  </h3>
                </div>
              </div>
              <div style={{ fontSize: "20px" }}>
                <table className="table text-center">
                  <thead className="text-center">
                    <tr className="text-center">
                      <th className="text-center">Day</th>
                      <th className="text-center">Guest Count Solo</th>
                      <th className="text-center">Guest Count Couple</th>
                      <th className="text-center">Guest Count Small Group</th>
                      <th className="text-center">Guest Count Medium Group</th>
                      <th className="text-center">Guest Count Large Group</th>
                    </tr>
                  </thead>

                  <tbody className="text-center">
                    {chartData.labels.map((label, index) => (
                      <tr key={label} className="text-center">
                        <td className="text-center">{label}</td>
                        <td className="text-center">{chartData.datasets[0].data[index]}</td>
                        <td className="text-center">{chartData.datasets[1].data[index]}</td>
                        <td className="text-center">{chartData.datasets[2].data[index]}</td>
                        <td className="text-center">{chartData.datasets[3].data[index]}</td>
                        <td className="text-center">{chartData.datasets[4].data[index]}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

            </div>
          </div>
        ) : this.state.storess && this.state.range ? (
          <div className="text-center mt-5">
            <Progress />
            <h6>Processing... Please wait.</h6>
          </div>
        ) : (
          <div className="text-center align-items-center mt-4">
            <img
              src="./choosefirst.png"
              style={{ height: "200px", width: "200px" }}
            />
            <h4>To proceed, please select a date first</h4>
          </div>
        )}
      </>
    );
  }
}

export default withRouter(TotalGuestDayPage);
