import React from "react";
import BaseListPage from "../../base/BaseListPage";
import { countObjectUseCase, findObjectUseCase } from "../../usecases/object";
import withRouter from "../../withRouter";
import LogsPresenter from "./LogsPresenter";
import { InfiniteScroll, Progress } from "nq-component";
import DateRange from "../../components/DateRange";
import NavBar2 from "../../components/NavBar2";
import DateRangeReport from "../../components/DateRangeReport";

class LogsPage extends BaseListPage {
  constructor(props) {
    super(props);
    this.presenter = new LogsPresenter(
      this,
      findObjectUseCase(),
      countObjectUseCase()
    );
    this.state = {
      objects: [],
      progress: true,
      currentPage: 1,
      storess: "",
      range: "",
      itemsPerPage: 2000000, // Adjust as needed
      store: [],
      storess: "",
    };
  }

  getCollectionName() {
    return "transactions";
  }

  heads() {
    return this.state.objects[0]
      ? Object.keys(this.state.objects[0]).map((item) => (
          <th style={{ backgroundColor: "#83c14a" }} key={item}>
            {item}
          </th>
        ))
      : null;
  }

  datas() {
    const { currentPage, itemsPerPage, objects } = this.state;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = objects.slice(indexOfFirstItem, indexOfLastItem);

    return currentItems.length > 0
      ? currentItems.map((object, index) => (
          <tr key={index}>
            {Object.keys(object).map((key) => (
              <td key={`${index}-${key}`}>{object[key]}</td>
            ))}
          </tr>
        ))
      : null;
  }

  changePage(number) {
    this.setState({ currentPage: number });
  }

  onChangeStore(where) {
    this.setState({ storess: where });
    this.presenter.onChangeStore(where);
  }

  setStore(store) {
    this.setState({ store });
  }

  renderPagination() {
    const { currentPage, itemsPerPage, objects } = this.state;
    const totalItems = objects.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const pageNumbers = [];
    const visiblePages = 7; // Number of pages to display in the pagination component

    let startPage = currentPage - Math.floor(visiblePages / 2);
    startPage = Math.max(startPage, 1);
    let endPage = startPage + visiblePages - 1;
    endPage = Math.min(endPage, totalPages);

    if (endPage - startPage + 1 < visiblePages) {
      startPage = Math.max(endPage - visiblePages + 1, 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return (
      <nav>
        <ul className="pagination">
          {startPage > 1 && (
            <>
              <li className="page-item">
                <a onClick={() => this.changePage(1)} className="page-link">
                  1
                </a>
              </li>
              {startPage > 2 && (
                <li className="page-item">
                  <span className="page-link">...</span>
                </li>
              )}
            </>
          )}
          {pageNumbers.map((number) => (
            <li
              key={number}
              className={`page-item ${currentPage === number ? "active" : ""}`}
            >
              <a onClick={() => this.changePage(number)} className="page-link">
                {number}
              </a>
            </li>
          ))}
          {endPage < totalPages && (
            <>
              {endPage < totalPages - 1 && (
                <li className="page-item">
                  <span className="page-link">...</span>
                </li>
              )}
              <li className="page-item">
                <a
                  onClick={() => this.changePage(totalPages)}
                  className="page-link"
                >
                  {totalPages}
                </a>
              </li>
            </>
          )}
        </ul>
      </nav>
    );
  }

  onChangeDate(range) {
    this.presenter.onChangeDate(range);
    this.setState({ range });
  }

  render() {
    const progress = this.presenter.progress;
    return (
      <>
        <NavBar2 />
        <div className="p-3 p-lg-4">
          <h2 className="fw-bold text-capitalize" style={{ color: "#83c14a" }}>
            Logs
          </h2>

          <div className="row mb-2 ">
            <div className="col-sm-4 mb-3" style={{ maxWidth: "max-content" }}>
              {/* <input type='date' className='form-control form-control-md shadow-none w-auto rounded-0 '/> */}
              <DateRangeReport
                onChange={this.onChangeDate.bind(this)}
                field="tran_date"
              />
            </div>
            <div className="col-sm-3">
              <select
                className="form-select"
                // value={this.state.selectedStoreCode}
                onChange={(e) => this.onChangeStore(e.target.value)}
                // disabled={this.state.range ? false : true}
              >
                <option value="">Select Store</option>
                <option value="AllStore">All Store</option>
                {this.state.store.map((code, index) => (
                  <option key={index} value={code.name}>
                    {code.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <InfiniteScroll>
            <div className="overflow-auto m-auto">
              {/* {progress &&
                (!this.state.objects || this.state.objects.length < 1) && (
                  <Progress />
                )} */}
              {(!this.state.range || !this.state.storess) && (
                <div className="text-center align-items-center mt-4">
                  <img
                    src="./choosefirst.png"
                    style={{ height: "200px", width: "200px" }}
                  />
                  <h4>To proceed, please select a date first</h4>
                </div>
              )}
              {this.state.storess && (
                <table className="table table-striped table-hover">
                  <thead style={{ backgroundColor: "#83c14a" }}>
                    <tr className="" style={{ backgroundColor: "#83c14a" }}>
                      {this.heads()}
                    </tr>
                  </thead>
                  <tbody>{this.datas()}</tbody>
                </table>
              )}

              {this.presenter.progress &&
                this.state.storess &&
                this.state.objects.length < 1 && (
                  <div className="text-center mt-5">
                    <Progress />
                    <h6>Processing... Please wait.</h6>
                  </div>
                )}
              {/* {this.renderPagination()} */}
            </div>
          </InfiniteScroll>
        </div>
      </>
    );
  }
}

export default withRouter(LogsPage);
