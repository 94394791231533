import React from "react";
import { Bar } from "react-chartjs-2"; // Assuming you have this import based on the context
import ChartDataLabels from "chartjs-plugin-datalabels";

function Graph({ salesData, data, store, options, plugins, storess }) {
  console.log("DATA GRAPH", data);
  const formatSales = (sales) => {
    return sales.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  };
  return (
    <div>
      {Object.keys(salesData).length > 0 ? (
        <>
          <div id="chartContainer">
            <div className="print-only">
              <div className="print-header mb-5 mt-2">
                <div className="d-flex justify-content-between align-items-center">
                  <img src="/figaro.png" alt="Figaro" className="header-logo" />
                  <h4 className="mx-auto">FIGARO COFFEE GROUP</h4>
                  <img
                    src="/angels.png"
                    alt="Angel's Pizza"
                    className="header-logo"
                  />
                </div>
                <h2 className="text-center">Monthly Sales Chart</h2>
              </div>
            </div>
            <div className="card p-2">
              <Bar data={data} options={options} plugins={[plugins]} />
            </div>
            <div className="print-only">
              <h2 className="mt-4">Report Details</h2>
              <div className="">
                <h3>Brand: Angels Pizza</h3>
                <h3>Store: {storess}</h3>
              </div>
              <table className="table text-center">
                <thead>
                  <tr className="d-flex">
                    <th>Month</th>
                    <th className="ms-5">Sales</th>
                  </tr>{" "}
                </thead>
                <tbody>
                  {data.labels.map((label, index) => (
                    <tr className="d-flex" key={label}>
                      <td>{label}</td>
                      <td>{data.datasets[0].data[index]}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      ) : (
        <div className="p-2">
          <p>Please select a month and year to view Monthly Sales.</p>
        </div>
      )}
    </div>
  );
}

export default Graph;
// import React from "react";
// import { Bar } from "react-chartjs-2";

// function Graph({ salesData, data, options, plugins }) {
//   // Function to format the sales figures for display
//   const formatSales = (sales) => {
//     return sales.toLocaleString("en-US", {
//       style: "currency",
//       currency: "USD",
//     });
//   };

//   return (
//     <div>
//       {Object.keys(salesData).length > 0 ? (
//         <>
//           <div id="chartContainer">
//             {/* Existing code for displaying the chart */}
//             <div className="card p-2">
//               <Bar data={data} options={options} plugins={[plugins]} />
//             </div>
//             <div>
//               <h2>Report</h2>
//               {/* Table for displaying sales data */}
//               {/* <table className="table">
//                 <thead>
//                   <tr>
//                     <th>Month</th>
//                     <th>Sales</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {data.labels.map((label, index) => (
//                     <tr key={label}>
//                       <td>{label}</td>
//                       <td>{formatSales(data.datasets[0].data[index])}</td>
//                     </tr>
//                   ))}
//                 </tbody>
//               </table> */}
//             </div>
//           </div>
//         </>
//       ) : (
//         <div className="p-2">
//           <p>Please select a month and year to view Monthly Sales.</p>
//         </div>
//       )}
//     </div>
//   );
// }

// export default Graph;
