import React, { Component } from "react";
import NavBar from "../../../../components/navbar";
import RevenueQuantityPage from "../revenueQuantity/RevenueQuantityPage";
import Top10ItemsPage from "../top10-menu-items/Top10ItemsPage";

class ItemSoldPage extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <>
        <NavBar />
        <div className="overflow-auto">
          <div className="p-3 p-lg-4">
            <div className="d-flex justify-content-between align-items-center">
              <h1
                className="fw-bold mt-1 text-capitalize"
                style={{ color: "#83c14a" }}
              >
                Total Item Sold
              </h1>
            </div>
            <RevenueQuantityPage />
            <Top10ItemsPage />
          </div>
        </div>
      </>
    );
  }
}

export default ItemSoldPage;
