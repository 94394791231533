import React from "react";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import BaseListPage from "../../../base/BaseListPage";
import { findObjectUseCase } from "../../../usecases/object";
import TotalGuestTimePresenter from "./TotalGuestTimePresenter";
import withRouter from "../../../withRouter";
import Export from "../../../components/Export";
import printChart from "../../../PrintChart";
import { Progress } from "nq-component";

class TotalGuestTimePage extends BaseListPage {
  constructor(props) {
    super(props);
    this.presenter = new TotalGuestTimePresenter(this, findObjectUseCase());
    this.state = {
      objects: [],
      selectedDate: new Date().toISOString().split("T")[0],
      selectedStoreCode: "",
      store: [],
      storess: "",
      range: "",
    };
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleStoreCodeChange = this.handleStoreCodeChange.bind(this);
  }

  getCollectionName() {
    return "transactions";
  }

  setStore(store) {
    console.log("storessss", store);
    this.setState({ store });
  }

  onChangeStore(where) {
    console.log("store Onchange", where);
    this.setState({ storess: where });
    this.presenter.onChangeStore(where);
  }

  getUniqueStoreCodes() {
    const storeCodes = this.state.objects.map((obj) =>
      // Remove "ANGELSPIZZA_" from the start and return the rest
      obj.store_code.replace("ANGELSPIZZA_", "")
    );

    console.log("store", storeCodes);

    return [...new Set(storeCodes)]; // Remove duplicates
  }

  handleDateChange(event) {
    this.setState({ selectedDate: event.target.value });
  }

  handleStoreCodeChange(event) {
    this.setState({ selectedStoreCode: event.target.value }); // Update the state when store code changes
  }

  filterTransactions(transactions) {
    const selectedDate = new Date(this.state.selectedDate);
    selectedDate.setHours(0, 0, 0, 0);

    return transactions.filter(obj => obj.status === "C").filter((transaction) => {
      const transactionDate = new Date(transaction.createdAt);
      transactionDate.setHours(0, 0, 0, 0);

      const selectedDateMatch =
        transactionDate.getTime() === selectedDate.getTime();
      const storeCodeMatch = this.state.selectedStoreCode
        ? transaction.store_code.replace("ANGELSPIZZA_", "") ===
        this.state.selectedStoreCode
        : true;

      return selectedDateMatch && storeCodeMatch;
    });
  }

  countTransactionTypesPerHour = (transactions) => {
    const counts = {
      solo: new Array(24).fill(0),
      couple: new Array(24).fill(0),
      smallGroup: new Array(24).fill(0),
      meduimGroup: new Array(24).fill(0),
      largeGroup: new Array(24).fill(0),
    };

    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    const filteredTransactions = transactions.filter((transaction) => {
      const transactionDate = new Date(transaction.createdAt);
      // transactionDate.setHours(0, 0, 0, 0);
      // return transactionDate.getTime() === currentDate.getTime();
      return transactionDate;
    });

    // Count transactions per type for each hour
    filteredTransactions.forEach((transaction) => {
      const hour = new Date(transaction.createdAt).getHours();
      const index = hour < 5 ? hour + 19 : hour - 5;
      switch (transaction.cust_count) {
        case "1":
          counts.solo[index]++;
          break;
        case "2":
          counts.couple[index]++;
          break;
        case "3":
        case "4":
        case "5":
          counts.smallGroup[index]++;
          break;
        case "6":
        case "7":
        case "8":
        case "9":
        case "10":
          counts.meduimGroup[index]++;
          break;
        case "11":
        case "12":
        case "13":
        case "14":
        case "15":
          counts.largeGroup[index]++;
          break;
        default:
          break;
      }
    });

    return counts;
  };

  getChartData() {
    const filteredTransactions = this.filterTransactions(this.state.objects);
    const newCounts = this.countTransactionTypesPerHour(filteredTransactions);
    const labels = Array.from({ length: 24 }, (_, i) => {
      const hour = (5 + i) % 24;
      return `${hour}:00`;
    });

    return {
      labels: labels,
      datasets: [
        {
          label: "Solo",
          data: newCounts.solo,
          borderColor: "#1b74bb",
          backgroundColor: "rgba(53, 162, 235, 0.5)",
          fill: true,
        },
        {
          label: "Couple",
          data: newCounts.couple,
          borderColor: "rgb(255, 205, 86)",
          backgroundColor: "rgba(255, 205, 86, 0.5)",
          fill: true,
        },
        {
          label: "Small Group",
          data: newCounts.smallGroup,
          borderColor: "rgb(75, 192, 192)",
          backgroundColor: "rgba(75, 192, 192, 0.5)",
          fill: true,
        },
        {
          label: "Medium Group",
          data: newCounts.meduimGroup,
          borderColor: "rgb(52, 64, 84)",
          backgroundColor: "rgba(52, 64, 84,0.5)",
          fill: true,
        },
        {
          label: "Large Group",
          data: newCounts.largeGroup,
          borderColor: "rgb(208, 213, 221)",
          backgroundColor: "rgba(208, 213, 221,0.5)",
          fill: true,
        },
      ],
    };
  }

  getChartOptions() {
    return {
      chartOptions: {
        responsive: true,
        plugins: {
          legend: {
            position: "top",
          },
          title: {
            display: true,
            text: "Total Transactions vs Time of Day (Hours)",
          },
        },
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
          },
        },
      },
    };
  }

  convertToCSV(objArray) {
    const array =
      typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
    let str =
      `${Object.keys(array[0])
        .map((value) => `"${value}"`)
        .join(",")}` + "\r\n";
    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in array[i]) {
        if (line !== "") line += ",";
        line += `"${array[i][index]}"`;
      }
      str += line + "\r\n";
    }
    return str;
  }

  exportToCSV = () => {
    const { objects, selectedDate, selectedStoreCode } = this.state;
    const transactionsOnSelectedDate = this.filterTransactions(objects);
    const countsPerHour = this.countTransactionTypesPerHour(
      transactionsOnSelectedDate
    );

    // Define CSV headers
    const headers = [
      "Date",
      "Hour",
      "Solo",
      "Couple",
      "Small Group",
      "Medium Group",
      "Large Group",
    ];
    let csvContent = [headers.join(",")];

    // Loop over each hour and format the data
    countsPerHour.solo.forEach((count, index) => {
      const hour = `${index + 5}:00`; // Adjust according to your time range
      const row = [
        selectedDate, // Add the selected date
        hour,
        countsPerHour.solo[index],
        countsPerHour.couple[index],
        countsPerHour.smallGroup[index],
        countsPerHour.meduimGroup[index],
        countsPerHour.largeGroup[index],
      ];
      csvContent.push(row.join(","));
    });

    // Convert the rows to a single string
    csvContent = csvContent.join("\n");

    // Create a Blob and trigger the download
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", `TotalGuests_${selectedDate}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // In your render method, add a button to trigger this function
  // <button onClick={this.exportToCSV}>Export to CSV</button>

  render() {
    const chartData = this.getChartData();
    const chartOptions = this.getChartOptions();
    const uniqueStoreCodes = this.getUniqueStoreCodes();
    const chartId = "chart-container14";

    return (
      <>
        <div className="d-flex justify-content-between align-items-center mb-1 mt-4">
          <h2 className="fw-bold text-capitalize">
            <span style={{ color: "black" }}>Total</span>
            <span style={{ color: "#83c14a" }}> Guest</span>
            <span style={{ color: "black" }}> vs.</span>
            <span style={{ color: "#c5da55" }}> Time of Day</span>
          </h2>
          <Export
            exportCsv={() => this.exportToCSV()}
            exportPdf={() => printChart(chartId)}
          />
        </div>

        <div className="row mb-2">
          <div className="col-sm-2">
            <input
              className="form-control"
              type="date"
              value={this.state.selectedDate}
              onChange={this.handleDateChange}
            />
          </div>
          <div className="col-sm-3">
            <select
              className="form-select"
              // value={this.state.selectedStoreCode}
              onChange={(e) => this.onChangeStore(e.target.value)}
            >
              <option value="">Select Store</option>
              <option value="AllStore">All Store</option>
              {this.state.store.map((code, index) => (
                <option key={index} value={code.name}>
                  {code.name}
                </option>
              ))}
            </select>
          </div>

        </div>
        {this.state.objects && this.state.objects.length > 0 ? (
          <div ref={this.chartRef1} id="chart-container14">
            <div className="card">
              <Line data={chartData} options={chartOptions} />
            </div>
            <div className="print-only-content">
              <h2>Report Details</h2>
              <div className=" print-only-content">
                <div className="">
                  <h3>Brand: Angels Pizza</h3>
                  <h3>Store: {this.state.storess}</h3>
                  <h3>Date: {this.state.selectedDate}</h3>
                </div>
              </div>
              <div className="" style={{ fontSize: "20px" }}>
                <table className="table text-center">
                  <thead>
                    <tr className="d-flex">
                      <th>Time</th>
                      <th className="ms-5">Guest Count Solo</th>{" "}
                      <th className="ms-5">Guest Count Couple</th>
                      <th className="ms-5">Guest Count Small Group</th>
                      <th className="ms-5">Guest Count Medium Group</th>
                      <th className="ms-5">Guest Count Large Group</th>
                    </tr>
                  </thead>

                  <tbody>
                    {chartData.labels.map((label, index) => (
                      <tr className="d-flex" key={label}>
                        <td>{label} ------ </td>
                        <td>{chartData.datasets[0].data[index]} ------ </td>
                        <td>{chartData.datasets[1].data[index]}</td>
                        <td>{chartData.datasets[2].data[index]}</td>
                        <td>{chartData.datasets[3].data[index]}</td>
                        <td>{chartData.datasets[4].data[index]}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : this.state.storess ? (
          <div className="text-center mt-5">
            <Progress />
            <h6>Processing... Please wait.</h6>
          </div>
        ) : (
          <div className="text-center align-items-center mt-4">
            <img
              src="./choosefirst.png"
              style={{ height: "200px", width: "200px" }}
            />
            <h4>To proceed, please select a store first</h4>
          </div>
        )}
      </>
    );
  }
}

export default withRouter(TotalGuestTimePage);
