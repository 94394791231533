/**
 * responsible for get the current user and current roles and schemas
 */
class MainPagePresenter {
  constructor(
    view,
    getCurrentUserUseCase,
    findObjectUseCase,
    signOutUseCase,
    getSchemaUseCase,
    getObjectUseCase
  ) {
    this.view = view;
    this.getCurrentUserUseCase = getCurrentUserUseCase;
    this.findObjectUseCase = findObjectUseCase;
    this.signOutUseCase = signOutUseCase;
    this.getSchemaUseCase = getSchemaUseCase;
    this.getObjectUseCase = getObjectUseCase;
  }

  // init2() {
  //   this.limit = 1;
  //   this.where = {};
  //   this.search = {};
  //   this.filter = {};
  //   this.filterDate = {};
  //   this.include = [];
  //   this.keys = undefined; // if keys are specified, only those keys will be returned
  //   this.sort = { createdAt: -1 };
  //   this.progress = true;
  //   this.reset();
  // }

  createQuery() {
    const skip = (this.current - 1) * this.limit;
    const query = {
      limit: this.limit,
      skip: skip,
      where: {
        ...this.where,
        ...this.search,
        ...this.filter,
        ...this.filterDate,
      },
      include: this.include,
    };
    if (this.sort) {
      query.sort = this.sort;
    }
    // const keys = this.keys || this.view.getKeys() || [];
    // if (keys.length > 0) {
    //   query.keys = keys;
    // }
    return query;
  }

  componentDidMount() {
    this.init();
  }

  async init() {
    this.view.showProgress();
    try {
      await this.getUser();
      await this.getSchema();
      await this.getTransaction();
      await this.getRolesBi();
      this.view.hideProgress();
    } catch (error) {
      this.view.hideProgress();
      switch (error.code) {
        case 401:
          this.view.navigateTo("/signin");
          break;
        case 403:
          this.view.navigateTo("/app");
          break;
        default:
          this.view.showError(error);
      }
    }
  }

  async getUser() {
    try {
      const user = await this.getCurrentUserUseCase.execute();
      this.user = user;
      if (!user.roles && !this.user.isMaster) {
        this.view.navigateTo("/denied");
        return;
      }
      this.view.setCurrentRoles(user.roles || []);
      this.view.setCurrentUser(user);
    } catch (error) {
      throw error;
    }
  }

  async getRolesBi() {
    const user = await this.getCurrentUserUseCase.execute();
    const query = {
      include: ["all"],
      where: {
        email: user.email,
      },
    };
    try {
      const store = await this.findObjectUseCase.execute("users", query);
      this.view.setGlobalState({ store });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    // try {
    //   const store = await this.getObjectUseCase.execute(
    //     "storeAnalytics",
    //     "fmLZ0njqOA",
    //     { include: ["all"] }
    //   );
    //   console.log("haha", store);
    //   this.view.setGlobalState({ store });
    // } catch (error) {}
  }

  async getTransaction() {
    // const query = this.createQuery();
    // try {
    //   const transaction = await this.findObjectUseCase.execute("transactions", {
    //     limit: 15000,
    //   });
    //   console.log("MAIN", transaction);
    //   this.view.setCurrentTransaction(transaction || {});
    // } catch (error) {}
    const user = await this.getCurrentUserUseCase.execute();
    const query = {
      include: ["all"],
      where: {
        email: user.email,
      },
    };
    try {
      const transaction = await this.findObjectUseCase.execute("users", query);
      // this.view.setGlobalState({ store });
      this.view.setCurrentTransaction(transaction || {});
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  async getSchema() {
    try {
      const schemas = await this.getSchemaUseCase.execute();
      this.view.setSchemas(schemas);
    } catch (error) {
      throw error;
    }
  }

  onClickSignOut() {
    const options = {
      title: "Confirm",
      message: "Are you sure you want to sign out?",
      positiveButton: "SIGN OUT",
    };

    this.view
      .showDialog(options)
      .then(() => this.signOutUseCase.execute())
      .then(() => {
        this.view.navigateTo("/signin");
      })
      .catch((error) => {
        this.view.showError(error);
      });
  }
}

export default MainPagePresenter;
