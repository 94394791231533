import BaseListPresenter from "../../base/BaseListPresenter";
import { getCurrentUserUseCase } from "../../usecases/user";
import {
  format,
  startOfDay,
  addHours,
  addMinutes,
  addDays,
  parseISO,
} from "date-fns";

class GrossSalesPresenter extends BaseListPresenter {
  init() {
    this.store = [];
    this.limit = 2000000;
    this.where = {};
    this.search = {};
    this.filter = {};
    this.filterDate = {};
    this.filterStore = {};
    this.include = ["all"];
    this.keys = undefined; // if keys are specified, only those keys will be returned
    this.sort = { createdAt: -1 };
    this.progress = true;
    this.reset();
  }

  async findObjects() {
    // Fetch the current user and store information
    try {
      const user = await getCurrentUserUseCase().execute();
      const storeQuery = {
        include: ["all"],
        where: { email: user.email },
      };
      this.store = await this.findObjectUseCase.execute("users", storeQuery);
      // const store = this.store[0].storeAnalytic;
      const store = this.store[0].storeAnalytic.sort((a, b) => {
        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0; // names must be equal
      });
      this.view.setStore(store);
    } catch (error) {
      console.error("Error fetching user or store data:", error);
      return; // Exit if there's an error fetching user or store data
    }

    // Prepare the query for finding objects
    const skip = (this.current - 1) * this.limit;
    const storeCodes = this.store?.flatMap((storeItem) =>
      storeItem.storeAnalytic?.map((analytic) => analytic?.name)
    );

    const query = {
      limit: 2000000,
      skip: skip,
      where: {
        store_code:
          this.filterStore === "AllStore" ? storeCodes : this.filterStore,
        tran_date: this.filterDate && this.filterDate,
      },
      include: ["all"],
      sort: this.sort,
      keys: [
        "store_code",
        "tran_date",
        "total",
        "total_taxfree",
        "total_tax",
        "discount_amount",
        "createdAt",
        "cust_name",
        "status",
        // "items",
      ],
    };

    // Execute the query and update the view
    try {
      this.showProgress();
      this.findObjectUseCase.abort(); // Abort any previous use case execution

      const objects = await this.findObjectUseCase.execute(
        "transactions",
        query
      );

      this.objects = this.objects.concat(objects);
      this.view.setTotal(this.objects.length);
      this.view.setObjects(this.objects);
      console.log("cvcvcccount", this.objects?.length);
    } catch (error) {
      console.error("Error fetching objects:", error);
      this.view.showError(error);
    } finally {
      this.hideProgress();
      this.progress = false;
    }
  }

  onChangeStore(where) {
    this.filterStore = where;
    this.getObjects();
  }

  onChangeDate(where) {
    console.log("gegege", where);

    if (where !== "AllMonths" && where) {
      const { $gte, $lte } = where;

      if ($gte && $lte) {
        const startDate = addHours(startOfDay(parseISO($gte)), 5);
        const endDate = addMinutes(
          addHours(addDays(startOfDay(parseISO($lte)), 0), 4),
          59
        );

        const formattedStartDate = format(startDate, "yyyy-MM-dd HH:mm:ss.SSS");
        const formattedEndDate = format(endDate, "yyyy-MM-dd HH:mm:ss.SSS");

        this.filterDate = {
          $gte: formattedStartDate,
          $lte: formattedEndDate,
        };
      }

      console.log("filterDate", this.filterDate);
    } else {
      //null
      delete this.filterDate;
    }

    this.getObjects();
  }

  // onChangeDate(where) {
  //   console.log("gegege", where);

  //   if (where !== "AllMonths" || !where) {
  //     this.filterDate = where;
  //     console.log("filterDte", this.filterDate);
  //   } else {
  //     //null
  //     delete this.filterDate;
  //   }

  //   this.getObjects();
  // }
  // onChangeDate(where) {
  //   const { tran_date } = where;
  //   console.log("tran", tran_date);

  //   if (tran_date && tran_date.$gte && tran_date.$lte) {
  //     const startDate = addHours(startOfDay(parseISO(tran_date.$gte)), 5);
  //     const endDate = addMinutes(
  //       addHours(addDays(startOfDay(parseISO(tran_date.$lte)), 0), 4),
  //       59
  //     );

  //     const formattedStartDate = format(startDate, "yyyy-MM-dd HH:mm:ss.SSS");
  //     const formattedEndDate = format(endDate, "yyyy-MM-dd HH:mm:ss.SSS");

  //     this.filterDate = {
  //       tran_date: {
  //         $gte: formattedStartDate,
  //         $lte: formattedEndDate,
  //       },
  //     };
  //   }

  //   console.log("date", this.filterDate);
  //   this.getObjects();
  // }

  // onChangeDate(where) {
  //   const timeStart = "16:00:00.000Z";
  //   const timeEnd = "15:59:59.999Z";

  //   if (where !== "AllMonths" || !where) {
  //     alert("hahah");
  //     const timeL = where.$gte.split("T")[0];
  //     const timeR = where.$lte.split("T")[0];

  //     const createdAts = {
  //       createdAt: {
  //         $gte: timeL + "T" + timeStart,
  //         $lte: timeR + "T" + timeEnd,
  //       },
  //     };

  //     console.log("gegege", createdAts);
  //     this.filterDate = createdAts;
  //     console.log("filterDate", this.filterDate);
  //   } else {
  //     // Reset filterDate if "AllMonths" or invalid date range
  //     delete this.filterDate;
  //   }

  //   this.getObjects();
  // }
}

export default GrossSalesPresenter;
