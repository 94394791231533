import BaseListPresenter from "../../../../base/BaseListPresenter";
import { getCurrentUserUseCase } from "../../../../usecases/user";

class Top10ItemsPresenter extends BaseListPresenter {
  init() {
    this.store = [];
    this.limit = 800000;
    this.where = {};
    this.search = {};
    this.filter = {};
    this.filterDate = {};
    this.filterStore = {};
    this.include = ["all"];
    this.keys = undefined; // if keys are specified, only those keys will be returned
    this.sort = { createdAt: -1 };
    this.progress = true;
    this.reset();
  }
  async findObjects() {
    // Fetch the current user and store information
    try {
      const user = await getCurrentUserUseCase().execute();
      const storeQuery = {
        include: ["all"],
        where: { email: user.email },
      };
      this.store = await this.findObjectUseCase.execute("users", storeQuery);
      const store = this.store[0].storeAnalytic;
      this.view.setStore(store);
    } catch (error) {
      console.error("Error fetching user or store data:", error);
      return; // Exit if there's an error fetching user or store data
    }

    // Prepare the query for finding objects
    const skip = (this.current - 1) * this.limit;
    const storeCodes = this.store?.flatMap((storeItem) =>
      storeItem.storeAnalytic?.map((analytic) => analytic?.name)
    );

    const query = {
      limit: this.limit,
      skip: skip,
      where: {
        // store_code: storeCodes,
        store_code: this.filterStore,
        ...this.filterDate,
      },
      include: ["all"],
      sort: this.sort,
      keys: [
        "store_code",
        "tran_date",
        "total",
        "total_taxfree",
        "total_tax",
        "discount_amount",
        "createdAt",
        "cust_name",
        "tran_type",
        "paymenttype",
        "cust_count",
        "items",
        "status"
      ],
    };

    // Execute the query and update the view
    try {
      this.showProgress();
      this.findObjectUseCase.abort(); // Abort any previous use case execution
      const objects = await this.findObjectUseCase.execute(
        this.view.getCollectionName(),
        query
      );
      if (Object.keys(this.filterDate).length === 0) {
        console.log("data");
      } else {
        this.objects = this.objects.concat(objects);
        this.view.setTotal(this.objects.length);
        this.view.setObjects(this.objects);
      }
    } catch (error) {
      console.error("Error fetching objects:", error);
      this.view.showError(error);
    } finally {
      this.hideProgress();
      this.progress = false;
    }
  }

  onChangeStore(where) {
    if (where === "AllStore") {
      delete this.filterStore;
    } else {
      this.filterStore =  where;
    }
    this.getObjects();
  }


  onChangeDate(where) {
    this.filterDate = where;
    this.getObjects();
  }
}

export default Top10ItemsPresenter;
