import React, { useMemo, useState } from "react";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import getCustomerCategories from "./CustomerCategories";
import Export from "../../../../components/Export";
import printChart from "../../../../PrintChart";
import Papa from "papaparse";
import DateRange from "../../../../components/DateRange";
import { Progress } from "nq-component";
import DateRangeReport from "../../../../components/DateRangeReport";

function CustomerCountPage({
  transactions,
  onChangeDate,
  onChangeStore,
  dateField,
  store,
  storess,
  range,
}) {
  const [selectedStore, setSelectedStore] = useState("All");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const getUniqueStores = (transactions) => {
    const storeSet = new Set();
    transactions.forEach((transaction) => {
      const storeCode = transaction.store_code.replace("ANGELSPIZZA_", "");
      storeSet.add(storeCode);
    });
    return Array.from(storeSet);
  };

  const filteredTransactions = useMemo(() => {
    return transactions.filter((transaction) => {
      const tranDate = new Date(transaction.tran_date);
      const start = startDate ? new Date(startDate) : new Date(0); // Unix epoch start if empty
      let end = endDate ? new Date(endDate) : new Date(); // Current date if empty
      end.setHours(23, 59, 59, 999); // Set the end date to the end of the day

      return tranDate >= start && tranDate <= end;
    });
  }, [transactions, startDate, endDate]);

  const uniqueStores = useMemo(
    () => getUniqueStores(filteredTransactions),
    [filteredTransactions]
  );

  const customerCategories = useMemo(
    () => getCustomerCategories(filteredTransactions, selectedStore),
    [filteredTransactions, selectedStore]
  );

  console.log("CUSTOMERCATEGORIES", customerCategories);

  const loyalistCount = customerCategories.loyalists.length;
  const potentialLoyalistCount = customerCategories.potentialLoyalists.length;
  const promisingCount = customerCategories.promising.length;
  const hesitantCount = customerCategories.hesitant.length;
  const needAttentionCount = customerCategories.needAttention.length;
  const detractorsCount = customerCategories.detractors.length;
  const customerCount = customerCategories.customerCount;

  const data = {
    labels: [
      "Loyalist",
      "Potential Loyalist",
      "PROMISING",
      "HESITANT",
      "NEED ATTENTION",
      "DETRACTORS",
      // "CUSTOMERS",
    ],
    datasets: [
      {
        label: "Customer Count per Customer Type",
        data: [
          loyalistCount,
          potentialLoyalistCount,
          promisingCount,
          hesitantCount,
          needAttentionCount,
          detractorsCount,
          // detractorsCount + filteredTransactions.length,
          // filteredTransactions.length,
        ],
        backgroundColor: [
          "#00589C",
          "#016FC4",
          "#1891C3",
          "#3AC0DA",
          "#3DC6C3",
          "#50E3C2",
        ],
        borderColor: [
          "#00589C",
          "#016FC4",
          "#1891C3",
          "#3AC0DA",
          "#3DC6C3",
          "#50E3C2",
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            return value;
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const exportCsv = () => {
    const headers = [
      "brand", // Assuming 'brand' is equivalent to 'store' in your case
      "store",
      "start_date",
      "end_date",
      "category",
      "count",
    ];
    // Prepare data for CSV
    const data = [
      {
        brand: "", // Replace with actual brand name if needed
        store: selectedStore,
        start_date: startDate || "Not set",
        end_date: endDate || "Not set",
        category: "Loyalist",
        count: loyalistCount,
      },
      {
        brand: "", // Replace with actual brand name if needed
        store: selectedStore,
        start_date: startDate || "Not set",
        end_date: endDate || "Not set",
        category: "Potential Loyalist",
        count: potentialLoyalistCount,
      },
      {
        brand: "", // Replace with actual brand name if needed
        store: selectedStore,
        start_date: startDate || "Not set",
        end_date: endDate || "Not set",
        category: "Promising",
        count: promisingCount,
      },
      {
        brand: "", // Replace with actual brand name if needed
        store: selectedStore,
        start_date: startDate || "Not set",
        end_date: endDate || "Not set",
        category: "Hesitant",
        count: hesitantCount,
      },
      {
        brand: "", // Replace with actual brand name if needed
        store: selectedStore,
        start_date: startDate || "Not set",
        end_date: endDate || "Not set",
        category: "Loyalist",
        count: loyalistCount,
      },
      {
        brand: "", // Replace with actual brand name if needed
        store: selectedStore,
        start_date: startDate || "Not set",
        end_date: endDate || "Not set",
        category: "Need Attention",
        count: needAttentionCount,
      },
      {
        brand: "", // Replace with actual brand name if needed
        store: selectedStore,
        start_date: startDate || "Not set",
        end_date: endDate || "Not set",
        category: "Detatractors",
        count: detractorsCount,
      },

      // ... [rest of your categories]
    ];

    // Convert to CSV
    const csv = Papa.unparse({
      fields: headers,
      data: data,
    });

    // Create a Blob from the CSV String
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "customer_categories.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const chartId = 40;

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-1">
        <h2 className="fw-bold text-capitalize">
          <span style={{ color: "#83c14a" }}>Customer</span>
          <span style={{ color: "black" }}> Count</span>
        </h2>
        <Export exportCsv={exportCsv} exportPdf={() => printChart(chartId)} />
      </div>
      <div className="row">
        <div className="col-sm-4 mb-3" style={{ maxWidth: "max-content" }}>
          <DateRangeReport onChange={onChangeDate} field={dateField} />
        </div>
        <div className="col-sm-3 mb-3">
          <select
            className="form-select"
            onChange={(e) => onChangeStore(e.target.value)} // Correctly passing the value
            disabled={!range ? true : false}
          >
            <option value="All">Select Store</option>
            <option value="AllStore">All Store</option>
            {store.map((store, index) => (
              <option key={index} value={store.name}>
                {store.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      {transactions.length > 0 ? (
        <div id="40">
          <div className="card">
            <Bar data={data} options={options} />
          </div>
          <div className="print-only-content">
            <h2>Report Details</h2>
            <div className=" print-only-content">
              <div className="">
                <h3>Brand: Angels Pizza</h3>
                <h3>Store: {selectedStore}</h3>
                <h3>
                  Date: {startDate} - {endDate}
                </h3>
              </div>
            </div>
            <div className="" style={{ fontSize: "20px" }}>
              <table className="table text-center">
                <thead>
                  <tr className="d-flex">
                    <th>Type</th>
                    <th className="ms-5">Count</th>{" "}
                  </tr>
                </thead>
                Í
                <tbody>
                  {data.labels.map((label, index) => (
                    <tr className="d-flex" key={label}>
                      <td>{label}</td>
                      <td>{data.datasets[0].data[index]}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : storess && range ? (
        <div className="text-center mt-5">
          <Progress />
          <h6>Processing... Please wait.</h6>
        </div>
      ) : (
        <div className="text-center align-items-center mt-4">
          <img
            src="./choosefirst.png"
            style={{ height: "200px", width: "200px" }}
          />
          <h4>To proceed, please select a date first</h4>
        </div>
      )}
    </>
  );
}

export default CustomerCountPage;
