import React, { useState, useMemo, useEffect } from "react";
import { Pie } from "react-chartjs-2";
import "chart.js/auto";
import { InputSelect } from "nq-component";
import printChart from "../../../PrintChart";
import Export from "../../Export";

function TargetHit({ objects, storess }) {
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [optionss, setOptionss] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [filterBox, setFilterBox] = useState(false);
  // Assuming 'objects' includes transactions from previous years
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const currentMonthName = monthNames[currentMonth]; // Get the current month name

  useEffect(() => {
    processData();
  }, [objects]);

  const processData = () => {
    const data = objects;

    const storeCodes = data.reduce((acc, item) => {
      const code = item.store_code.split("ANGELSPIZZA_")[1]; // Remove prefix
      if (code && !acc.includes(code)) {
        acc.push(code);
      }
      return acc;
    }, []);

    setOptionss(storeCodes.map((code) => ({ value: code, label: code })));
  };

  const parsedObjects = useMemo(() => {
    return objects.map((obj) => {
      const parts = obj.store_code.split("ANGELSPIZZA_");
      const codePart = parts.length > 1 ? parts[1] : ""; // Get the part after 'ANGELSPIZZA_'
      return {
        ...obj,
        parsedMonth: new Date(obj.tran_date).toLocaleString("default", {
          month: "long",
        }),
        parsedYear: new Date(obj.tran_date).getFullYear().toString(),
        storeCodePart: codePart, // Add this property
      };
    });
  }, [objects]);

  const onChangeType = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  const filteredTransactions = useMemo(
    () =>
      parsedObjects.filter((obj) => {
        const transactionDate = new Date(obj.tran_date);
        const matchesDate =
          transactionDate.getMonth() === currentMonth &&
          (transactionDate.getFullYear() === currentYear ||
            transactionDate.getFullYear() === currentYear - 1);
        const matchesStore = selectedOption
          ? obj.storeCodePart === selectedOption
          : true;
        return matchesDate && matchesStore;
      }),
    [parsedObjects, selectedOption, currentMonth, currentYear]
  );

  // Calculate actual sales for the current month
  const actualSalesCurrentMonth = filteredTransactions
    .filter((obj) => new Date(obj.tran_date).getFullYear() === currentYear)
    .reduce((acc, curr) => {
      const grossSales =
        parseFloat(curr.total) +
        parseFloat(curr.discount_amount) -
        parseFloat(curr.total_tax);
      const sales = grossSales - parseFloat(curr.discount_amount);
      return acc + sales;
    }, 0);

  // Calculate sales for the same month previous year
  const salesPreviousYear = filteredTransactions
    .filter((obj) => new Date(obj.tran_date).getFullYear() === currentYear - 1)
    .reduce((acc, curr) => {
      const grossSales =
        parseFloat(curr.total) +
        parseFloat(curr.discount_amount) -
        parseFloat(curr.total_tax);
      const sales = grossSales - parseFloat(curr.discount_amount);
      return acc + sales;
    }, 0);

  // Compute the Monthly Growth Rate
  let monthlyGrowthRate = 0;
  if (salesPreviousYear > 0) {
    monthlyGrowthRate =
      ((actualSalesCurrentMonth - salesPreviousYear) / salesPreviousYear) * 100;
  }

  // Assuming an average of the growth rate over 2 years for simplicity (you might need more complex logic here)
  const averageYearlyGrowthRate = monthlyGrowthRate / 2;

  // Calculate expected sales for the current month
  const expectedSales = salesPreviousYear * (1 + averageYearlyGrowthRate / 100);
  const expectedSalesValue = expectedSales || 0;

  // Update Pie chart data
  const dataActual = {
    labels: ["Actual Sales"],
    datasets: [
      {
        label: "Total Actual Sales",
        data: [actualSalesCurrentMonth],
        backgroundColor: ["#83c14a"],
        hoverOffset: 4,
      },
    ],
  };

  const dataExpected = {
    labels: ["Expected Sales"],
    datasets: [
      {
        label: "Total Expected Sales",
        data: [expectedSalesValue],
        backgroundColor: ["#0076ba"],
        hoverOffset: 4,
      },
    ],
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const months = useMemo(() => {
    return [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
  }, []);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top", // Legend position can be 'top', 'right', 'bottom', or 'left'
      },
      title: {
        display: true,
        text: "Actual Sales Performance", // Chart title
      },
    },
  };

  const options2 = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Expected Sales Performance",
      },
    },
  };

  function downloadCSV() {
    const csvRows = [
      // CSV Header
      ["Brand", "Store", "Actual Sales", "Expected Sales"],
    ];

    // Assuming you have a way to get the brand (this might be a static value or part of your data)
    const brand = "Angels Pizza"; // Replace this with actual logic to get brand name

    const store = storess || "All";
    const actualSales = actualSalesCurrentMonth.toString();
    const expectedSales = expectedSalesValue.toString() || "0";

    // Add the data row
    csvRows.push([brand, store, actualSales, expectedSales]);

    // Convert to CSV string
    const csvString = csvRows.map((e) => e.join(",")).join("\n");

    // Trigger Download
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "sales-data.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  const chartId = 99;

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <h2 className="fw-bold text-capitalize">
          <span style={{ color: "#83c14a" }}>Target Hit</span>
        </h2>

        <Export exportCsv={downloadCSV} exportPdf={() => printChart(chartId)} />
      </div>
      <div className="container mt-1 mb-3">
        <div className="row">
          {/* <div className="col-sm-3">
            <InputSelect placeholder="Select Brand" />
          </div> */}
          {/* <div className="col-sm-3">
            <InputSelect
              placeholder="Select Store"
              options={optionss}
              onChange={onChangeType}
            />
          </div> */}
        </div>
      </div>
      <div id="99">
        <div className="card ">
          <h6 className="text-center mt-4 fw-bold" style={{ color: "#0076ba" }}>
            % Target Hit - Current Month
          </h6>
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="">
                <Pie data={dataActual} options={options} />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="">
                <Pie data={dataExpected} options={options2} />
              </div>
            </div>
          </div>
        </div>
        <div className="print-only-content">
          <h2>Report Details</h2>
          <div className=" print-only-content">
            <div className="">
              <h3>Brand: Angels Pizza</h3>
              <h3>Store: {storess}</h3>
            </div>
          </div>
          <div className="" style={{ fontSize: "20px" }}>
            <table className="table text-center">
              <thead>
                <tr className="d-flex">
                  <th>{currentMonthName}</th>
                  <th className="ms-5">Actual Sales</th>{" "}
                </tr>
              </thead>
              <tbody>
                {dataActual.labels.map((label, index) => (
                  <tr className="d-flex" key={label}>
                    <td>{label}</td>
                    <td>{dataActual.datasets[0].data[index]}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default TargetHit;
