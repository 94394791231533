import React, { Component } from "react";
import BaseListPage from "../../../../base/BaseListPage";
import { findObjectUseCase } from "../../../../usecases/object";
import withRouter from "../../../../withRouter";
import Top10itemsPresenter from "./Top10ItemsPresenter";
import Export from "../../../../components/Export";
import DateRange from "../../../../components/DateRange";
import { Progress } from "nq-component";
import printJS from "print-js";

class Top10ItemsPage extends BaseListPage {
  constructor(props) {
    super(props);
    this.chartRef = React.createRef();
    this.presenter = new Top10itemsPresenter(this, findObjectUseCase());
    this.state = {
      objects: [],
      store: [],
      storess: "",
      range: "",
    };
  }

  getCollectionName() {
    return "transactions";
  }

  setStore(store) {
    this.setState({ store });
  }
  onChangeDate(where) {
    this.presenter.onChangeDate(where);
    this.setState({ range: where });
  }

  onChangeStore(where) {
    this.setState({ storess: where });
    this.presenter.onChangeStore(where);
  }

  processItemsData() {
    const { objects } = this.state;
    let itemAggregates = {};

    objects.filter(obj => obj.status === "C").forEach((transaction) => {
      transaction.items?.forEach((item) => {
        const { code, quantity, description, customProperties } = item;
        const unitPrice = customProperties.find(
          (p) => p.codeName === "UNIT_PRICE"
        ).value;
        const revenue = Number(quantity) * Number(unitPrice);

        if (!itemAggregates[code]) {
          itemAggregates[code] = { description, quantity: 0, revenue: 0 };
        }

        itemAggregates[code].quantity += Number(quantity);
        itemAggregates[code].revenue += revenue;
      });
    });

    const topItems = Object.entries(itemAggregates)
      .map(([code, data]) => ({
        code,
        description: data.description,
        quantity: data.quantity,
        revenue: data.revenue,
      }))
      .sort((a, b) => b.revenue - a.revenue) // Sort by revenue, but you can adjust as needed
      .slice(0, 10); // Select top 10 items

    return topItems;
  }

  formatSales(sales) {
    return sales.toLocaleString("en-US", {
      style: "currency",
      currency: "PHP",
    });
  };


  renderTable(topItems) {
    const { range } = this.state
    return (
      <table className="table">
        <thead>
          <tr>
            {/* <th>Item Code</th> */}
            <th style={{ backgroundColor: "#83c14a", color: "#fff" }}>
              Item Name
            </th>
            <th style={{ backgroundColor: "#83c14a", color: "#fff" }}>
              Total Sold
            </th>
            <th style={{ backgroundColor: "#83c14a", color: "#fff" }}>
              Total Revenue
            </th>
          </tr>
        </thead>
        {this.state.objects && this.state.objects.length > 0 ? (
          <tbody>
            {topItems.map((item, index) => (
              <tr key={index}>
                {/* <td>{item.code}</td> */}
                <td>{item.description}</td>
                <td>{item.quantity}</td>
                <td>
                  {new Intl.NumberFormat("en-US", {
                    // Locale
                    style: "currency",
                    currency: "PHP", // Currency
                    minimumFractionDigits: 2, // Ensuring two decimal places
                  }).format(item.revenue)}
                </td>
              </tr>
            ))}
            <p>Store: {this.state.storess}</p>
            <p>Date: {range.createdAt["$gte"].split("T")[0]} - {range.createdAt["$lte"].split("T")[0]}</p>
          </tbody>
        ) : this.state.storess && this.state.range ? (
          <div className="text-center mt-5">
            <Progress />
            <h6>Processing... Please wait.</h6>
          </div>
        ) : (
          <div className="text-center align-items-center mt-4">
            <img
              src="./choosefirst.png"
              style={{ height: "200px", width: "200px" }}
            />
            <h4>To proceed, please select a date first</h4>
          </div>
        )}
      </table>
    );
  }

  convertToCSV(objArray) {
    const array =
      typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
    let str =
      `${Object.keys(array[0])
        .map((value) => `"${value}"`)
        .join(",")}` + "\r\n";
    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in array[i]) {
        if (line !== "") line += ",";
        line += `"${array[i][index]}"`;
      }
      str += line + "\r\n";
    }
    return str;
  }


  formatNumber(num) {
    let numStr = num.toFixed(2);
    numStr = numStr.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return numStr;
  }

  exportToCSV = () => {
    const { storess, range } = this.state;
    const topItems = this.processItemsData();

    const csvData = topItems.map((item, index) => {

      return {
        Store: storess,
        Date: `${range.createdAt["$gte"].split("T")[0]} - ${range.createdAt["$lte"].split("T")[0]}`,
        Description: item.description,
        Quantity: item.quantity,
        Revenue: this.formatNumber(item.revenue)
      };
    });

    const csvString = this.convertToCSV(csvData);
    const a = document.createElement("a");
    const blob = new Blob([csvString], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = `${storess}-${range.createdAt["$gte"].split("T")[0]} - ${range.createdAt["$lte"].split("T")[0]}-top10-items.csv`;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  printChart = () => {
    setTimeout(() => {
      printJS({
        printable: "chart-container",
        type: "html",
        header: `<div style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem;">
                     <img src="/figaro.png" alt="Figaro" style="height: 50px;"/>
                     <h4 style="margin: 0 auto;">FIGARO COFFEE GROUP</h4>
                     <img src="/angels.png" alt="Angel's Pizza" style="height: 50px;"/>
                   </div>`,
        css: "./index.css",
        scanStyles: false,
        style: ".print-only-content { display: block; }",
      });
    }, 500);
  };

  render() {
    const topItems = this.processItemsData();

    return (
      <>
        <div className="d-flex justify-content-between align-items-center mb-1">
          <h2 className="mt-3 fw-bold text-capitalize">
            <span style={{ color: "black" }}>Top 10</span>
            <span style={{ color: "#c5da55" }}> Menu Items</span>
          </h2>
          <Export
            exportCsv={() => this.exportToCSV()}
            exportPdf={() => this.printChart()}
          />
        </div>
        <div className="row">
          <div className="col-sm-4 mb-3" style={{ maxWidth: "max-content" }}>
            <DateRange
              onChange={this.onChangeDate.bind(this)}
              field="createdAt"
            />
          </div>
          <div className="col-sm-3 mb-3">
            <select
              className="form-select"
              onChange={(e) => this.onChangeStore(e.target.value)}
              disabled={this.state.range ? false : true}
            >
              <option value="">Select Store</option>
              <option value="AllStore">All Store</option>
              {this.state.store.map((code, index) => (
                <option key={index} value={code.name}>
                  {code.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div ref={this.chartRef} id="chart-container">
          {this.renderTable(topItems)}
        </div>
      </>
    );
  }
}

export default withRouter(Top10ItemsPage);
