import React, { useMemo } from "react";
import getCustomerCategories from "./CustomerCategories";
import Export from "../../../../components/Export";
import printChart from "../../../../PrintChart";
import { createCsvString, downloadCsv } from "./Export";

const getTopItems = (transactions, category) => {
  const customerPhones = getCustomerCategories(transactions)[category];
  let itemsSummary = {};

  transactions.forEach((transaction) => {
    if (customerPhones.includes(transaction.cust_phone)) {
      transaction.items.forEach((item) => {
        const description = item.description;
        const quantity = parseInt(item.quantity, 10);

        itemsSummary[description] = (itemsSummary[description] || 0) + quantity;
      });
    }
  });

  return Object.entries(itemsSummary)
    .map(([description, quantity]) => ({ description, quantity }))
    .sort((a, b) => b.quantity - a.quantity)
    .slice(0, 5);
};

const ItemsTable = ({ items, title }) => (
  <div className="table-responsive">
    <h6 className="fw-bold">{title}</h6>
    <table className="table">
      <thead>
        <tr>
          <th style={{ backgroundColor: "#83c14a" }}>Item Name</th>
          <th style={{ backgroundColor: "#83c14a" }}>Item Count</th>
        </tr>
      </thead>
      <tbody>
        {items.map((item, index) => (
          <tr key={index}>
            <td>{item.description}</td>
            <td>{item.quantity}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

function Top5ProductsBuyPage({ transactions }) {
  const customerCategories = useMemo(
    () => getCustomerCategories(transactions),
    [transactions]
  );

  const topItemsByCategory = useMemo(
    () =>
      Object.keys(customerCategories).reduce((acc, category) => {
        acc[category] = getTopItems(transactions, category);
        return acc;
      }, {}),
    [transactions]
  );

  const exportCsv = () => {
    const csvString = createCsvString(topItemsByCategory);
    downloadCsv(csvString, "top_products.csv");
  };

  const chartId = 45;

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-1 mt-4">
        <h2 className="fw-bold text-capitalize">
          <span style={{ color: "black" }}>Top 5</span>
          <span style={{ color: "#83c14a" }}> Products</span>
          <span style={{ color: "black" }}> that</span>
          <span style={{ color: "#83c14a" }}> Customers</span>
          <span style={{ color: "black" }}> Buy</span>
        </h2>
        <Export exportCsv={exportCsv} exportPdf={() => printChart(chartId)} />
      </div>
      <div className="overflow-auto" id="45">
        {Object.entries(topItemsByCategory).map(([category, items]) => (
          <ItemsTable
            key={category}
            items={items}
            title={category.charAt(0).toUpperCase() + category.slice(1)}
          />
        ))}
      </div>
    </>
  );
}

export default Top5ProductsBuyPage;
