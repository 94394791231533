import React from "react";
import BaseListPage from "../../base/BaseListPage";
import withRouter from "../../withRouter";
import SaleTransPresenter from "./SaleTransPresenter";
import { countObjectUseCase, findObjectUseCase } from "../../usecases/object";
import NavBar from "../../components/navbar";
import schemaDashboard from "./schemaDashboard.json";
import Count from "../dashboard/components/Count";
import DateRange from "../../components/DateRange";
import MonthlySalesChart from "../../components/chart/MonthlySalesChart";
import ShareSalesContri from "../../components/chart/ShareSalesContri";
import TargetHit from "../../components/chart/TargetHit";
import { Progress } from "nq-component";
import DateRangeReport from "../../components/DateRangeReport";

class SaleTransPage extends BaseListPage {
  constructor(props) {
    super(props);
    this.presenter = new SaleTransPresenter(
      this,
      findObjectUseCase(),
      countObjectUseCase()
    );
    this.state = {
      objects: [],
      selected: [],
      progress: true,
      count: 0,
      where: {},
      range: "",
      store: [],
      storess: "",
    };
  }

  getCollectionName() {
    return "transactions";
  }

  setStore(store) {
    this.setState({ store });
  }

  onChangeStore(where) {
    this.setState({ storess: where });
    this.presenter.onChangeStore(where);
  }

  onChangeDate(where) {
    console.log("where date", where);
    this.presenter.onChangeDate(where);
    this.setState({ range: where });
  }

  getValueForDashboardObject(object) {
    switch (object.label) {
      case "Gross Sales":
        return this.presenter.calculateGrossSalesInfo();
      case "Total Transaction":
        return this.presenter.calculateTotalTransactionInfo();
      case "Total Guest":
        return this.presenter.calculateTotalGuestInfo();
      case "Discount Cost":
        return this.presenter.calculateDiscountCost();
      case "Total Tax Free":
        return this.presenter.calculateTotalTaxFree();
      default:
        return { value: 0, percentage: 0 };
    }
  }

  handleCountClick(nav) {
    this.navigateTo(nav);
  }

  render() {
    const objects = this.state.objects;

    console.log("translate", objects);

    return (
      <>
        <NavBar />
        <div className="overflow-auto">
          <div className="p-3 p-lg-4">
            <div className="d-flex justify-content-between align-items-center">
              <h1
                className="fw-bold mt-3 text-capitalize"
                style={{ color: "#83c14a" }}
              >
                Sales and Transaction
              </h1>
            </div>
            <div className="row mb-2 ">
              <div className="col-sm-4" style={{ maxWidth: "max-content" }}>
                <DateRangeReport
                  onChange={this.onChangeDate.bind(this)}
                  field="tran_date"
                />
              </div>

              <div className="col-sm-3">
                <select
                  className="form-select"
                  // value={this.state.selectedStoreCode}
                  onChange={(e) => this.onChangeStore(e.target.value)}
                  disabled={this.state.range ? false : true}
                >
                  <option value="">Select Store</option>
                  <option value="AllStore">All Store</option>
                  {this.state.store.map((code, index) => (
                    <option key={index} value={code.name}>
                      {code.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {objects?.length > 0 ? (
              <div className="row mt-1 g-3">
                {schemaDashboard.map((object, index) => {
                  const { value, percentage } = this.getValueForDashboardObject(
                    object,
                    objects
                  );

                  const navigationTarget = object.navigationTarget;
                  return (
                    <div key={index} className="col-6 col-md-3">
                      <Count
                        collection={object.collection}
                        icon={object.icon}
                        where={object.where}
                        val={value}
                        label={object.label}
                        labelAction={object.labelAction}
                        barangayValue={this.state.barangayValue}
                        object={object}
                        percentage={percentage}
                        bg={object.bg}
                        onClick={() => this.handleCountClick(navigationTarget)}
                      />
                    </div>
                  );
                })}{" "}
              </div>
            ) : this.state.range && this.state.storess ? (
              <div className="text-center mt-5">
                <Progress />
                <h6>Processing... Please wait.</h6>
              </div>
            ) : (
              <div className="text-center align-items-center mt-4">
                <img
                  src="./choosefirst.png"
                  style={{ height: "200px", width: "200px" }}
                />
                <h4>To proceed, please select a date range first and store</h4>
              </div>
            )}
            <div className="mt-4">
              <MonthlySalesChart
                objects={objects}
                storess={this.state.storess}
              />
            </div>
            {/* <div className="mt-4">
              <AveSizeTrend />
            </div>
            <div className="mt-4">
              <AveGuestTrend />
            </div> */}
            <div className="mt-4">
              <ShareSalesContri
                objects={objects}
                storess={this.state.storess}
              />
            </div>
            <div className="mt-4">
              <TargetHit objects={objects} storess={this.state.storess} />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(SaleTransPage);
