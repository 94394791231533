import printJS from "print-js";

const printChart = (chart) => {
  setTimeout(() => {
    printJS({
      printable: chart,
      type: "html",
      header: `<div style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem;">
                   <img src="/figaro.png" alt="Figaro" style="height: 50px;"/>
                   <h4 style="margin: 0 auto;">FIGARO COFFEE GROUP</h4>
                   <img src="/angels.png" alt="Angel's Pizza" style="height: 50px;"/>
                 </div>`,
      css: "./index.css",
      scanStyles: false,
      style: ".print-only-content { display: block; }",
    });
  }, 500);
};

export default printChart;
