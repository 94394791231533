import BaseListPresenter from "../../base/BaseListPresenter";
import { getCurrentUserUseCase } from "../../usecases/user";
import {
  format,
  startOfDay,
  addHours,
  addMinutes,
  addDays,
  parseISO,
} from "date-fns";

class SaleTransPresenter extends BaseListPresenter {
  init() {
    this.limit = 800000;
    this.where = {};
    this.search = {};
    this.filter = {};
    this.filterDate = {};
    this.filterStore = {};
    this.include = ["all"];
    this.keys = undefined; // if keys are specified, only those keys will be returned
    this.sort = { createdAt: -1 };
    this.progress = true;
    this.reset();
  }

  createQuery() {
    const skip = (this.current - 1) * this.limit;
    const query = {
      limit: this.limit,
      skip: skip,
      where: {
        ...this.where,
        ...this.search,
        ...this.filter,
        ...this.filterDate,
      },
      include: this.include,
    };
    if (this.sort) {
      query.sort = this.sort;
    }
    const keys = this.keys || this.view.getKeys() || [];
    if (keys.length > 0) {
      query.keys = keys;
    }
    return query;
  }

  async findObjects() {
    this.showProgress();
    try {
      const user = await getCurrentUserUseCase().execute();
      const storeQuery = { include: ["all"], where: { email: user.email } };
      this.store = await this.findObjectUseCase.execute("users", storeQuery);
      // const store = this.store[0].storeAnalytic;
      const store = this.store[0].storeAnalytic.sort((a, b) => {
        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0; // names must be equal
      });

      this.view.setStore(store);
      const storeCodes = this.store?.flatMap((storeItem) =>
        storeItem.storeAnalytic?.map((analytic) => analytic?.name)
      );
      // Prepare and execute the query for finding objects
      const skip = (this.current - 1) * this.limit;
      const query = {
        limit: this.limit,
        skip,
        where: {
          store_code:
            this.filterStore === "AllStore" ? storeCodes : this.filterStore,
          ...this.filterDate,
        },
        include: ["all"],
        sort: this.sort,
        keys: [
          "store_code",
          "tran_date",
          "total",
          "total_taxfree",
          "total_tax",
          "discount_amount",
          "createdAt",
          "cust_name",
          "tran_type",
          "paymenttype",
          "cust_count",
          "status",
        ],
      };

      this.findObjectUseCase.abort();
      const objects = await this.findObjectUseCase.execute(
        this.view.getCollectionName(),
        query
      );

      if (Object.keys(this.filterDate).length === 0) {
        console.log("sample");
      } else {
        this.objects = [...this.objects, ...objects];
        console.log("TOTAL TRANSACTION", this.objects.length);
        this.view.setTotal(this.objects.length);
        this.view.setObjects(this.objects);
      }
    } catch (error) {
      console.error("Error:", error);
      this.view.showError(error);
    } finally {
      this.hideProgress();
    }
  }

  // onChangeDate(where) {
  //   // const timeStart = "00:00:00.000Z";
  //   // const timeEnd = "23:59:59.999Z";

  //   // const timeL = where.createdAt.$gte.split("T")[0];
  //   // const timeR = where.createdAt.$lte.split("T")[0];

  //   // const createdAts = {
  //   //   createdAt: {
  //   //     $gte: timeL + "T" + timeStart,
  //   //     $lte: timeR + "T" + timeEnd,
  //   //   },
  //   // };

  //   this.filterDate = where;
  //   this.getObjects();
  // }

  // onChangeDate(where) {
  //   const { tran_date } = where;
  //   console.log("tran", tran_date);

  //   if (tran_date && tran_date.$gte && tran_date.$lte) {
  //     const startDate = addHours(startOfDay(parseISO(tran_date.$gte)), 5);
  //     const endDate = addMinutes(addHours(addDays(startDate, 1), -1), 59);

  //     const formattedStartDate = format(startDate, "yyyy-MM-dd HH:mm:ss.SSS");
  //     const formattedEndDate = format(endDate, "yyyy-MM-dd HH:mm:ss.SSS");

  //     this.filterDate = {
  //       tran_date: {
  //         $gte: formattedStartDate,
  //         $lte: formattedEndDate,
  //       },
  //     };
  //   }

  //   console.log("date", this.filterDate);
  //   this.getObjects();
  // }
  onChangeDate(where) {
    const { tran_date } = where;
    console.log("tran", tran_date);

    if (tran_date && tran_date.$gte && tran_date.$lte) {
      const startDate = addHours(startOfDay(parseISO(tran_date.$gte)), 5);
      const endDate = addMinutes(
        addHours(addDays(startOfDay(parseISO(tran_date.$lte)), 0), 4),
        59
      );

      const formattedStartDate = format(startDate, "yyyy-MM-dd HH:mm:ss.SSS");
      const formattedEndDate = format(endDate, "yyyy-MM-dd HH:mm:ss.SSS");

      this.filterDate = {
        tran_date: {
          $gte: formattedStartDate,
          $lte: formattedEndDate,
        },
      };
    }

    console.log("date", this.filterDate);
    this.getObjects();
  }

  onChangeStore(where) {
    this.filterStore = where;
    this.getObjects();
  }

  calculateSums(objects) {
    // Filter objects to include only those with status "C"
    const filteredObjects = objects.filter((obj) => obj.status === "C");

    const sumReducer = (acc, object, key) => acc + Number(object[key] || 0);

    const sum_total = filteredObjects.reduce(
      (acc, obj) => sumReducer(acc, obj, "total"),
      0
    );
    const sum_discount = filteredObjects.reduce(
      (acc, obj) => sumReducer(acc, obj, "discount_amount"),
      0
    );
    const sum_taxes = filteredObjects.reduce(
      (acc, obj) => sumReducer(acc, obj, "total_tax"),
      0
    );

    return {
      sum_total,
      sum_discount,
      sum_taxes,
      grossSum: sum_total + sum_discount,
      // grossSales: sum_total + sum_discount + sum_taxes,
      grossSales: sum_total,
    };
  }

  calculateGrossSalesInfo() {
    // const objects = this.view.getCurrentTransaction();
    // console.log("klklk", objects);
    // this.objects = objects;
    const prevGross = 10000;
    const { grossSales } = this.calculateSums(this.objects);
    // console.log("ytyty", Number(grossSales));
    return {
      value: grossSales,
      percentage: this.calculatePercentage(grossSales, prevGross),
    };
  }

  calculateTotalTransactionInfo() {
    // const objects = this.view.getCurrentTransaction();
    const prevTransaction = 5;
    const totalTransaction = this.objects?.filter(
      (status) => status.status === "C"
    );
    return {
      value: totalTransaction?.length,
      percentage: this.calculatePercentage(
        totalTransaction?.length,
        prevTransaction
      ),
    };
  }

  calculateTotalGuestInfo() {
    const prevCust = 6;
    const custCount = this.countCustomers();
    return {
      value: custCount,
      percentage: this.calculatePercentage(custCount, prevCust),
    };
  }

  calculateDiscountCost() {
    const prevCust = 6;
    const discountSum = this.objects
      ?.filter((status) => status.status === "C")
      ?.map((cust) => Number(cust.discount_amount))
      .reduce((acc, prev) => acc + prev, 0);
    return {
      value: discountSum,
      percentage: this.calculatePercentage(discountSum, prevCust),
    };
  }

  calculateTotalTaxFree() {
    const object = this.objects;
    const taxFree = object
      ?.filter((status) => status.status === "C")
      ?.map((cust) => Number(cust.total_taxfree))
      .reduce((acc, prev) => acc + prev, 0);
    return {
      value: taxFree,
      // percentage: this.calculatePercentage(discountSum, prevCust),
    };
  }

  calculatePercentage(current, previous) {
    if (previous > 0 && current !== undefined) {
      return parseFloat(((current - previous) / previous) * 100).toFixed(2);
    }
    return 0;
  }

  countCustomers() {
    return this.objects
      ?.filter((status) => status.status === "C")
      ?.filter((cus) => cus.cust_name !== "-")
      .map((cust) => cust.cust_name).length;
  }
}

export default SaleTransPresenter;
