import React from "react";
import { NavBar as Nav, Layout, LogoHolder } from "nq-component";
import classNames from "../../classNames";
import { useNavigate } from "react-router-dom";

function NavBar2({ action }) {
  const classes = classNames("navbar navbar-expand-lg ");
  const { collapsed, setCollapse } = React.useContext(Layout.Context);
  const location = useNavigate();

  function onClickNavigate() {
    setCollapse(!collapsed);
  }

  React.useEffect(() => {
    setCollapse(false);
  }, [setCollapse]);

  const navigateProfile = () => {
    location("/account");
  };

  const backHome = () => {
    location("/");
  };

  return (
    <nav
      className={classes}
      // style={{ backgroundColor: "#83c14a" }}
      style={{
        background:
          "linear-gradient(to left, #83c14a, #83c14a 50%, #ffffff 100%)",
      }}
    >
      <div className="container-fluid">
        <button
          onClick={onClickNavigate}
          type="button"
          className="btn btn-sm btn-link fs-4 ps-0 text-dark"
        >
          <i className="bi bi-justify-left"></i>
        </button>
        <a href="# " className="navbar-brand me-auto"></a>

        <div onClick={backHome} className="d-flex">
          <img className="w-auto" height="38" src={"/figaro.png"} alt="logo" />
        </div>

        <a href="# " className="navbar-brand me-auto"></a>
        {(action && action()) || <div />}

        {/* <div
          className="text-center p-4"
          // style={{ backgroundColor: "#83c14a" }}
        >
          <LogoHolder
            className="bg-white"
            textClassName="text-dark"
            logo={user.picture}
            name={user.username}
          />
          <p className="text-white mt-3">{user.name || user.username}</p>
        </div> */}
      </div>
    </nav>
  );
}

export default NavBar2;
