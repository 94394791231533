import React from "react";
import MainPagePresenter from "./MainPagePresenter";
import { Menu } from "nq-component";
import { getAllSchemasUseCase } from "../../usecases/schema/usecases";
import { getCurrentUserUseCase, signOutUseCase } from "../../usecases/user";
import { Routes, Route } from "react-router-dom";
import { OffCanvas } from "nq-component";
import CollectionListPage from "../collection-list/CollectionListPage";
import CollectionFormPage from "../collection-form/CollectionFormPage";
import BasePage from "../../base/BasePage";
import NotFoundPage from "../notfound";
import { Layout, Progress, LogoHolder } from "nq-component";
import MigrationPage from "../migration/MigrationPage";
import AccountPage from "../account/AccountPage";
import RoleFormPage from "../role-form/RoleFormPage";
import canRead from "../../canRead";
import withRouter from "../../withRouter";
import DashboardPage from "../dashboard/DashboardPage";
import HooksPage from "../web-hook/HooksPage";
import FunctionPage from "../web-hook/FunctionPage";
import SchemaPage from "../schema/SchemaPage";
import menus from "./menus.json";
import SaleTransPage from "../sales-and-transactions/SaleTransPage";
import GrossSalesPage from "../gross-sales/GrossSalesPage";
import TotalTransactionPage from "../total-transaction/TotalTransactionPage";
import TotalGuestPage from "../total-guest/TotalGuestPage";
import DiscountCostPage from "../discount-cost/DiscountCostPage";
import ProductsPage from "../products/ProductsPage";
import ItemSoldPage from "../products/chart/item-sold/ItemSoldPage";
import CustomerPage from "../customer/CustomerPage";
import ReportPage from "../report/ReportPage";
import ReportDashPage from "../report/ReportDashPage";
import { findObjectUseCase, getObjectUseCase } from "../../usecases/object";
import Top10ItemsPage from "../products/chart/top10-menu-items/Top10ItemsPage";
import LogsPage from "../logs/LogsPage";
import TransactionPage from "../transactions/TransactionPage";

class MainPage extends BasePage {
  constructor(props) {
    super(props);
    this.presenter = new MainPagePresenter(
      this,
      getCurrentUserUseCase(),
      findObjectUseCase(),
      signOutUseCase(),
      getAllSchemasUseCase(),
      getObjectUseCase()
    );
  }

  componentDidMount() {
    this.presenter.componentDidMount();
  }

  onClickSignOut() {
    this.presenter.onClickSignOut();
  }

  onClickMenu(e, item) {
    e.preventDefault();
    this.navigateTo(item.route);
  }

  render() {
    const user = this.getCurrentUser();
    // const store = user.storeAnalytic;
    // console.log("sss", user);
    const store = this.getGlobalState();
    const schemas = this.getSchemas();
    const roles = this.getCurrentRoles();
    if (user === undefined || schemas === undefined) {
      return <Progress />;
    }
    const settings = [
      {
        name: "Edit Account",
        route: "/account",
        icon: "bi bi-person-check",
      },
      {
        name: "Schema",
        route: "/schema",
        icon: "bi bi-filetype-json",
      },
      // {
      //     name: "Notification",
      //     route: "/notification",
      //     icon: "bi bi-bell"
      // },
    ];

    const hook = [
      {
        name: "Hooks",
        route: "/hooks",
        icon: "bi bi-person-check",
      },
      {
        name: "Function",
        route: "/function",
        icon: "bi bi-person-check",
      },
    ];

    const setting = {
      name: "Settings",
      icon: "bi bi-sliders",
      route: settings,
    };

    const hooksMenu = {
      name: "WebHook",
      icon: "bi bi-sliders",
      route: hook,
    };

    const menu = [...menus];
    return (
      <Layout>
        <Layout.Context.Consumer>
          {(value) => (
            <OffCanvas onSetShow={value.setCollapse} show={value.collapsed}>
              <div
                className="offcanvas-body p-0 gradient-background text-white"
                style={{
                  background:
                    "linear-gradient(to bottom, #1064ab, #1064ab 50%, #ffffff 100%)",
                }}
              >
                <nav className="">
                  <div
                    className="text-center p-4"
                    // style={{ backgroundColor: "#83c14a" }}
                  >
                    <LogoHolder
                      className="bg-white"
                      textClassName="text-dark"
                      logo={user.picture}
                      name={user.username}
                    />
                    <p className="text-white mt-3">
                      {user.name || user.username}
                    </p>
                  </div>
                  <hr className="dropdown-divider bg-light" />
                  <div className="m-3">
                    <Menu
                      onClickItem={this.onClickMenu.bind(this)}
                      menus={menu}
                    />
                  </div>
                </nav>
              </div>
              <div className="" style={{ backgroundColor: "white" }}>
                <button
                  className="nav-link text-dark btn btn-link m-3"
                  onClick={this.onClickSignOut.bind(this)}
                >
                  <i className="bi bi-power"></i>
                  <span className="ms-2 fw-bold small">Log out</span>
                </button>
              </div>
            </OffCanvas>
          )}
        </Layout.Context.Consumer>
        <main className="vh-100 d-flex flex-column">
          <Routes>
            {/* <Route exact path={"/"} element={<DashboardPage />} /> */}
            {/*<Route*/}
            {/*  exact*/}
            {/*  path={"/collection/dashboard"}*/}
            {/*  element={<DashboardPage />}*/}
            {/*/>*/}
            <Route
              exact
              path={"/collection/:name"}
              element={<CollectionListPage />}
            />
            <Route path={"/collection/roles/form"} element={<RoleFormPage />} />
            <Route
              path={"/collection/roles/form/:id"}
              element={<RoleFormPage />}
            />
            <Route
              path={"/collection/:name/form/"}
              element={<CollectionFormPage />}
            />
            <Route
              path={"/collection/:name/form/:id"}
              element={<CollectionFormPage />}
            />
            <Route path={"/migration"} element={<MigrationPage />} />
            <Route path={"/schema"} element={<SchemaPage />} />
            <Route path={"/account"} element={<AccountPage />} />
            <Route path={"/hooks"} element={<HooksPage />} />
            <Route path={"/function"} element={<FunctionPage />} />
            {/* start */}
            <Route path={"/sales&transactions"} element={<SaleTransPage />} />
            <Route path={"/grossSales"} element={<GrossSalesPage />} />
            <Route
              path={"/totalTransaction"}
              element={<TotalTransactionPage />}
            />
            <Route path={"/totalGuest"} element={<TotalGuestPage />} />
            <Route path={"/discountCost"} element={<DiscountCostPage />} />

            {/* productModule */}
            <Route path={"/products"} element={<ProductsPage />} />
            <Route path={"/itemSold"} element={<ItemSoldPage />} />
            <Route path={"/top10"} element={<Top10ItemsPage />} />

            {/* customerModule */}
            <Route path={"/customers"} element={<CustomerPage />} />

            {/* reportModule */}
            <Route path={"/report"} element={<ReportPage />} />
            <Route path={"/dashboard"} element={<ReportDashPage />} />
            <Route path={"/logs"} element={<LogsPage />} />

            {/* Transaction Route  */}
            <Route exact path={"/transactions"} element={<TransactionPage />} />

            <Route element={<NotFoundPage />} />
          </Routes>
        </main>
      </Layout>
    );
  }
}

export default withRouter(MainPage);
