import BaseListPresenter from "../../../base/BaseListPresenter";
import { getCurrentUserUseCase } from "../../../usecases/user";

class DiscountDistributionPresenter extends BaseListPresenter {
  async findObjects() {
    // Fetch the current user and store information
    try {
      const user = await getCurrentUserUseCase().execute();
      const storeQuery = {
        include: ["all"],
        where: { email: user.email },
      };
      this.store = await this.findObjectUseCase.execute("users", storeQuery);
    } catch (error) {
      console.error("Error fetching user or store data:", error);
      return; // Exit if there's an error fetching user or store data
    }

    // Prepare the query for finding objects
    const skip = (this.current - 1) * this.limit;
    const storeCodes = this.store?.flatMap((storeItem) =>
      storeItem.storeAnalytic?.map((analytic) => analytic?.name)
    );

    const query = {
      limit: this.limit,
      skip: skip,
      where: { store_code: storeCodes },
      include: ["all"],
      sort: this.sort,
      keys: [
        "store_code",
        "tran_date",
        "total",
        "total_taxfree",
        "total_tax",
        "discount_amount",
        "createdAt",

        // "items",
      ],
    };

    // Execute the query and update the view
    try {
      this.showProgress();
      this.findObjectUseCase.abort(); // Abort any previous use case execution
      const objects = await this.findObjectUseCase.execute(
        this.view.getCollectionName(),
        query
      );
      this.objects = this.objects.concat(objects);
      this.view.setTotal(this.objects.length);
      this.view.setObjects(this.objects);
    } catch (error) {
      console.error("Error fetching objects:", error);
      this.view.showError(error);
    } finally {
      this.hideProgress();
      this.progress = false;
    }
  }
}

export default DiscountDistributionPresenter;
