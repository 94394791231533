import React, { Component } from "react";
import NavBar from "../../components/navbar";
import GrossTotalTransacPage from "./chart/grossTotalTransac/GrossTotalTransacPage";
import TotalTransacTimePage from "./chart/totalTransacTime/TotalTransacTimePage";
import TotalTransacDayPage from "./chart/totalTransacDay/TotalTransacDayPage";
import TransTypeDistributionPage from "./chart/transTypeDistribution/TransTypeDistributionPage";

class TotalTransactionPage extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <>
        <NavBar />
        <div className="overflow-auto">
          <div className="p-3 p-lg-4">
            <div className="d-flex justify-content-between align-items-center">
              <h1
                className="fw-bold mt-1 text-capitalize"
                style={{ color: "#83c14a" }}
              >
                Total Transaction
              </h1>
            </div>
            <GrossTotalTransacPage />
            <TotalTransacTimePage />
            <TotalTransacDayPage />
            <TransTypeDistributionPage />
          </div>
        </div>
      </>
    );
  }
}

export default TotalTransactionPage;
