import React, { Component } from "react";
import withRouter from "../../withRouter";
import NavBar from "../../components/navbar";
import MonthlySalesChartPage from "./chart/monthlyChart/MonthlySalesChartPage";
import AverageSalesDayPage from "./chart/averageSalesDay/AverageSalesDayPage";
import AverageSalesTimePage from "./chart/averageSalesTime/AverageSalesTimePage";
import TotalSalesDayPage from "./chart/totalSalesDay/TotalSalesDayPage";
import GrossSalesBreakdownPage from "./chart/grossSalesBreakdown/GrossSalesBreakdownPage";
import BaseListPage from "../../base/BaseListPage";
import GrossSalesPresenter from "./GrossSalesPresenter";
import { findObjectUseCase } from "../../usecases/object";
import TotalSalesWeekDayPage from "./chart/totalSalesWeekDay/TotalSalesWeekDayPage";

class GrossSalesPage extends BaseListPage {
  constructor(props) {
    super(props);
    this.presenter = new GrossSalesPresenter(this, findObjectUseCase());
    this.state = {
      store: [],
      storess: "",
    };
  }

  getCollectionName() {
    return "transactions";
  }

  // onChangeDate(where) {
  //   console.log("on change data :", where);
  //   this.presenter.onChangeDate(where);
  // }
  onChangeDate(where) {
    console.log("on change data :", where);

    // Assuming `where` contains date objects or strings that can be parsed into dates
    const gteDate = new Date(where["$gte"]);
    const lteDate = new Date(where["$lte"]);

    // Adjust the gteDate to 5:00 AM of the given day
    gteDate.setUTCHours(5, 0, 0, 0);

    // Adjust the lteDate to 4:59:59.999 AM of the next day
    lteDate.setUTCDate(lteDate.getUTCDate() + 1);
    lteDate.setUTCHours(4, 59, 59, 999);

    // Update the `where` object with the new values
    where["$gte"] = gteDate.toISOString();
    where["$lte"] = lteDate.toISOString();

    console.log("updated where clause :", where);
    this.presenter.onChangeDate(where);
  }

  onChangeStore(where) {
    this.presenter.onChangeStore(where);
    this.setState({ storess: where });
  }

  setStore(store) {
    console.log("Store", store);
    this.setState({ store });
  }

  render() {
    const objects = this.state.objects;
    const store = this.state.store;
    return (
      <>
        <NavBar />
        <div className="overflow-auto">
          <div className="p-3 p-lg-4">
            <div className="d-flex justify-content-between align-items-center">
              <h1
                className="fw-bold mt-1 text-capitalize"
                style={{ color: "#83c14a" }}
              >
                Gross Sales
              </h1>
            </div>
            <MonthlySalesChartPage
              objects={objects}
              onChangeDate={this.onChangeDate.bind(this)}
              onChangeStore={this.onChangeStore.bind(this)}
              dateField="createdAt"
              store={store}
              storess={this.state.storess}
              progress={this.presenter.progress}
            />

            {/* <AverageSalesDayPage
              objects={objects}
              onChangeDate={this.onChangeDate.bind(this)}
              onChangeStore={this.onChangeStore.bind(this)}
              dateField="createdAt"
              store={store}
              storess={this.state.storess}
            /> */}

            <TotalSalesWeekDayPage />

            <AverageSalesTimePage objects={objects} />

            <TotalSalesDayPage objects={objects} />

            <GrossSalesBreakdownPage objects={objects} />
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(GrossSalesPage);
