import BaseListPresenter from "../../base/BaseListPresenter";
import { getCurrentUserUseCase } from "../../usecases/user";
import {
  format,
  startOfDay,
  addHours,
  addMinutes,
  addDays,
  parseISO,
} from "date-fns";

class LogsPresenter extends BaseListPresenter {
  init() {
    this.limit = 2000000;
    this.where = {};
    this.search = {};
    this.filter = {};
    this.filterDate = {};
    this.filterStore = {};
    this.filterType = {};
    this.filterStore = {};
    this.status = "V";
    this.include = ["all"];
    this.keys = undefined; // if keys are specified, only those keys will be returned
    this.sort = { createdAt: -1 };
    this.progress = true;
    this.reset();
  }

  createQuery() {
    const query = {
      limit: 2000000,
      skip: (this.current - 1) * this.limit,
      where: {
        status: "V",
        createdAt: this.filterDate,
      },
      include: ["all"],
      sort: this.sort,
      keys: [
        "store_code",
        "tran_date",
        "total",
        "total_taxfree",
        "total_tax",
        "discount_amount",
        "createdAt",
        "cust_name",
        "status",
      ],
    };
    return query;
  }

  // async findObjects() {
  //   const collection = this.view.getCollectionName();
  //   const query = this.createQuery();
  //   try {
  //     this.showProgress();
  //     this.findObjectUseCase.abort();
  //     const objects = await this.findObjectUseCase.execute(collection, query);

  //     // this.objects = this.objects.concat(objects.filter(obj => obj.status === "V"));
  //     this.objects = this.objects.concat(objects);
  //     console.log("void: ", this.objects);
  //     this.view.setTotal(this.objects.length);
  //     this.view.setObjects(this.objects);
  //     this.hideProgress();
  //   } catch (error) {
  //     this.hideProgress();
  //     this.view.showError(error);
  //   }
  //   this.progress = false;
  // }
  async findObjects() {
    // Fetch the current user and store information
    try {
      const user = await getCurrentUserUseCase().execute();
      const storeQuery = {
        include: ["all"],
        where: { email: user.email },
      };
      this.store = await this.findObjectUseCase.execute("users", storeQuery);
      // const store = this.store[0].storeAnalytic;
      const store = this.store[0].storeAnalytic.sort((a, b) => {
        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0; // names must be equal
      });
      this.view.setStore(store);
    } catch (error) {
      console.error("Error fetching user or store data:", error);
      return; // Exit if there's an error fetching user or store data
    }

    // Prepare the query for finding objects
    const skip = (this.current - 1) * this.limit;
    const storeCodes = this.store?.flatMap((storeItem) =>
      storeItem.storeAnalytic?.map((analytic) => analytic?.name)
    );

    let a;

    const query = {
      limit: 2000000,
      skip: skip,
      where: {
        store_code:
          this.filterStore === "AllStore" ? storeCodes : this.filterStore,
        ...this.filterDate,
        status:
          this.filterStore === "AllStore" ||
          this.filterStore === this.filterStore
            ? "V"
            : "",
      },
      include: ["all"],
      sort: this.sort,
      keys: [
        "store_code",
        "tran_date",
        "total",
        "total_taxfree",
        "total_tax",
        "discount_amount",
        "createdAt",
        "cust_name",
        "tran_type",
        "paymenttype",
        "cust_count",
        "total_pretax",
        "status",
        // "items",
      ],
    };

    // Execute the query and update the view
    try {
      this.showProgress();
      this.findObjectUseCase.abort(); // Abort any previous use case execution
      const objects = await this.findObjectUseCase.execute(
        this.view.getCollectionName(),
        query
      );
      // this.objects = this.objects.concat(objects);
      // this.view.setTotal(this.objects.length);
      // this.view.setObjects(this.objects);
      if (Object.keys(this.filterDate).length === 0) {
        // No filterDate applied, skip updating objects
        console.log("sample");
      } else {
        // filterDate is applied, proceed with updating objects
        this.objects = this.objects.concat(objects);
        console.log("getget", this.objects.length);
        this.view.setTotal(this.objects.length);
        this.view.setObjects(this.objects);
      }
    } catch (error) {
      // console.error("Error fetching objects:", error);
      // this.view.showError(error);
    } finally {
      this.hideProgress();
      this.progress = false;
    }
  }

  componentDidMount() {
    this.getObjects();
  }

  // handleChangeDate(where) {
  //   this.filterDate = where.createdAt;
  //   this.getObjects();
  // }

  onChangeDate(where) {
    const { tran_date } = where;
    console.log("tran", tran_date);

    if (tran_date && tran_date.$gte && tran_date.$lte) {
      const startDate = addHours(startOfDay(parseISO(tran_date.$gte)), 5);
      const endDate = addMinutes(
        addHours(addDays(startOfDay(parseISO(tran_date.$lte)), 0), 4),
        59
      );

      const formattedStartDate = format(startDate, "yyyy-MM-dd HH:mm:ss.SSS");
      const formattedEndDate = format(endDate, "yyyy-MM-dd HH:mm:ss.SSS");

      this.filterDate = {
        tran_date: {
          $gte: formattedStartDate,
          $lte: formattedEndDate,
        },
      };
    }

    console.log("date", this.filterDate);
    this.getObjects();
  }

  onChangeStore(where) {
    this.filterStore = where;
    this.getObjects();
  }
}

export default LogsPresenter;
