import React from "react";
import { Pie } from "react-chartjs-2";
import "chart.js/auto";
import BaseListPage from "../../../base/BaseListPage";
import DiscountDistributionPresenter from "./DiscountDistributionPresenter";
import { findObjectUseCase } from "../../../usecases/object";
import withRouter from "../../../withRouter";
import Export from "../../../components/Export";

class DiscountDistributionPage extends BaseListPage {
  constructor(props) {
    super(props);
    this.presenter = new DiscountDistributionPresenter(
      this,
      findObjectUseCase()
    );
  }

  getCollectionName() {
    return "transactions";
  }

  countTransactionTypesFromMonth = (transactions, month, year) => {
    let totalGuest = 0;
    let totalTransaction = 0;

    transactions.forEach((transaction) => {
      const transactionDate = new Date(transaction.createdAt);
      if (
        transactionDate.getMonth() === month &&
        transactionDate.getFullYear() === year
      ) {
        totalGuest += Number(transaction.cust_count || "0");
        totalTransaction += 1;
      }
    });

    return { totalGuest, totalTransaction };
  };

  getChartData() {
    const discountCounts = {
      senior: 0,
      pwd: 0,
    };

    this.state.objects.forEach((transaction) => {
      const discountName = transaction.discount_name;
      if (discountName === "senior") {
        discountCounts.senior += 1;
      } else if (discountName === "pwd") {
        discountCounts.pwd += 1;
      }
      // Ignores "-" discount names
    });

    return {
      labels: ["Senior", "PWD"],
      datasets: [
        {
          label: "Discount Counts",
          data: [discountCounts.senior, discountCounts.pwd],
          backgroundColor: [
            "rgba(255, 99, 132, 0.5)",
            "rgba(54, 162, 235, 0.5)",
          ],
          borderColor: ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)"],
          borderWidth: 1,
        },
      ],
    };
  }

  getChartOptions() {
    return {
      responsive: true,
      plugins: {
        legend: {
          position: "top",
        },
        title: {
          display: true,
          text: "Distribution of Senior and PWD Discounts",
        },
      },
    };
  }

  render() {
    const chartData = this.getChartData();
    const chartOptions = this.getChartOptions();

    const hasData = chartData.datasets.some((dataset) =>
      dataset.data.some((count) => count > 0)
    );

    return (
      <>
        <div className=" d-flex justify-content-between align-items-center mb-1 mt-4">
          <h2 className="fw-bold text-capitalize">
            <span style={{ color: "#83c14a" }}>Discount Type</span>
            <span style={{ color: "black" }}> Distribution</span>
          </h2>
          <Export />
        </div>

        <div className="card">
          {hasData ? (
            <Pie data={chartData} options={chartOptions} />
          ) : (
            <div style={{ padding: "20px", textAlign: "center" }}>
              <p>No discount data available to display.</p>
              <Pie data={chartData} options={chartOptions} />
            </div>
          )}
        </div>
      </>
    );
  }
}

export default withRouter(DiscountDistributionPage);
