import React from "react";

function Export({ exportCsv, exportPdf }) {
  return (
    <div className="dropdown">
      <button
        className="btn p-2"
        id="dropdownMenuButton1"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <i className="bi bi-three-dots"></i>
      </button>

      <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
        <li>
          <a className="dropdown-item" href="#" onClick={exportCsv}>
            Export to CSV
          </a>
        </li>
        <li>
          <a className="dropdown-item" href="#" onClick={exportPdf}>
            Export to PDF
          </a>
        </li>
      </ul>
    </div>
  );
}

export default Export;
