export function calculateDoughnutData(objects, tooltipOptions) {
  let counts = { D: 0, T: 0, G: 0, P: 0, U: 0, B: 0, K: 0 };
  let groupCounts = {
    Solo: 0,
    Couple: 0,
    SmallGroup: 0,
    MediumGroup: 0,
    LargeGroup: 0,
  };
  let brandCounts = {
    Figaro: 0,
    "Angels Pizza": 0,
    "Tien Ma's": 0,
    "AP Express": 0,
    "Cafe Portofino": 0,
  };
  let paymentTypeCounts = {
    CASH: 0,
    CREDIT: 0,
    "GRAB-AR": 0,
    "G-CASH": 0,
    PAYMAYA: 0,
  };

  objects
    .filter((obj) => obj.status === "C")
    .forEach((item) => {
      if (counts.hasOwnProperty(item.tran_type)) {
        counts[item.tran_type]++;
      }

      const custCount = parseInt(item.cust_count, 10);
      if (custCount === 1) {
        groupCounts.Solo++;
      } else if (custCount === 2) {
        groupCounts.Couple++;
      } else if (custCount >= 3 && custCount <= 5) {
        groupCounts.SmallGroup++;
      } else if (custCount >= 6 && custCount <= 10) {
        groupCounts.MediumGroup++;
      } else if (custCount >= 11) {
        groupCounts.LargeGroup++;
      }

      // Brand count logic
      if (item.id.includes("ANGELSPIZZA")) {
        brandCounts["Angels Pizza"]++;
      }

      // Payment type count logic
      if (paymentTypeCounts.hasOwnProperty(item.paymenttype)) {
        paymentTypeCounts[item.paymenttype]++;
      }
    });

  let doughnutData = [
    {
      labels: [
        "Figaro",
        "Angels Pizza",
        "Tien Ma's",
        "AP Express",
        "Cafe Portofino",
      ],
      datasets: [
        {
          label: "Brand",
          data: [
            brandCounts["Figaro"],
            brandCounts["Angels Pizza"],
            brandCounts["Tien Ma's"],
            brandCounts["AP Express"],
            brandCounts["Cafe Portofino"],
          ],
          backgroundColor: ["#0077bb"],
          borderColor: ["#0077bb"],
          borderWidth: 1,
        },
      ],
    },
    {
      labels: ["Solo", "Couple", "Small Group", "Medium Group", "Large Group"],
      datasets: [
        {
          label: "Dataset 1",
          data: [
            groupCounts.Solo,
            groupCounts.Couple,
            groupCounts.SmallGroup,
            groupCounts.MediumGroup,
            groupCounts.LargeGroup,
          ],
          backgroundColor: [
            "#00c9f6",
            "#4f5a6b",
            "#8bc34a",
            "#fd7e14",
            "#FCD116",
          ],
          borderColor: ["#00c9f6", "#4f5a6b", "#8bc34a", "#fd7e14", "#FCD116"],
          borderWidth: 1,
        },
      ],
    },
    {
      labels: [
        "Dine-in",
        "Take-out",
        "GrabFood",
        "Pickup",
        "Delivery",
        "deltime",
        "Drive-Thru",
      ],
      options: tooltipOptions,
      datasets: [
        {
          label: "Number",
          data: [
            counts.D,
            counts.T,
            counts.G,
            counts.P,
            counts.U,
            counts.B,
            counts.K,
          ],
          backgroundColor: [
            "#0077bb",
            "#ffcd35",
            "#00c9f6",
            "#fd7e14",
            "#8bc34a",
            "#d63384",
          ],
          borderColor: [
            "#0077bb",
            "#ffcd35",
            "#00c9f6",
            "#fd7e14",
            "#8bc34a",
            "#d63384",
          ],
          borderWidth: 1,
        },
      ],
    },
    // {
    //   labels: ["N/A"],
    //   datasets: [
    //     {
    //       label: "Number",
    //       data: [100],
    //       backgroundColor: ["#0077bb"],
    //       borderColor: ["#0077bb"],
    //       borderWidth: 1,
    //     },
    //   ],
    // },
    {
      labels: ["CASH", "CREDIT", "GRAB-AR", "G-CASH", "PAYMAYA"],
      datasets: [
        {
          label: "Number",
          data: [
            paymentTypeCounts["CASH"],
            paymentTypeCounts["CREDIT"],
            paymentTypeCounts["GRAB-AR"],
            paymentTypeCounts["G-CASH"],
            paymentTypeCounts["PAYMAYA"],
          ],
          backgroundColor: ["#ffd969", "#00c9f6", "#4f5a6b"],
          borderColor: ["#ffd969", "#00c9f6", "#4f5a6b"],
          borderWidth: 1,
        },
      ],
    },
    // {
    //   labels: ["Luzon - NCR"],
    //   datasets: [
    //     {
    //       label: "Number",
    //       data: [100],
    //       backgroundColor: ["#0077bb"],
    //       borderColor: ["#0077bb"],
    //       borderWidth: 1,
    //     },
    //   ],
    // },
    // {
    //   labels: ["Mall", "Stand Alone"],
    //   datasets: [
    //     {
    //       label: "Number",
    //       data: [37, 63],
    //       backgroundColor: ["#0077bb", "#00c9f6"],
    //       borderColor: ["#0077bb", "#00c9f6"],
    //       borderWidth: 1,
    //     },
    //   ],
    // },
    // {
    //   labels: ["Mayon", "Shangrila"],
    //   datasets: [
    //     {
    //       label: "Number",
    //       data: [37, 63],
    //       backgroundColor: ["#0077bb", "#00c9f6"],
    //       borderColor: ["#0077bb", "#00c9f6"],
    //       borderWidth: 1,
    //     },
    //   ],
    // },
  ];

  return doughnutData;
}
