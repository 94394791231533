import BaseListPresenter from "../../../../base/BaseListPresenter";

class GrossSalesBreakdownPresenter extends BaseListPresenter {
  async findObjects() {
    const collection = this.view.getCollectionName();
    const query = this.createQuery();
    try {
      this.showProgress();
      this.findObjectUseCase.abort();
      const objects = await this.findObjectUseCase.execute(collection, query);
      this.objects = this.objects.concat(objects);
      this.view.setTotal(this.objects.length);
      this.view.setObjects(this.objects);
      this.hideProgress();
    } catch (error) {
      this.hideProgress();
      this.view.showError(error);
    }
    this.progress = false;
  }
}

export default GrossSalesBreakdownPresenter;
