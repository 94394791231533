import React from "react";
import NavBar2 from "../NavBar2";
import { Layout } from "nq-component";

function NavBar(props) {
  const { collapsed, setCollapse } = React.useContext(Layout.Context);

  function onClickNavigate() {
    setCollapse(!collapsed);
  }

  return (
    <NavBar2
      className="shadow-sm"
      title="DASHBOARD"
      onClickNavigate={onClickNavigate}
      {...props}
    />
  );
}

export default NavBar;
