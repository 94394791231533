import React from "react";

function ExportPdfs({ antipoloTransactions, retiroTransactions }) {
  const StoreRow = ({ store, transactions }) => {
    // Calculate gross sales
    const grossSales = transactions
      .map(
        (t) => Number(t.total) + Number(t.discount_amount) - Number(t.total_tax)
      )
      .reduce((acc, curr) => acc + curr, 0);

    // Calculate net sales (gross sales - total discounts)
    const totalDiscounts = transactions
      .map((t) => Number(t.discount_amount))
      .reduce((acc, curr) => acc + curr, 0);

    const netSales = grossSales - totalDiscounts;

    const formattedNetSales = new Intl.NumberFormat("en-US", {
      style: "decimal",
      maximumFractionDigits: 2,
    }).format(netSales);

    return (
      <tr>
        <td>{store}</td>
        <td>{transactions.length}</td>
        <td>₱ {formattedNetSales}</td>
      </tr>
    );
  };
  return (
    <div style={{ padding: "10px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <img width="100px" src="/figaro.png" />
        <div className="text-center">
          <h4 className="mb-0">FIGARO COFFEE SYSTEM, INC.</h4>
        </div>

        <img width="100px" src="/angels.png" />
      </div>
      <div
        style={{
          marginTop: "60px",
          display: "flex",
          flexWrap: "wrap",
          gap: "1rem",
        }}
      >
        <table
          style={{
            width: "100%",
            marginBottom: "1rem",
            color: "inherit",
            textAlign: "left",
          }}
        >
          <thead>
            <tr>
              <th
                style={{
                  backgroundColor: "black",
                  color: "white",
                  padding: "0.75rem",
                  verticalAlign: "bottom",
                  borderBottom: "2px solid #dee2e6",
                }}
              >
                STORE
              </th>
              <th
                style={{
                  backgroundColor: "black",
                  color: "white",
                  padding: "0.75rem",
                  verticalAlign: "bottom",
                  borderBottom: "2px solid #dee2e6",
                }}
              >
                TC
              </th>
              <th
                style={{
                  backgroundColor: "black",
                  color: "white",
                  padding: "0.75rem",
                  verticalAlign: "bottom",
                  borderBottom: "2px solid #dee2e6",
                }}
              >
                AMOUNT
              </th>
            </tr>
          </thead>
          <tbody>
            <StoreRow store="Antipolo" transactions={antipoloTransactions} />
            <StoreRow store="Retiro" transactions={retiroTransactions} />
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ExportPdfs;
