export const createCsvString = (data) => {
  let csvString = "Category,Item Name,Item Count\n"; // CSV Header
  Object.entries(data).forEach(([category, items]) => {
    items.forEach((item) => {
      csvString += `${category.charAt(0).toUpperCase() + category.slice(1)},${
        item.description
      },${item.quantity}\n`;
    });
  });
  return csvString;
};

export const downloadCsv = (csvString, filename = "export.csv") => {
  const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
