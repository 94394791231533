import React from "react";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import BaseListPage from "../../../../base/BaseListPage";
import withRouter from "../../../../withRouter";
import TotalTransacTimePresenter from "./TotalTransacTimePresenter";
import { findObjectUseCase } from "../../../../usecases/object";
import Export from "../../../../components/Export";
import printChart from "../../../../PrintChart";
import { Progress } from "nq-component";
import { format, startOfDay, addHours, addMinutes, addDays } from "date-fns";

class TotalTransacTimePage extends BaseListPage {
  constructor(props) {
    super(props);
    this.presenter = new TotalTransacTimePresenter(this, findObjectUseCase());
    this.state = {
      objects: [],
      selectedDate: new Date().toISOString().split("T")[0],
      selectedStoreCode: "",
      store: [],
      storess: "",
    };
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleStoreCodeChange = this.handleStoreCodeChange.bind(this);
  }

  getCollectionName() {
    return "transactions";
  }

  setStore(store) {
    console.log("storessss", store);
    this.setState({ store });
  }

  onChangeStore(where) {
    console.log("store Onchange", where);
    this.setState({ storess: where });
    this.presenter.onChangeStore(where);
  }

  getUniqueStoreCodes() {
    const storeCodes = this.state.objects.map((obj) =>
      // Remove "ANGELSPIZZA_" from the start and return the rest
      obj.store_code.replace("ANGELSPIZZA_", "")
    );

    console.log("store", storeCodes);

    return [...new Set(storeCodes)]; // Remove duplicates
  }

  handleDateChange(event) {
    this.setState({ selectedDate: event.target.value });
  }

  handleStoreCodeChange(event) {
    this.setState({ selectedStoreCode: event.target.value }); // Update the state when store code changes
  }

  // filterTransactions(transactions) {
  //   const selectedDate = new Date(this.state.selectedDate);
  //   selectedDate.setHours(0, 0, 0, 0);
  //   console.log("Selected Date: ", selectedDate);

  //   return transactions
  //     .filter((status) => status.status === "C")
  //     .filter((transaction) => {
  //       const transactionDate = new Date(transaction.tran_date);
  //       transactionDate.setHours(0, 0, 0, 0);

  //       const selectedDateMatch =
  //         transactionDate.getTime() === selectedDate.getTime();
  //       const storeCodeMatch = this.state.selectedStoreCode
  //         ? transaction.store_code.replace("ANGELSPIZZA_", "") ===
  //           this.state.selectedStoreCode
  //         : true;

  //       return selectedDateMatch && storeCodeMatch;
  //     });
  // }
  filterTransactions(transactions) {
    const selectedDate = new Date(this.state.selectedDate);
    selectedDate.setHours(0, 0, 0, 0);

    const startDate = addHours(startOfDay(selectedDate), 5);
    const endDate = addMinutes(addHours(addDays(startDate, 1), -1), 59);

    const formattedStartDate = format(startDate, "yyyy-MM-dd HH:mm:ss.SSS");
    const formattedEndDate = format(endDate, "yyyy-MM-dd HH:mm:ss.SSS");

    console.log("Selected Date Range: ", {
      $gte: formattedStartDate,
      $lte: formattedEndDate,
    });

    return transactions
      .filter((status) => status.status === "C")
      .filter((transaction) => {
        const transactionDate = new Date(transaction.tran_date);

        const transactionDateMatch =
          transactionDate >= startDate && transactionDate <= endDate;
        const storeCodeMatch = this.state.selectedStoreCode
          ? transaction.store_code.replace("ANGELSPIZZA_", "") ===
            this.state.selectedStoreCode
          : true;

        return transactionDateMatch && storeCodeMatch;
      });
  }

  countTransactionTypesPerHour = (transactions) => {
    const counts = {
      DineIn: new Array(24).fill(0),
      GrabFood: new Array(24).fill(0),
      TakeOut: new Array(24).fill(0),
      Delivery: new Array(7).fill(0),
      Drive: new Array(7).fill(0),
    };

    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);

    const filteredTransactions = transactions
      .filter((status) => status.status === "C")
      .filter((transaction) => {
        const transactionDate = new Date(transaction.tran_date);
        // transactionDate.setHours(0, 0, 0, 0);
        // return transactionDate.getTime() === currentDate.getTime();
        return transactionDate;
      });

    // Count transactions per type for each hour
    filteredTransactions.forEach((transaction) => {
      const hour = new Date(transaction.tran_date).getHours();
      const index = hour < 5 ? hour + 19 : hour - 5;
      switch (transaction.tran_type) {
        case "D": // Dine-in
          counts.DineIn[index]++;
          break;
        case "G": // GrabFood
          counts.GrabFood[index]++;
          break;
        case "T": // Take-out
          counts.TakeOut[index]++;
          break;
        case "U": // Take-out
          counts.Delivery[index]++;
          break;
        case "K": // Take-out
          counts.Drive[index]++;
          break;
        default:
          break;
      }
    });

    return counts;
  };

  getChartData() {
    const filteredTransactions = this.filterTransactions(this.state.objects);
    const newCounts = this.countTransactionTypesPerHour(filteredTransactions);
    const labels = Array.from({ length: 24 }, (_, i) => {
      const hour = (5 + i) % 24;
      return `${hour}:00`;
    });
    return {
      labels: labels,
      datasets: [
        {
          label: "Dine-in",
          data: newCounts.DineIn,
          borderColor: "rgb(53, 162, 235)",
          backgroundColor: "rgba(53, 162, 235, 0.5)",
          fill: true,
        },
        {
          label: "GrabFood",
          data: newCounts.GrabFood,
          borderColor: "rgb(255, 205, 86)",
          backgroundColor: "rgba(255, 205, 86, 0.5)",
          fill: true,
        },
        {
          label: "Take-out",
          data: newCounts.TakeOut,
          borderColor: "rgb(75, 192, 192)",
          backgroundColor: "rgba(75, 192, 192, 0.5)",
          fill: true,
        },
        {
          label: "Delivery",
          data: newCounts.Delivery,
          borderColor: "rgb(400, 192, 192)",
          backgroundColor: "rgba(400, 192, 192, 0.5)",
          fill: true,
        },
        {
          label: "Drive-thru",
          data: newCounts.Drive,
          borderColor: "rgb(198, 219, 86)",
          backgroundColor: "rgba(198, 219, 86, 0.5)",
          fill: true,
        },
      ],
    };
  }

  getChartOptions() {
    return {
      chartOptions: {
        responsive: true,
        plugins: {
          legend: {
            position: "top",
          },
          title: {
            display: true,
            text: "Total Transactions vs Time of Day (Hours)",
          },
        },
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
          },
        },
      },
    };
  }

  convertToCSV(objArray) {
    const array =
      typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
    let str =
      `${Object.keys(array[0])
        .map((value) => `"${value}"`)
        .join(",")}` + "\r\n";
    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in array[i]) {
        if (line !== "") line += ",";
        line += `"${array[i][index]}"`;
      }
      str += line + "\r\n";
    }
    return str;
  }

  exportToCSV = () => {
    const { objects, selectedStoreCode, selectedDate } = this.state;

    // Use your existing logic to get the counts per day for each transaction type
    const transactionCounts = this.countTransactionTypesPerHour(objects);

    // Assuming 'chartData.labels' are days of the week from 'Sunday' to 'Saturday'
    const chartData = this.getChartData();
    const csvData = chartData.labels.map((day, index) => {
      return {
        Brand: "Angels Pizza", // Replace with actual brand logic
        Store: selectedStoreCode || "All Stores",
        Date: selectedDate, // Or the date that corresponds to the 'day'
        DineIn: transactionCounts.DineIn[index],
        GrabFood: transactionCounts.GrabFood[index],
        TakeOut: transactionCounts.TakeOut[index],
      };
    });

    const csvString = this.convertToCSV(csvData);

    // Trigger the download
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "TotalTransactions.csv";
    a.click();
    window.URL.revokeObjectURL(url);
  };

  render() {
    const chartData = this.getChartData();
    const chartOptions = this.getChartOptions();
    const uniqueStoreCodes = this.getUniqueStoreCodes();
    const chartId = "chart-container7";

    const formatSales = (sales) => {
      return sales.toLocaleString("en-US", {
        style: "currency",
        currency: "PHP",
      });
    };
    return (
      <>
        <div className="d-flex justify-content-between align-items-center mb-1 mt-4">
          <h2 className="fw-bold text-capitalize">
            <span style={{ color: "black" }}>Total</span>
            <span style={{ color: "#83c14a" }}> Transaction</span>
            <span style={{ color: "black" }}> vs.</span>
            <span style={{ color: "#c5da55" }}> Time of Day</span>
          </h2>
          <Export
            exportCsv={() => this.exportToCSV()}
            exportPdf={() => printChart(chartId)}
          />
        </div>

        <div className="row mb-2">
          <div className="col-sm-2">
            <input
              className="form-control"
              type="date"
              value={this.state.selectedDate}
              onChange={this.handleDateChange}
            />
          </div>
          <div className="col-sm-3">
            <select
              className="form-select"
              // value={this.state.selectedStoreCode}
              onChange={(e) => this.onChangeStore(e.target.value)}
            >
              <option value="">Select Store</option>
              <option value="AllStore">All Store</option>
              {this.state.store.map((code, index) => (
                <option key={index} value={code.name}>
                  {code.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        {this.state.objects && this.state.objects.length > 0 ? (
          <div ref={this.chartRef1} id="chart-container7">
            <div className="card">
              <Line data={chartData} options={chartOptions} />
            </div>
            <div className="print-only-content">
              <h2>Report Details</h2>
              <div className=" print-only-content">
                <div className="">
                  <h3>Brand: Angels Pizza</h3>
                  <h3>Store: {this.state.storess}</h3>
                  <h3>Date: {this.state.selectedDate}</h3>
                </div>
              </div>
              <div className="" style={{ fontSize: "20px" }}>
                <table className="table text-center">
                  <thead>
                    <tr className="d-flex">
                      <th>Time</th>
                      <th>Transaction Count</th>
                    </tr>
                  </thead>
                  <tbody>
                    {chartData.labels.map((label, index) => (
                      <tr key={index}>
                        <td>{label}</td>
                        <td>{chartData.datasets[0].data[index]}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : this.state.storess ? (
          <div className="text-center mt-5">
            <Progress />
            <h6>Processing... Please wait.</h6>
          </div>
        ) : (
          <div className="text-center align-items-center mt-4">
            <img
              src="./choosefirst.png"
              style={{ height: "200px", width: "200px" }}
            />
            <h4>To proceed, please select a store first</h4>
          </div>
        )}
      </>
    );
  }
}

export default withRouter(TotalTransacTimePage);
