import React, { Component } from "react";
import NavBar from "../../components/navbar";
import SalesDiscountAmountPage from "./salesDiscountAmount/SalesDiscountAmountPage";
import DiscountGrossPage from "./discountGross/DiscountGrossPage";
import DiscountDayTimePage from "./discountDayTime/DiscountDayTimePage";
import DiscountDayWeekPage from "./discountDayWeek/DiscountDayWeekPage";
import DiscountDistributionPage from "./discountDistribution/DiscountDistributionPage";

class DiscountCostPage extends Component {
  render() {
    return (
      <>
        <NavBar />
        <div className="overflow-auto">
          <div className="p-3 p-lg-4">
            <div className="d-flex justify-content-between align-items-center">
              <h1
                className="fw-bold mt-1 text-capitalize"
                style={{ color: "#83c14a" }}
              >
                Discount Cost
              </h1>
            </div>
            <SalesDiscountAmountPage />
            <DiscountGrossPage />
            <DiscountDayTimePage />
            <DiscountDayWeekPage />
            <DiscountDistributionPage />
          </div>
        </div>
      </>
    );
  }
}

export default DiscountCostPage;
