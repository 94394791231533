import React, { useState, useEffect, useMemo } from "react";
import { Bar } from "react-chartjs-2";
import "chartjs-plugin-datalabels";
import "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import "./index.css";
import { InputSelect } from "nq-component";
import Export from "./components/Export";
import Graph from "./components/Graph";

function MonthlySalesChart({ objects, storess }) {
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [isComparisonMode, setIsComparisonMode] = useState(false);

  const [filteredData, setFilteredData] = useState([]);
  const [optionss, setOptionss] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [filterBox, setFilterBox] = useState(false);

  useEffect(() => {
    processData();
  }, [objects]);

  const processData = () => {
    const data = objects;

    const storeCodes = data.reduce((acc, item) => {
      const code = item.store_code.split("ANGELSPIZZA_")[1]; // Remove prefix
      if (code && !acc.includes(code)) {
        acc.push(code);
      }
      return acc;
    }, []);

    setOptionss(storeCodes.map((code) => ({ value: code, label: code })));
  };

  const onChangeType = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  // Pre-process ung objects para sa date parsing
  // Modify the useMemo that processes the objects
  const parsedObjects = useMemo(() => {
    return objects.map((obj) => {
      const parts = obj.store_code.split("ANGELSPIZZA_");
      const codePart = parts.length > 1 ? parts[1] : ""; // Get the part after 'ANGELSPIZZA_'
      return {
        ...obj,
        parsedMonth: new Date(obj.tran_date).toLocaleString("default", {
          month: "long",
        }),
        parsedYear: new Date(obj.tran_date).getFullYear().toString(),
        storeCodePart: codePart, // Add this property
      };
    });
  }, [objects]);

  // Update filtered data when selections change
  useEffect(() => {
    const filtered = parsedObjects.filter((obj) => {
      const matchesMonth = selectedMonth
        ? obj.parsedMonth === selectedMonth
        : true;
      const matchesYear = selectedYear ? obj.parsedYear === selectedYear : true;
      const matchesOption = selectedOption
        ? obj.storeCodePart === selectedOption // Compare using the new storeCodePart
        : true;
      return matchesMonth && matchesYear && matchesOption;
    });
    setFilteredData(filtered);
  }, [parsedObjects, selectedMonth, selectedYear, selectedOption]);

  // Compute sales data using the filtered data
  const salesData = useMemo(() => {
    const result = filteredData
      .filter((obj) => obj.status === "C")
      .reduce((acc, obj) => {
        const monthYear = `${obj.parsedMonth} ${obj.parsedYear}`;
        const gross = Number(obj.total);
        // +
        // parseFloat(obj.discount_amount) +
        // parseFloat(obj.total_tax);
        const sales = gross - parseFloat(obj.total_tax);
        // - parseFloat(obj.discount_amount);

        if (!acc[monthYear]) {
          acc[monthYear] = { gross: 0, sales: 0 };
        }
        acc[monthYear].gross += gross;
        acc[monthYear].sales += sales;

        return acc;
      }, {});

    if (selectedMonth && !selectedYear) {
      return Object.keys(result)
        .sort((a, b) => new Date(a) - new Date(b))
        .reduce((obj, key) => {
          obj[key] = result[key];
          return obj;
        }, {});
    }
    return result;
  }, [filteredData, selectedMonth, selectedYear]);

  // Preparing chart data
  const chartLabels = Object.keys(salesData);
  const chartSalesData = Object.values(salesData).map((data) => data.sales);

  const data = {
    labels: chartLabels.reverse(),
    datasets: [
      {
        label: "Monthly Sales",
        data: chartSalesData,
        backgroundColor: ["#83c14a"],
        borderColor: ["#83c14a"],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
      x: {
        beginAtZero: true,
      },
    },

    plugins: {
      datalabels: {
        color: "#FFF",
        anchor: "center",
        align: "center",
        font: {
          size: 20,
        },
        formatter: (value, context) => {
          return value.toLocaleString();
        },
      },
    },
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
    setIsComparisonMode(event.target.value === "Comparison");
  };

  const months = useMemo(() => {
    return [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
  }, []);

  const years = useMemo(() => {
    return Array.from(
      new Set(objects.map((obj) => new Date(obj.tran_date).getFullYear()))
    );
  }, [objects]);

  function convertToCSV(objArray) {
    const array =
      typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
    let str =
      `${Object.keys(array[0])
        .map((value) => `"${value}"`)
        .join(",")}` + "\r\n";

    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in array[i]) {
        if (line !== "") line += ",";

        line += `"${array[i][index]}"`;
      }

      str += line + "\r\n";
    }

    return str;
  }

  const exportToCSV = () => {
    const csvData = [];
    Object.entries(salesData).forEach(([monthYear, data]) => {
      csvData.push({
        brand: "Angels Pizza",
        store: storess,
        monthYear,
        sales: data.sales,
      });
    });

    const csvString = convertToCSV(csvData);
    const a = document.createElement("a");
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = "monthly-sales-data.csv";
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const exportChartAsPDFs = () => {
    setTimeout(() => {
      window.print();
    }, 500);
  };

  console.log("sales data", salesData);

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-1">
        <h2 className="fw-bold text-capitalize">
          <span style={{ color: "darkcolor" }}>Monthly</span>
          <span style={{ color: "#83c14a" }}> Sales Chart</span>
        </h2>
        <button
          className="btn d-block d-md-none"
          onClick={() => setFilterBox(!filterBox)}
        >
          <i class="bi bi-filter"></i>
        </button>

        <Export exportCsv={exportToCSV} exportPdf={exportChartAsPDFs} />
      </div>

      <div className="container mt-1 mb-3">
        {filterBox && (
          <div className="row">
            {/* <div className="col-sm-3">
              <InputSelect placeholder="Select Brand" />
            </div> */}
            <div className="col-sm-3">
              <InputSelect
                placeholder="Select Store"
                options={optionss}
                onChange={(selectedOption) => {
                  onChangeType(selectedOption);
                }}
              />
            </div>
            <div className="col-sm-2">
              <select
                id="month-select"
                className="form-select"
                value={selectedMonth}
                onChange={handleMonthChange}
              >
                <option value="">All Months</option>
                {months.map((month, index) => (
                  <option key={index} value={month}>
                    {month}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-sm-2">
              <select
                id="year-select"
                className="form-select"
                value={selectedYear}
                onChange={handleYearChange}
              >
                <option value="">All Years</option>
                {years.map((year) => (
                  <option key={year} value={year.toString()}>
                    {year}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}
      </div>

      <div className="container mt-1 mb-3 d-none d-md-block">
        <div className="row">
          <div className="col-sm-2">
            <select
              id="year-select"
              className="form-select"
              value={selectedYear}
              onChange={handleYearChange}
            >
              <option value="">All Years</option>
              <option value="Comparison">Comparison</option>
              {years.map((year) => (
                <option key={year} value={year.toString()}>
                  {year}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <Graph
        salesData={salesData}
        data={data}
        options={options}
        storess={storess}
        plugins={[ChartDataLabels]}
      />
    </>
  );
}

export default MonthlySalesChart;
