import React from "react";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import BaseListPage from "../../../../base/BaseListPage";
import withRouter from "../../../../withRouter";
import { findObjectUseCase } from "../../../../usecases/object";
import TotalTransacDayPresenter from "./TotalTransacDayPresenter";
import Export from "../../../../components/Export";
import printChart from "../../../../PrintChart";
import { Progress } from "nq-component";
import DateRange from "../../../../components/DateRange";
import DateRangeReport from "../../../../components/DateRangeReport";

class TotalTransacDayPage extends BaseListPage {
  constructor(props) {
    super(props);
    this.presenter = new TotalTransacDayPresenter(this, findObjectUseCase());
    this.state = {
      objects: [],
      startDate: "",
      endDate: "",
      selectedStoreCode: "",
      store: [],
      storess: "",
      range: "",
    };
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.handleStoreCodeChange = this.handleStoreCodeChange.bind(this);
  }

  getCollectionName() {
    return "transactions";
  }

  setStore(store) {
    console.log("storessss", store);
    this.setState({ store });
  }
  onChangeStore(where) {
    console.log("store Onchange", where);
    this.setState({ storess: where });
    this.presenter.onChangeStore(where);
  }

  onChangeDate(where) {
    this.presenter.onChangeDate(where);
    this.setState({ range: where });
  }

  getUniqueStoreCodes() {
    const storeCodes = this.state.objects.map((obj) =>
      // Remove "ANGELSPIZZA_" from the start and return the rest
      obj.store_code.replace("ANGELSPIZZA_", "")
    );

    console.log("store", storeCodes);

    return [...new Set(storeCodes)]; // Remove duplicates
  }

  handleStartDateChange(event) {
    this.setState({ startDate: event.target.value });
  }

  handleEndDateChange(event) {
    this.setState({ endDate: event.target.value });
  }

  handleStoreCodeChange(event) {
    this.setState({ selectedStoreCode: event.target.value });
  }

  filterTransactions() {
    const { objects, startDate, endDate, selectedStoreCode } = this.state;

    return objects
      .filter((status) => status.status === "C")
      .filter((transaction) => {
        const transactionDate = new Date(transaction.tran_date);
        const start = startDate
          ? new Date(startDate)
          : new Date(-8640000000000000);
        const end = endDate ? new Date(endDate) : new Date();
        end.setHours(23, 59, 59, 999);
        const isDateInRange =
          (!startDate || transactionDate >= start) &&
          (!endDate || transactionDate <= end);
        const isStoreCodeMatch = selectedStoreCode
          ? transaction.store_code.replace("ANGELSPIZZA_", "") ===
            selectedStoreCode
          : true;
        console.log("iaiaiaia", isStoreCodeMatch);

        return isDateInRange && isStoreCodeMatch;
      });
  }

  countTransactionTypesPerDayOfWeek = (transactions) => {
    const counts = {
      DineIn: new Array(7).fill(0),
      GrabFood: new Array(7).fill(0),
      TakeOut: new Array(7).fill(0),
      Delivery: new Array(7).fill(0),
      Drive: new Array(7).fill(0),
    };

    transactions
      .filter((status) => status.status === "C")
      .forEach((transaction) => {
        const dayOfWeek = new Date(transaction.tran_date).getDay();
        switch (transaction.tran_type) {
          case "D": // Dine-in
            counts.DineIn[dayOfWeek]++;
            break;
          case "G": // GrabFood
            counts.GrabFood[dayOfWeek]++;
            break;
          case "T": // Take-out
            counts.TakeOut[dayOfWeek]++;
            break;
          case "U": // Take-out
            counts.Delivery[dayOfWeek]++;
            break;
          case "K": // Take-out
            counts.Drive[dayOfWeek]++;
            break;
          default:
            break;
        }
      });

    return counts;
  };

  getChartData() {
    const filteredTransactions = this.filterTransactions();
    const newCounts = this.countTransactionTypesPerDayOfWeek(
      filteredTransactions,
      new Date(this.state.startDate).getMonth(),
      new Date(this.state.startDate).getFullYear()
    );

    return {
      labels: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      datasets: [
        {
          label: "Dine-in",
          data: newCounts.DineIn,
          borderColor: "#1b74bb",
          backgroundColor: "rgba(53, 162, 235, 0.5)",
          fill: true,
        },
        {
          label: "GrabFood",
          data: newCounts.GrabFood,
          borderColor: "rgb(255, 205, 86)",
          backgroundColor: "rgba(255, 205, 86, 0.5)",
          fill: true,
        },
        {
          label: "Take-out",
          data: newCounts.TakeOut,
          borderColor: "rgb(75, 192, 192)",
          backgroundColor: "rgba(75, 192, 192, 0.5)",
          fill: true,
        },
        {
          label: "Delivery",
          data: newCounts.Delivery,
          borderColor: "rgb(400, 192, 192)",
          backgroundColor: "rgba(400, 192, 192, 0.5)",
          fill: true,
        },
        {
          label: "Drive-thru",
          data: newCounts.Drive,
          borderColor: "rgb(198, 219, 86)",
          backgroundColor: "rgba(198, 219, 86, 0.5)",
          fill: true,
        },
      ],
    };
  }

  getChartOptions() {
    return {
      chartOptions: {
        responsive: true,
        plugins: {
          legend: {
            position: "top",
          },
          title: {
            display: true,
            text: "Total Transactions vs Time of Day (Hours)",
          },
        },
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
          },
        },
      },
    };
  }

  convertToCSV(objArray) {
    const array =
      typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
    let str =
      `${Object.keys(array[0])
        .map((value) => `"${value}"`)
        .join(",")}` + "\r\n";
    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in array[i]) {
        if (line !== "") line += ",";
        line += `"${array[i][index]}"`;
      }
      str += line + "\r\n";
    }
    return str;
  }

  exportToCSV = () => {
    const { selectedStoreCode, startDate, endDate, storess } = this.state;
    const filteredTransactions = this.filterTransactions();
    const transactionCounts =
      this.countTransactionTypesPerDayOfWeek(filteredTransactions);

    // Assuming chartData.labels are days of the week
    const chartData = this.getChartData();
    const daysOfWeek = chartData.labels;

    const csvData = daysOfWeek.map((day, index) => {
      return {
        Brand: "Angels Pizza", // Replace with actual brand logic
        Store: storess || "All Stores",
        DateRange: `${this.state.range.tran_date["$lte"].split("T")[0]} ${
          this.state.range.tran_date["$gte"].split("T")[0]
        }`,
        Day: day,
        DineIn: transactionCounts.DineIn[index],
        GrabFood: transactionCounts.GrabFood[index],
        TakeOut: transactionCounts.TakeOut[index],
        Delivery: transactionCounts.Delivery[index],
        DriveThru: transactionCounts.Drive[index],
      };
    });

    const csvString = this.convertToCSV(csvData);

    // Trigger the download
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "TotalTransactionsByDay.csv";
    a.click();
    window.URL.revokeObjectURL(url);
  };

  render() {
    const chartData = this.getChartData();
    const chartOptions = this.getChartOptions();
    const { startDate, endDate } = this.state;
    const uniqueStoreCodes = this.getUniqueStoreCodes();
    const chartId = "chart-container8";

    return (
      <>
        <div className="d-flex justify-content-between align-items-center mb-1 mt-4">
          <h2 className="fw-bold text-capitalize">
            <span style={{ color: "black" }}>Total</span>
            <span style={{ color: "#83c14a" }}> Transaction</span>
            <span style={{ color: "black" }}> vs.</span>
            <span style={{ color: "#c5da55" }}> Day of Week</span>
          </h2>
          <Export
            exportCsv={() => this.exportToCSV()}
            exportPdf={() => printChart(chartId)}
          />
        </div>
        <div className="row mb-2">
          <div className="col-sm-4" style={{ maxWidth: "max-content" }}>
            <DateRangeReport
              onChange={this.onChangeDate.bind(this)}
              field="tran_date"
            />
          </div>
          <div className="col-sm-3">
            <select
              className="form-select"
              // value={this.state.selectedStoreCode}
              onChange={(e) => this.onChangeStore(e.target.value)}
              disabled={this.state.range ? false : true}
            >
              <option value="">Select Store</option>
              <option value="AllStore">All Store</option>
              {this.state.store.map((code, index) => (
                <option key={index} value={code.name}>
                  {code.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        {this.state.objects && this.state.objects.length > 0 ? (
          <div ref={this.chartRef1} id="chart-container8">
            <div className="card">
              <Line data={chartData} options={chartOptions} />
            </div>
            <div className="print-only-content">
              <h2>Report Details</h2>
              <div className="d-flex justify-content-between align-items-center print-only-content">
                <h3>Brand: Angels Pizza</h3>
                <h4 className="mx-auto"></h4>
                <h3>Store: {this.state.storess}</h3>
                <h3>
                  Date:{" "}
                  {`${this.state.range?.tran_date["$lte"]?.split("T")[0]} ${
                    this.state.range?.tran_date["$gte"]?.split("T")[0]
                  }`}
                </h3>
              </div>
              <div className="" style={{ fontSize: "20px" }}>
                <table className="table text-center">
                  <thead>
                    <tr className="d-flex">
                      <th>Day</th>
                      <th className="ms-5">Count</th>
                    </tr>{" "}
                  </thead>
                  <tbody>
                    {chartData.labels.map((label, index) => (
                      <tr className="d-flex" key={label}>
                        <td>{label}</td>
                        <td>{chartData.datasets[0].data[index]}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : this.state.storess && this.state.range ? (
          <div className="text-center mt-5">
            <Progress />
            <h6>Processing... Please wait.</h6>
          </div>
        ) : (
          <div className="text-center align-items-center mt-4">
            <img
              src="./choosefirst.png"
              style={{ height: "200px", width: "200px" }}
            />
            <h4>To proceed, please select a date first</h4>
          </div>
        )}
      </>
    );
  }
}

export default withRouter(TotalTransacDayPage);
