import React, { useState } from 'react'
import { formatAmount } from '../utils/formatAmount'

export default function Table({ grossSaleData }) {
  const [selectedItem, setSelectedItem] = useState(null)
  const [detailsVisible, setDetailsVisible] = useState(false) // New state for details visibility

  const handleRowClick = (index) => {
    setSelectedItem(selectedItem === index ? null : index)
    setDetailsVisible(true) // Show details when clicking a row
  }

  return (
    <div className='row mt-1 g-3'>
      <table className='table'>
        <thead>
          <tr>
            <th style={{ backgroundColor: '#83c14a', color: '#fff' }}>Brand</th>
            <th style={{ backgroundColor: '#83c14a', color: '#fff' }}>
              Location
            </th>
            <th style={{ backgroundColor: '#83c14a', color: '#fff' }}>
              Gross Sales
            </th>
          </tr>
        </thead>
        <tbody>
          {grossSaleData.map((item, i) => (
            <React.Fragment key={i}>
              <tr
                onClick={() => handleRowClick(i)}
                style={{ cursor: 'pointer' }}
              >
                <td>{item.brand}</td>
                <td>{item.location}</td>
                <td>{formatAmount(parseFloat(item.gross_sale))}</td>
              </tr>
              {selectedItem === i && (
                <tr key={i}>
                  <td colSpan='3'>
                    <ul style={{ listStyle: 'none', paddingTop: '12px' }}>
                      <li>
                        <span style={{ fontWeight: 'bold' }}>Brand:</span>{' '}
                        {item.brand}
                      </li>
                      <li>
                        <span style={{ fontWeight: 'bold' }}>Location:</span>{' '}
                        {item.location}
                      </li>
                      <li>
                        <span style={{ fontWeight: 'bold' }}>Years:</span>{' '}
                        {item.years}
                      </li>
                      <li>
                        <span style={{ fontWeight: 'bold' }}>Gross Sales:</span>{' '}
                        {formatAmount(parseFloat(item.gross_sale))}
                      </li>
                    </ul>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  )
}
