export function formatAmount(number) {
  if (isNaN(number)) {
    return 'Invalid number'
  }

  const absNumber = Math.abs(number)

  const parts = absNumber.toFixed(2).toString().split('.')

  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  parts[1] = parts[1] || '00'
  parts[1] = parts[1].padEnd(2, '0')

  const formattedNumber = parts.join('.')

  const result = `₱${formattedNumber}`

  return result
}
