import React from "react";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import BaseListPage from "../../../base/BaseListPage";
import { findObjectUseCase } from "../../../usecases/object";
import DiscountDayWeekPresenter from "./DiscountDayWeekPresenter";
import withRouter from "../../../withRouter";
import printChart from "../../../PrintChart";
import Export from "../../../components/Export";
import DateRange from "../../../components/DateRange";
import { Progress } from "nq-component";
import DateRangeReport from "../../../components/DateRangeReport";

class DiscountDayWeekPage extends BaseListPage {
  constructor(props) {
    super(props);
    this.presenter = new DiscountDayWeekPresenter(this, findObjectUseCase());
    this.state = {
      objects: [],
      startDate: "",
      endDate: "",
      selectedStoreCode: "",
      store: [],
      storess: "",
      range: "",
    };
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.handleStoreCodeChange = this.handleStoreCodeChange.bind(this);
  }

  getCollectionName() {
    return "transactions";
  }

  setStore(store) {
    console.log("storessss", store);
    this.setState({ store });
  }

  onChangeStore(where) {
    console.log("store Onchange", where);
    this.setState({ storess: where });
    this.presenter.onChangeStore(where);
  }

  onChangeDate(where) {
    this.presenter.onChangeDate(where);
    this.setState({ range: where });
  }

  getUniqueStoreCodes() {
    const storeCodes = this.state.objects.map((obj) =>
      // Remove "ANGELSPIZZA_" from the start and return the rest
      obj.store_code.replace("ANGELSPIZZA_", "")
    );

    return [...new Set(storeCodes)]; // Remove duplicates
  }

  handleStartDateChange(event) {
    this.setState({ startDate: event.target.value });
  }

  handleEndDateChange(event) {
    this.setState({ endDate: event.target.value });
  }

  handleStoreCodeChange(event) {
    this.setState({ selectedStoreCode: event.target.value });
  }

  filterTransactions() {
    const { objects, startDate, endDate, selectedStoreCode } = this.state;

    return objects.filter((transaction) => {
      const transactionDate = new Date(transaction.createdAt);
      const start = startDate
        ? new Date(startDate)
        : new Date(-8640000000000000);
      const end = endDate ? new Date(endDate) : new Date();
      end.setHours(23, 59, 59, 999);
      const isDateInRange =
        (!startDate || transactionDate >= start) &&
        (!endDate || transactionDate <= end);
      const isStoreCodeMatch = selectedStoreCode
        ? transaction.store_code.replace("ANGELSPIZZA_", "") ===
          selectedStoreCode
        : true;

      return isDateInRange && isStoreCodeMatch;
    });
  }

  countTransactionTypesPerDayOfWeek = (transactions) => {
    const counts = {
      DineIn: new Array(7).fill(0),
      GrabFood: new Array(7).fill(0),
      TakeOut: new Array(7).fill(0),
    };

    transactions.forEach((transaction) => {
      const dayOfWeek = new Date(transaction.createdAt).getDay();
      switch (transaction.tran_type) {
        case "D": // Dine-in
          counts.DineIn[dayOfWeek]++;
          break;
        case "G": // GrabFood
          counts.GrabFood[dayOfWeek]++;
          break;
        case "T": // Take-out
          counts.TakeOut[dayOfWeek]++;
          break;
        default:
          break;
      }
    });

    return counts;
  };

  countDiscountAmountPerDayOfWeek = (transactions) => {
    const discountAmounts = new Array(7).fill(0);

    transactions.forEach((transaction) => {
      const dayOfWeek = new Date(transaction.createdAt).getDay();
      const discountAmount = Number(transaction.discount_amount) || 0; // Replace with the actual property name for the discount amount
      discountAmounts[dayOfWeek] += discountAmount;
    });

    return discountAmounts;
  };

  getChartData() {
    const filteredTransactions = this.filterTransactions();
    const discountAmountsPerDay = this.countDiscountAmountPerDayOfWeek(
      filteredTransactions,
      new Date(this.state.startDate).getMonth(),
      new Date(this.state.startDate).getFullYear()
    );

    return {
      labels: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      datasets: [
        // ... existing datasets for Dine-in, GrabFood, and Take-out
        {
          label: "Discount Amount",
          data: discountAmountsPerDay,
          borderColor: "rgb(234, 67, 53)", // A distinct color for the discount amounts
          backgroundColor: "rgba(234, 67, 53, 0.5)", // Adjust the transparency as needed
          fill: true,
        },
      ],
    };
  }

  getChartOptions() {
    return {
      chartOptions: {
        responsive: true,
        plugins: {
          legend: {
            position: "top",
          },
          title: {
            display: true,
            text: "Total Transactions vs Time of Day (Hours)",
          },
        },
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
          },
        },
      },
    };
  }

  convertToCSV(objArray) {
    const array =
      typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
    let str =
      `${Object.keys(array[0])
        .map((value) => `"${value}"`)
        .join(",")}` + "\r\n";
    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in array[i]) {
        if (line !== "") line += ",";
        line += `"${array[i][index]}"`;
      }
      str += line + "\r\n";
    }
    return str;
  }

  exportToCSV = () => {
    const { objects, selectedStoreCode, startDate, endDate } = this.state;

    // Filter data based on the selected store and date range
    const filteredTransactions = this.filterTransactions(objects);

    // Create an object to store aggregated data for each day
    const aggregatedData = {};

    // Loop through filtered transactions and aggregate data by day
    filteredTransactions.forEach((transaction) => {
      const transactionDate = new Date(transaction.createdAt)
        .toISOString()
        .split("T")[0];
      if (!aggregatedData[transactionDate]) {
        aggregatedData[transactionDate] = {
          Brand: "Angels Pizza", // Replace with your actual brand data
          Store: transaction.store_code.split("_")[1],
          Date: transactionDate,
        };
      }
      const discountAmount = Number(transaction.discount_amount) || 0;
      aggregatedData[transactionDate]["Discount Amount"] =
        (aggregatedData[transactionDate]["Discount Amount"] || 0) +
        discountAmount;
    });

    // Define CSV headers dynamically based on available dates
    const headers = ["Brand", "Store", "Date", "Discount Amount"];
    let csvContent = [headers.join(",")];

    // Loop over each aggregated day data and format the row
    Object.values(aggregatedData).forEach((rowData) => {
      const row = headers.map((header) => rowData[header] || 0);
      csvContent.push(row.join(","));
    });

    // Convert the rows to a single string
    csvContent = csvContent.join("\n");

    // Create a Blob and trigger the download
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute(
      "download",
      `DiscountDayWeek_${startDate}_${endDate}.csv`
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // In your render method, add a button to trigger this function
  // <button onClick={this.exportToCSV}>Export to CSV</button>

  render() {
    const chartData = this.getChartData();
    const chartOptions = this.getChartOptions();
    const { startDate, endDate, range } = this.state;
    // const dateRange = JSON.parse(range);
    const uniqueStoreCodes = this.getUniqueStoreCodes();
    const chartId = "chart-container19";

    return (
      <>
        <div className="d-flex justify-content-between align-items-center mb-1 mt-4">
          <h2 className="fw-bold text-capitalize">
            <span style={{ color: "black" }}>Discount</span>
            <span style={{ color: "#83c14a" }}> Value</span>
            <span style={{ color: "black" }}> vs.</span>
            <span style={{ color: "#c5da55" }}> Day of Week</span>
          </h2>
          <Export
            exportCsv={() => this.exportToCSV()}
            exportPdf={() => printChart(chartId)}
          />
        </div>

        <div className="row mb-2">
          <div className="col-sm-4" style={{ maxWidth: "max-content" }}>
            <DateRangeReport
              onChange={this.onChangeDate.bind(this)}
              field="tran_date"
            />
          </div>
          <div className="col-sm-3">
            <select
              className="form-select"
              // value={this.state.selectedStoreCode}
              onChange={(e) => this.onChangeStore(e.target.value)}
              disabled={this.state.range ? false : true}
            >
              <option value="">Select Store</option>
              <option value="AllStore">All Store</option>
              {this.state.store.map((code, index) => (
                <option key={index} value={code.name}>
                  {code.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        {this.state.objects && this.state.objects.length > 0 ? (
          <div ref={this.chartRef1} id="chart-container19">
            <div className="card">
              <Line data={chartData} options={chartOptions} />
            </div>
            <div className="print-only-content">
              <h2>Report Details</h2>
              <div className=" print-only-content">
                <div className="">
                  <h3>Brand: Angels Pizza</h3>
                  <h3>Store: {this.state.storess}</h3>
                  <h3>
                    {/* Date: {`${range.$lte.split("T")[0]} ${range.$gte.split("T")[0]}`} */}
                  </h3>
                </div>
              </div>
              <div className="" style={{ fontSize: "20px" }}>
                <table className="table text-center">
                  <thead>
                    <tr className="d-flex">
                      <th>Day</th>
                      <th className="ms-5">Discount Value</th>{" "}
                    </tr>
                  </thead>

                  <tbody>
                    {chartData.labels.map((label, index) => (
                      <tr className="d-flex" key={label}>
                        <td className="ms-5">{label} ------------ </td>
                        <td className="ms-5">
                          {chartData.datasets[0].data[index]}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : this.state.storess && this.state.range ? (
          <div className="text-center mt-5">
            <Progress />
            <h6>Processing... Please wait.</h6>
          </div>
        ) : (
          <div className="text-center align-items-center mt-4">
            <img
              src="./choosefirst.png"
              style={{ height: "200px", width: "200px" }}
            />
            <h4>To proceed, please select a date first</h4>
          </div>
        )}
      </>
    );
  }
}

export default withRouter(DiscountDayWeekPage);
