// import React from "react";
// import { Button, InputFactory, Table } from "nq-component";
// import { Progress, InfiniteScroll } from "nq-component";
// import TransactionPresenter from "./TransactionPresenter";
// import BaseListPage from "../../base/BaseListPage";
// import {
//   countObjectUseCase,
//   deleteObjectUseCase,
//   findObjectUseCase,
//   upsertUseCase,
// } from "../../usecases/object";
// import withRouter from "../../withRouter";
// import Search from "../../components/Search";
// import NavBar from "../../components/navbar";
// import { exportCSVUseCase } from "../../usecases/csv/usecases";
// import SelectSearch from "../../components/SelectSearch";

// class TransactionPage extends BaseListPage {
//   constructor(props) {
//     super(props);
//     this.presenter = new TransactionPresenter(
//       this,
//       findObjectUseCase(),
//       countObjectUseCase(),
//       deleteObjectUseCase(),
//       upsertUseCase(),
//       exportCSVUseCase()
//     );
//     this.state = {
//       objects: [],
//       selected: [],
//       count: "",
//       storeAnalytics: [],
//       selectedID: "",
//       selectedTranType: "",
//       tranDate: "",
//     };
//   }

//   async componentDidMount() {
//     this.presenter.componentDidMount();
//     const storeAnalytics = await findObjectUseCase().execute("storeAnalytics");
//     this.setState({ storeAnalytics });
//   }

//   getCollectionName() {
//     return "transactions";
//   }

//   async refetchData() {
//     const objects = await this.presenter.getObjects();
//     return objects;
//   }

//   onChangeFilter(filters) {
//     const where = {};
//     if (filters.store_code) {
//       where.store_code = { $regex: filters.store_code, $options: "i" };
//     }
//     if (filters.tran_type) {
//       where.tran_type = { $regex: filters.tran_type, $options: "i" };
//     }
//     if (filters.tran_date) {
//       where.tran_date = { $regex: filters.tran_date, $options: "i" };
//     }
//     console.warn("WHERE: ", where);
//     this.filterSubmit(where);
//   }

//   onFilterChange = (field, value) => {
//     this.setState({ [field]: value }, () => {
//       const { selectedID, selectedTranType, tranDate } = this.state;
//       console.warn("ffffffff: ", tranDate);
//       const filters = {
//         store_code: selectedID?.value,
//         tran_type: selectedTranType?.value,
//         tran_date: tranDate,
//       };
//       this.onChangeFilter(filters);
//     });
//   };

//   onClickExport() {
//     this.presenter.onClickExport();
//   }

//   render() {
//     const schema = this.getSchema(this.getCollectionName());
//     const { objects, selected, count, progress, storeAnalytics } = this.state;
//     const unique = new Set();
//     console.warn("OBJE: ", objects);
//     storeAnalytics.forEach((item) => {
//       unique.add(item);
//     });
//     const selectableItem = Array.from(unique);
//     if (!schema) return <Progress />;
//     return (
//       <>
//         <NavBar
//           action={() => {
//             return (
//               <div className="dropdown dropstart d-inline-block">
//                 <i
//                   role="button"
//                   data-bs-toggle="dropdown"
//                   className="bi bi-three-dots-vertical"
//                 ></i>
//                 <div className="dropdown-menu fs-xs">
//                   <button
//                     onClick={this.onClickExport.bind(this)}
//                     className="dropdown-item py-3"
//                   >
//                     <i className="bi bi-arrow-up-square pe-2" />
//                     Export Data
//                   </button>
//                 </div>
//               </div>
//             );
//           }}
//         />
//         <div className="overflow-auto">
//           <InfiniteScroll
//             className="h-100"
//             loadMore={this.loadMore.bind(this)}
//             hasMore={!progress && count > objects.length}
//           >
//             <div className="p-3 p-lg-4">
//               <div className="d-flex justify-content-between align-items-center">
//                 <h1 className="fw-bold text-capitalize">
//                   {schema.label || this.getCollectionName()}
//                 </h1>
//                 {selected.length > 0 ? (
//                   <div>
//                     <span className="ms-2">Selected: </span>
//                     <span className="fs-sm text-nowrap">{selected.length}</span>
//                     <span className="ms-1">of </span>
//                     <span className="fs-sm text-nowrap">{count}</span>
//                   </div>
//                 ) : (
//                   <div>
//                     <span className="ms-2">Total: </span>
//                     <span className="fs-sm text-nowrap">{objects.length}</span>
//                     <span className="ms-1">of </span>
//                     <span className="fs-sm text-nowrap">{count}</span>
//                   </div>
//                 )}
//               </div>
//               <div className="d-flex mt-3">
//                 {Object.keys(schema.filters || {}).map((field) => {
//                   let { type, ...options } = schema.filters[field];
//                   return (
//                     <InputFactory
//                       key={field}
//                       className="ms-1"
//                       type={type}
//                       field={field}
//                       where={{}}
//                       onChange={this.onChangeFilter.bind(this, type)}
//                       {...options}
//                     />
//                   );
//                 })}
//               </div>
//               <div className="row ms-1 d-flex gap-2">
//                 {["selectedID", "selectedTranType", "tranDate"].map((item) => {
//                   return (
//                     <div className="col-lg-3 p-0" key={item}>
//                       {item === "tranDate" ? (
//                         <InputFactory
//                           type="Date"
//                           value={this.state[item]}
//                           onChange={(value) =>
//                             this.onFilterChange("tranDate", value)
//                           }
//                         />
//                       ) : (
//                         <SelectSearch
//                           value={this.state[item]}
//                           onClickClear={() =>
//                             this.onFilterChange(item, { label: "", value: "" })
//                           }
//                           onChange={(value) =>
//                             item === "selectedID"
//                               ? this.onFilterChange("selectedID", value)
//                               : this.onFilterChange("selectedTranType", value)
//                           }
//                           options={
//                             item === "selectedID"
//                               ? selectableItem.map((type) => ({
//                                   label: type.name,
//                                   value: type.name,
//                                 }))
//                               : ["G", "U", "F", "T", "D"].map((type) => ({
//                                   label: type,
//                                   value: type,
//                                 }))
//                           }
//                         />
//                       )}
//                     </div>
//                   );
//                 })}
//               </div>

//               <Search
//                 schemas={this.getSchemas()}
//                 className="mt-3"
//                 onSubmit={this.searchSubmit.bind(this)}
//                 fields={schema.fields}
//               />
//               <Table
//                 fields={schema.fields}
//                 objects={objects}
//                 selectable
//                 collapsable
//                 excludeFields={Object.keys(schema.fields).reduce(
//                   (acc, key) => {
//                     const options = schema.fields[key];
//                     if (options.read === false) {
//                       acc.push(key);
//                     }
//                     switch (options._type || options.type) {
//                       case "Relation":
//                       case "Array":
//                       case "Object":
//                       case "File":
//                         acc.push(key);
//                         break;
//                       default:
//                     }
//                     return acc;
//                   },
//                   ["acl", "password"]
//                 )}
//                 selected={selected}
//                 onSelect={this.onSelect.bind(this)}
//                 onSelectAll={this.onSelectAll.bind(this)}
//                 progress={progress}
//                 onClickItem={this.onClickItem.bind(this)}
//                 className="mt-3"
//               />
//             </div>
//           </InfiniteScroll>
//         </div>
//       </>
//     );
//   }
// }

// export default withRouter(TransactionPage);

import React from "react";
import { Button, InputFactory, Table } from "nq-component";
import { Progress, InfiniteScroll } from "nq-component";
import TransactionPresenter from "./TransactionPresenter";
import BaseListPage from "../../base/BaseListPage";
import {
  countObjectUseCase,
  deleteObjectUseCase,
  findObjectUseCase,
  upsertUseCase,
} from "../../usecases/object";
import withRouter from "../../withRouter";
import Search from "../../components/Search";
import NavBar from "../../components/navbar";
import { exportCSVUseCase } from "../../usecases/csv/usecases";
import SelectSearch from "../../components/SelectSearch";
import { startOfDay, addDays, addHours, addMinutes, format } from "date-fns";
import { getCurrentUserUseCase } from "../../usecases/user";

class TransactionPage extends BaseListPage {
  constructor(props) {
    super(props);
    this.presenter = new TransactionPresenter(
      this,
      findObjectUseCase(),
      countObjectUseCase(),
      deleteObjectUseCase(),
      upsertUseCase(),
      exportCSVUseCase()
    );
    this.state = {
      objects: [],
      selected: [],
      count: "",
      storeAnalytics: [],
      selectedID: "",
      selectedTranType: "",
      tranDate: "",
    };
  }

  // async componentDidMount() {
  //   this.presenter.componentDidMount();
  //   const storeAnalytics = await findObjectUseCase().execute("storeAnalytics");
  //   const sortedStoreAnalytics = storeAnalytics.sort((a, b) =>
  //     a.name.localeCompare(b.name)
  //   ); // Assuming 'name' is the property to sort by
  //   this.setState({ storeAnalytics: sortedStoreAnalytics });
  //   // this.setState({ storeAnalytics });
  // }

  async componentDidMount() {
    this.presenter.componentDidMount();
    const user = await getCurrentUserUseCase().execute();
    const storeQuery = { include: ["all"], where: { email: user.email } };
    this.store = await findObjectUseCase().execute("users", storeQuery);
    // const store = this.store[0].storeAnalytic;
    const store = this.store[0].storeAnalytic.sort((a, b) => {
      const nameA = a.name.toUpperCase(); // ignore upper and lowercase
      const nameB = b.name.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0; // names must be equal
    });
    // this.view.setStore(store);

    // const storeAnalytics = await findObjectUseCase().execute("storeAnalytics");

    // // Remove duplicates
    // const uniqueStoreAnalytics = Array.from(
    //   new Set(storeAnalytics.map((item) => item.name))
    // ).map((name) => storeAnalytics.find((item) => item.name === name));

    // // Sort the unique items alphabetically
    // const sortedStoreAnalytics = uniqueStoreAnalytics.sort((a, b) =>
    //   a.name.localeCompare(b.name)
    // );

    // this.setState({ storeAnalytics: sortedStoreAnalytics });
    this.setState({ storeAnalytics: store });
  }

  // async componentDidMount() {
  //   this.presenter.componentDidMount();
  //   const storeAnalytics = await findObjectUseCase().execute("storeAnalytics");

  //   // Remove duplicates
  //   const uniqueStoreAnalytics = Array.from(
  //     new Set(storeAnalytics.map((item) => item.name))
  //   ).map((name) => storeAnalytics.find((item) => item.name === name));

  //   // Filter out specific items
  //   const filteredStoreAnalytics = uniqueStoreAnalytics.filter(
  //     (item) => item.name !== "FCSIWH" && item.name !== "FIGARO_SHANG"
  //   );

  //   // Sort the unique items alphabetically
  //   const sortedStoreAnalytics = filteredStoreAnalytics.sort((a, b) =>
  //     a.name.localeCompare(b.name)
  //   );

  //   this.setState({ storeAnalytics: sortedStoreAnalytics });
  // }

  getCollectionName() {
    return "transactions";
  }

  async refetchData() {
    const objects = await this.presenter.getObjects();
    return objects;
  }

  // onChangeFilter(filters) {
  //   const where = {};
  //   if (filters.store_code) {
  //     where.store_code = { $regex: filters.store_code, $options: "i" };
  //   }
  //   if (filters.tran_type) {
  //     where.tran_type = { $regex: filters.tran_type, $options: "i" };
  //   }
  //   if (filters.tran_date) {
  //     const startDate = startOfDay(filters.tran_date);
  //     const endDate = addMinutes(addHours(addDays(startDate, 1), 4), 59);
  //     where.tran_date = { $gte: addHours(startDate, 5), $lte: endDate };
  //   }
  //   console.warn("WHERE: ", where);
  //   this.filterSubmit(where);
  // }

  onChangeFilter(filters) {
    const where = {};
    if (filters.store_code) {
      where.store_code = { $regex: filters.store_code, $options: "i" };
    }
    if (filters.tran_type) {
      where.tran_type = { $regex: filters.tran_type, $options: "i" };
    }
    if (filters.tran_date) {
      const startDate = addHours(startOfDay(filters.tran_date), 5);
      const endDate = addMinutes(addHours(addDays(startDate, 1), -1), 59);
      where.tran_date = {
        $gte: format(startDate, "yyyy-MM-dd HH:mm:ss.SSS"),
        $lte: format(endDate, "yyyy-MM-dd HH:mm:ss.SSS"),
      };
    }
    console.warn("WHERE: ", where);
    this.filterSubmit(where);
  }

  onFilterChange = (field, value) => {
    this.setState({ [field]: value }, () => {
      const { selectedID, selectedTranType, tranDate } = this.state;
      const filters = {
        store_code: selectedID?.value,
        tran_type: selectedTranType?.value,
        tran_date: tranDate ? new Date(tranDate) : null,
      };
      this.onChangeFilter(filters);
    });
  };

  onClickExport() {
    this.presenter.onClickExport();
  }

  render() {
    const schema = this.getSchema(this.getCollectionName());
    const { objects, selected, count, progress, storeAnalytics } = this.state;
    const unique = new Set();
    storeAnalytics.forEach((item) => {
      unique.add(item);
    });
    const selectableItem = Array.from(unique);

    if (!schema) return <Progress />;
    return (
      <>
        <NavBar
          action={() => {
            return (
              <div className="dropdown dropstart d-inline-block">
                <i
                  role="button"
                  data-bs-toggle="dropdown"
                  className="bi bi-three-dots-vertical"
                ></i>
                <div className="dropdown-menu fs-xs">
                  <button
                    onClick={this.onClickExport.bind(this)}
                    className="dropdown-item py-3"
                  >
                    <i className="bi bi-arrow-up-square pe-2" />
                    Export Data
                  </button>
                </div>
              </div>
            );
          }}
        />
        <div className="overflow-auto">
          <InfiniteScroll
            className="h-100"
            loadMore={this.loadMore.bind(this)}
            hasMore={!progress && count > objects.length}
          >
            <div className="p-3 p-lg-4">
              <div className="d-flex justify-content-between align-items-center">
                <h1 className="fw-bold text-capitalize">
                  {schema.label || this.getCollectionName()}
                </h1>
                {selected.length > 0 ? (
                  <div>
                    <span className="ms-2">Selected: </span>
                    <span className="fs-sm text-nowrap">{selected.length}</span>
                    <span className="ms-1">of </span>
                    <span className="fs-sm text-nowrap">{count}</span>
                  </div>
                ) : (
                  <div>
                    <span className="ms-2">Total: </span>
                    <span className="fs-sm text-nowrap">{objects.length}</span>
                    <span className="ms-1">of </span>
                    <span className="fs-sm text-nowrap">{count}</span>
                  </div>
                )}
              </div>
              <div className="d-flex mt-3">
                {Object.keys(schema.filters || {}).map((field) => {
                  let { type, ...options } = schema.filters[field];
                  return (
                    <InputFactory
                      key={field}
                      className="ms-1"
                      type={type}
                      field={field}
                      where={{}}
                      onChange={this.onChangeFilter.bind(this, type)}
                      {...options}
                    />
                  );
                })}
              </div>
              <div className="row ms-1 d-flex gap-2">
                {["selectedID", "selectedTranType", "tranDate"].map((item) => {
                  return (
                    <div className="col-lg-3 p-0" key={item}>
                      {item === "tranDate" ? (
                        <InputFactory
                          type="Date"
                          value={this.state[item]}
                          onChange={(value) =>
                            this.onFilterChange("tranDate", value)
                          }
                        />
                      ) : (
                        <SelectSearch
                          value={this.state[item]}
                          onClickClear={() =>
                            this.onFilterChange(item, { label: "", value: "" })
                          }
                          onChange={(value) =>
                            item === "selectedID"
                              ? this.onFilterChange("selectedID", value)
                              : this.onFilterChange("selectedTranType", value)
                          }
                          options={
                            item === "selectedID"
                              ? selectableItem.map((type) => ({
                                  label: type.name,
                                  value: type.name,
                                }))
                              : ["G", "U", "F", "T", "D"].map((type) => ({
                                  label: type,
                                  value: type,
                                }))
                          }
                        />
                      )}
                    </div>
                  );
                })}
              </div>

              <Search
                schemas={this.getSchemas()}
                className="mt-3"
                onSubmit={this.searchSubmit.bind(this)}
                fields={schema.fields}
              />
              <Table
                fields={schema.fields}
                objects={objects}
                selectable
                collapsable
                excludeFields={Object.keys(schema.fields).reduce(
                  (acc, key) => {
                    const options = schema.fields[key];
                    if (options.read === false) {
                      acc.push(key);
                    }
                    switch (options._type || options.type) {
                      case "Relation":
                      case "Array":
                      case "Object":
                      case "File":
                        acc.push(key);
                        break;
                      default:
                    }
                    return acc;
                  },
                  ["acl", "password"]
                )}
                selected={selected}
                onSelect={this.onSelect.bind(this)}
                onSelectAll={this.onSelectAll.bind(this)}
                progress={progress}
                onClickItem={this.onClickItem.bind(this)}
                className="mt-3"
              />
            </div>
          </InfiniteScroll>
        </div>
      </>
    );
  }
}

export default withRouter(TransactionPage);
