const getCustomerCategories = (transactions) => {
  const oneYearAgo = new Date();
  oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

  const ninetyDaysAgo = new Date();
  ninetyDaysAgo.setDate(ninetyDaysAgo.getDate() - 90);

  const customerMap = new Map();

  transactions.forEach((transaction) => {
    const tranDate = new Date(transaction.tran_date);

    if (tranDate >= oneYearAgo) {
      const customer = customerMap.get(transaction.cust_phone) || {
        custName: transaction.cust_name,
        totalSpend: 0,
        purchaseCount: 0,
        lastPurchaseDate: tranDate,
      };

      customer.totalSpend += parseFloat(transaction.total);
      customer.purchaseCount += 1;

      if (tranDate > customer.lastPurchaseDate) {
        customer.lastPurchaseDate = tranDate;
      }

      customerMap.set(transaction.cust_phone, customer);
    }
  });

  const categories = {
    loyalists: [],
    potentialLoyalists: [],
    promising: [],
    hesitant: [],
    needAttention: [],
    detractors: [],
  };

  customerMap.forEach((customer, name) => {
    const recentPurchase = customer.lastPurchaseDate >= thirtyDaysAgo;
    const pastMonthPurchase =
      customer.lastPurchaseDate >= ninetyDaysAgo &&
      customer.lastPurchaseDate < thirtyDaysAgo;
    const lastPurchaseOver90Days = customer.lastPurchaseDate < ninetyDaysAgo;

    if (
      customer.totalSpend >= 5000 &&
      customer.purchaseCount >= 5 &&
      recentPurchase
    ) {
      categories.loyalists.push(name);
    } else if (
      customer.totalSpend >= 2000 &&
      customer.totalSpend < 5000 &&
      customer.purchaseCount >= 5 &&
      recentPurchase
    ) {
      categories.potentialLoyalists.push(name);
    } else if (
      customer.totalSpend >= 2000 &&
      customer.totalSpend < 5000 &&
      customer.purchaseCount >= 2 &&
      customer.purchaseCount <= 4 &&
      recentPurchase
    ) {
      categories.promising.push(name);
    } else if (
      customer.totalSpend < 2000 &&
      customer.purchaseCount >= 2 &&
      customer.purchaseCount <= 4 &&
      recentPurchase
    ) {
      categories.hesitant.push(name);
    } else if (
      customer.totalSpend >= 2000 &&
      customer.totalSpend < 5000 &&
      customer.purchaseCount >= 2 &&
      customer.purchaseCount <= 4 &&
      pastMonthPurchase
    ) {
      categories.needAttention.push(name);
    } else if (
      customer.totalSpend < 2000 &&
      customer.purchaseCount === 1 &&
      lastPurchaseOver90Days
    ) {
      categories.detractors.push(name);
    }
  });

  return categories;
};

export default getCustomerCategories;
