import React from "react";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import BaseListPage from "../../../../base/BaseListPage";
import { findObjectUseCase } from "../../../../usecases/object";
import withRouter from "../../../../withRouter";
import RevenueQuantityPresenter from "./RevenueQuantityPresenter";
import Export from "../../../../components/Export";
import DateRange from "../../../../components/DateRange";
import { Progress } from "nq-component";
import printJS from "print-js";

class RevenueQuantityPage extends BaseListPage {
  constructor(props) {
    super(props);
    this.chartRef = React.createRef();
    this.presenter = new RevenueQuantityPresenter(this, findObjectUseCase());
    this.state = {
      objects: [],
      store: [],
      storess: "",
      range: "",
    };
  }

  getCollectionName() {
    return "transactions";
  }

  setStore(store) {
    this.setState({ store });
  }
  onChangeDate(where) {
    this.presenter.onChangeDate(where);
    this.setState({ range: where });
  }

  onChangeStore(where) {
    this.setState({ storess: where });
    this.presenter.onChangeStore(where);
  }

  getMonthYearString = (date) => {
    const year = date.getFullYear();
    const month = date.toLocaleString("default", { month: "long" });
    return `${month} ${year}`;
  };

  chartData = () => {
    const transactions = this.state.objects;

    // Initialize structures to hold your calculations
    const productRevenuesByMonth = {};
    const totalQuantitiesByMonth = {}; // New structure to hold total quantities
    const salesByMonth = {};

    transactions.filter(obj => obj.status === "C").forEach((transaction) => {
      const date = new Date(transaction.createdAt);

      // Get current month-year string
      const monthYear = this.getMonthYearString(date);

      // Calculate and get previous month-year string
      const prevDate = new Date(date.setMonth(date.getMonth() - 1));
      const previousMonthYear = this.getMonthYearString(prevDate);

      // Initialize the current month-year if not present
      if (!productRevenuesByMonth[monthYear]) {
        productRevenuesByMonth[monthYear] = 0;
      }
      if (!totalQuantitiesByMonth[monthYear]) {
        totalQuantitiesByMonth[monthYear] = 0;
      }
      if (!salesByMonth[monthYear]) {
        salesByMonth[monthYear] = [];
      }

      // Initialize the previous month-year if not present
      if (!productRevenuesByMonth[previousMonthYear]) {
        productRevenuesByMonth[previousMonthYear] = 0;
      }
      if (!totalQuantitiesByMonth[previousMonthYear]) {
        totalQuantitiesByMonth[previousMonthYear] = 0;
      }
      if (!salesByMonth[previousMonthYear]) {
        salesByMonth[previousMonthYear] = [];
      }

      salesByMonth[monthYear].push(transaction);

      // Calculate units per transaction and product revenue
      let transactionRevenue = 0;
      transaction.items.forEach((item) => {
        const unitsPerTransaction = parseInt(item.quantity);
        totalQuantitiesByMonth[monthYear] += unitsPerTransaction; // Add to total quantity for the month

        const unitPrice = item.customProperties.find(
          (prop) => prop.codeName === "UNIT_PRICE"
        );
        if (unitPrice) {
          transactionRevenue +=
            unitsPerTransaction * parseFloat(unitPrice.value);
        }
      });

      // Accumulate the revenue for the month-year
      productRevenuesByMonth[monthYear] += transactionRevenue;
    });

    // Prepare the data for the chart
    const monthlyProductRevenues = Object.keys(productRevenuesByMonth).map(
      (monthYear) => productRevenuesByMonth[monthYear]
    );
    const monthlyTotalQuantities = Object.keys(totalQuantitiesByMonth).map(
      (monthYear) => totalQuantitiesByMonth[monthYear]
    );

    const sortedMonths = Object.keys(salesByMonth).sort((a, b) => {
      const aDate = new Date(a + " 1");
      const bDate = new Date(b + " 1");
      return aDate - bDate;
    });

    return {
      labels: sortedMonths,
      datasets: [
        {
          type: "line",
          label: "Total Quantities Sold",
          data: sortedMonths.map(
            (monthYear) => totalQuantitiesByMonth[monthYear]
          ),
          borderColor: "#FF6384",
          borderWidth: 2,
          fill: false,
          yAxisID: "y-axis-2",
        },
        {
          type: "bar",
          label: "Product Revenue",
          data: sortedMonths.map(
            (monthYear) => productRevenuesByMonth[monthYear]
          ),
          backgroundColor: "#36A2EB",
          yAxisID: "y-axis-1",
        },
      ],
    };
  };

  chartOptions = () => {
    return {
      scales: {
        "y-axis-1": {
          type: "linear",
          display: true,
          position: "left",
        },
        "y-axis-2": {
          type: "linear",
          display: true,
          position: "right",
          grid: {
            drawOnChartArea: false,
          },
        },
      },
      plugins: {
        tooltip: {
          callbacks: {
            label: function (context) {
              let label = context.dataset.label || "";
              if (label) {
                label += ": ";
              }
              if (context.parsed.y !== null) {
                label += `₱${context.parsed.y.toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                })}`;
              }
              return label;
            },
          },
        },
      },
    };
  };


  exportToCSV = () => {
    const {
      storess,
      range
    } = this.state;

    const transactions = this.state.objects;

    // Initialize structures to hold your calculations
    const productRevenuesByMonth = {};
    const totalQuantitiesByMonth = {}; // New structure to hold total quantities
    const salesByMonth = {};

    transactions.filter(obj => obj.status === "C").forEach((transaction) => {
      const date = new Date(transaction.createdAt);

      // Get current month-year string
      const monthYear = this.getMonthYearString(date);

      // Calculate and get previous month-year string
      const prevDate = new Date(date.setMonth(date.getMonth() - 1));
      const previousMonthYear = this.getMonthYearString(prevDate);

      // Initialize the current month-year if not present
      if (!productRevenuesByMonth[monthYear]) {
        productRevenuesByMonth[monthYear] = 0;
      }
      if (!totalQuantitiesByMonth[monthYear]) {
        totalQuantitiesByMonth[monthYear] = 0;
      }
      if (!salesByMonth[monthYear]) {
        salesByMonth[monthYear] = [];
      }

      // Initialize the previous month-year if not present
      if (!productRevenuesByMonth[previousMonthYear]) {
        productRevenuesByMonth[previousMonthYear] = 0;
      }
      if (!totalQuantitiesByMonth[previousMonthYear]) {
        totalQuantitiesByMonth[previousMonthYear] = 0;
      }
      if (!salesByMonth[previousMonthYear]) {
        salesByMonth[previousMonthYear] = [];
      }

      salesByMonth[monthYear].push(transaction);

      // Calculate units per transaction and product revenue
      let transactionRevenue = 0;
      transaction.items.forEach((item) => {
        const unitsPerTransaction = parseInt(item.quantity);
        totalQuantitiesByMonth[monthYear] += unitsPerTransaction; // Add to total quantity for the month

        const unitPrice = item.customProperties.find(
          (prop) => prop.codeName === "UNIT_PRICE"
        );
        if (unitPrice) {
          transactionRevenue +=
            unitsPerTransaction * parseFloat(unitPrice.value);
        }
      });

      // Accumulate the revenue for the month-year
      productRevenuesByMonth[monthYear] += transactionRevenue;
    });

    // Prepare the data for the chart
    const monthlyProductRevenues = Object.keys(productRevenuesByMonth).map(
      (monthYear) => productRevenuesByMonth[monthYear]
    );
    const monthlyTotalQuantities = Object.keys(totalQuantitiesByMonth).map(
      (monthYear) => totalQuantitiesByMonth[monthYear]
    );

    const sortedMonths = Object.keys(salesByMonth).sort((a, b) => {
      const aDate = new Date(a + " 1");
      const bDate = new Date(b + " 1");
      return aDate - bDate;
    });

    const csvData = sortedMonths.map((monthYear, index) => {
      return {
        Store: storess,
        Date: `${range.createdAt["$gte"].split("T")[0]} - ${range.createdAt["$lte"].split("T")[0]}`,
        Quantity: totalQuantitiesByMonth[monthYear],
        Revenue: this.formatSales(productRevenuesByMonth[monthYear])
      };
    });

    const csvString = this.convertToCSV(csvData);
    const a = document.createElement("a");
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = `${storess}-${range.createdAt["$gte"].split("T")[0]} - ${range.createdAt["$lte"].split("T")[0]}-revenue-vs-quantity.csv`;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  formatSales(num) {
    let numStr = num.toFixed(2);
    numStr = numStr.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return numStr;
  }

  convertToCSV(objArray) {
    const array =
      typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
    let str =
      `${Object.keys(array[0])
        .map((value) => `"${value}"`)
        .join(",")}` + "\r\n";
    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in array[i]) {
        if (line !== "") line += ",";
        line += `"${array[i][index]}"`;
      }
      str += line + "\r\n";
    }
    return str;
  }

  printChart = () => {
    setTimeout(() => {
      printJS({
        printable: "chart-container",
        type: "html",
        header: `<div style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem;">
                     <img src="/figaro.png" alt="Figaro" style="height: 50px;"/>
                     <h4 style="margin: 0 auto;">FIGARO COFFEE GROUP</h4>
                     <img src="/angels.png" alt="Angel's Pizza" style="height: 50px;"/>
                   </div>`,
        css: "./index.css",
        scanStyles: false,
        style: ".print-only-content { display: block; }",
      });
    }, 500);
  };

  render() {
    return (
      <>
        <div className="d-flex justify-content-between align-items-center mb-1">
          <h2 className="fw-bold text-capitalize">
            <span style={{ color: "#83c14a" }}>Product Revenue</span>
            <span style={{ color: "black" }}> vs.</span>
            <span style={{ color: "#c5da55" }}> Quantity</span>
          </h2>
          <Export
            exportCsv={() => this.exportToCSV()}
            exportPdf={() => this.printChart()}
          />
        </div>
        <div className="row">
          <div className="col-sm-4 mb-3" style={{ maxWidth: "max-content" }}>
            <DateRange
              onChange={this.onChangeDate.bind(this)}
              field="createdAt"
            />
          </div>
          <div className="col-sm-3 mb-3">
            <select
              className="form-select"
              onChange={(e) => this.onChangeStore(e.target.value)}
              disabled={this.state.range ? false : true}
            >
              <option value="">Select Store</option>
              <option value="AllStore">All Store</option>
              {this.state.store.map((code, index) => (
                <option key={index} value={code.name}>
                  {code.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        {this.state.objects && this.state.objects.length > 0 ? (
          <div ref={this.chartRef} id="chart-container">
            <div className="card">
              <Bar data={this.chartData()} options={this.chartOptions()} />
            </div>
          </div>
        ) : this.state.storess && this.state.range ? (
          <div className="text-center mt-5">
            <Progress />
            <h6>Processing... Please wait.</h6>
          </div>
        ) : (
          <div className="text-center align-items-center mt-4">
            <img
              src="./choosefirst.png"
              style={{ height: "200px", width: "200px" }}
            />
            <h4>To proceed, please select a date first</h4>
          </div>
        )}
      </>
    );
  }
}

export default withRouter(RevenueQuantityPage);
