import React from "react";
import { Bar } from "react-chartjs-2";
import Chart from "chart.js/auto";
import BaseListPage from "../../../../base/BaseListPage";
import { findObjectUseCase } from "../../../../usecases/object";
import Export from "../../../../components/Export/index.js";
import printChart from "../../../../PrintChart.js";
import { Progress } from "nq-component";
import TotalSalesWeekDayPresenter from "./TotalSalesWeekDayPresenter.js";
import DateRange from "../../../../components/DateRange/index.js";
import DateRangeReport from "../../../../components/DateRangeReport/index.js";

const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

class TotalSalesWeekDayPage extends BaseListPage {
  constructor(props) {
    super(props);
    this.presenter = new TotalSalesWeekDayPresenter(this, findObjectUseCase());

    this.state = {
      objects: [],
      selectedDate: new Date().toISOString().split("T")[0],
      selectedStoreCode: "",
      totalSalesPerHour: [],
      storess: "",
      store: [],
      range: "",
    };

    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleStoreCodeChange = this.handleStoreCodeChange.bind(this);
  }
  setStore(store) {
    this.setState({ store });
  }

  onChangeStore(where) {
    this.setState({ storess: where });
    this.presenter.onChangeStore(where);
  }

  onChangeDate(where) {
    this.presenter.onChangeDate(where);
    this.setState({ range: where });
  }

  getUniqueStoreCodes() {
    const storeCodes = this.state.objects.map((obj) =>
      // Remove "ANGELSPIZZA_" from the start and return the rest
      obj.store_code.replace("ANGELSPIZZA_", "")
    );

    return [...new Set(storeCodes)]; // Remove duplicates
  }

  filterTransactions(transactions) {
    const selectedDate = new Date(this.state.selectedDate);
    selectedDate.setHours(0, 0, 0, 0);

    return transactions?.filter((transaction) => {
      const transactionDate = new Date(transaction.tran_date);
      transactionDate.setHours(0, 0, 0, 0);

      const selectedDateMatch =
        transactionDate.getTime() === selectedDate.getTime();
      const storeCodeMatch = this.state.selectedStoreCode
        ? transaction.store_code.replace("ANGELSPIZZA_", "") ===
          this.state.selectedStoreCode
        : true;

      return selectedDateMatch && storeCodeMatch;
    });
  }

  handleDateChange(event) {
    this.setState({ selectedDate: event.target.value });
  }

  handleStoreCodeChange(event) {
    this.setState({ selectedStoreCode: event.target.value });
  }

  countDaysInMonth(month, year) {
    const dayCounts = {
      Sunday: 0,
      Monday: 0,
      Tuesday: 0,
      Wednesday: 0,
      Thursday: 0,
      Friday: 0,
      Saturday: 0,
    };
    // Get the number of days in the month
    const daysInMonth = new Date(year, month, 0).getDate();

    // Loop through all days in the month
    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(year, month - 1, day); // month - 1 because JavaScript months are 0-indexed
      const weekday = daysOfWeek[date.getDay()];
      dayCounts[weekday]++;
    }

    return dayCounts;
  }

  // calculateAverageSales(transactions, month, year) {
  //   const daysOfWeek = [
  //     "Sunday",
  //     "Monday",
  //     "Tuesday",
  //     "Wednesday",
  //     "Thursday",
  //     "Friday",
  //     "Saturday",
  //   ];
  //   const totalSales = Object.fromEntries(
  //     daysOfWeek.map((day) => [day, { morning: 0, afternoon: 0 }])
  //   );
  //   const dayCountsInMonth = this.countDaysInMonth(month, year);

  //   transactions
  //     ?.filter((status) => status.status === "C")
  //     .forEach((transaction) => {
  //       const date = new Date(transaction.tran_date);
  //       const weekday = daysOfWeek[date.getDay()];
  //       const grossSales = parseFloat(transaction.total);
  //       // +
  //       // parseFloat(transaction.discount_amount) +
  //       // parseFloat(transaction.total_tax);

  //       const sales = grossSales - parseFloat(transaction.total_tax);

  //       const hour = date.getHours();
  //       if (hour >= 0 && hour < 5) {
  //         totalSales[weekday].morning += sales;
  //       } else {
  //         totalSales[weekday].afternoon += sales;
  //       }
  //     });

  //   return { averageSales: totalSales };
  // }

  calculateAverageSales(transactions, month, year) {
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const totalSales = Object.fromEntries(
      daysOfWeek.map((day) => [day, { morning: 0, afternoon: 0 }])
    );
    const dayCountsInMonth = this.countDaysInMonth(month, year);

    transactions
      ?.filter((status) => status.status === "C")
      .forEach((transaction) => {
        const date = new Date(transaction.tran_date);
        const weekday = daysOfWeek[date.getDay()];
        const grossSales = parseFloat(transaction.total);
        const sales = grossSales - parseFloat(transaction.total_tax);

        const hour = date.getHours();
        if (hour >= 0 && hour < 5) {
          totalSales[weekday].morning += sales;
        } else {
          totalSales[weekday].afternoon += sales;
        }
      });

    return { averageSales: totalSales };
  }

  getChartData() {
    const month = new Date().getMonth(); // Example: get current month
    const year = new Date().getFullYear();

    const filteredTransactions = this.filterTransactions(this.state.objects);
    const { averageSales } = this.calculateAverageSales(
      this.state.objects,
      month,
      year
    );

    const labels = daysOfWeek;

    // Define data for each segment of the day
    const morningSales = daysOfWeek.map(
      (day) => averageSales[day]?.morning || 0
    );
    const afternoonSales = daysOfWeek.map(
      (day) => averageSales[day]?.afternoon || 0
    );

    return {
      labels: labels,
      datasets: [
        {
          label: "Morning Sales (12:01 AM - 4:59 AM)",
          data: morningSales,
          backgroundColor: "#FF6384",
          borderColor: "#FF6384",
          borderWidth: 1,
        },
        {
          label: "Afternoon Sales (5:00 AM - 11:59 PM)",
          data: afternoonSales,
          backgroundColor: "#006096",
          borderColor: "#006096",
          borderWidth: 1,
        },
      ],
    };
  }

  getChartOptions() {
    return {
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    };
  }

  setObjects(objects) {
    this.setState({ objects });
  }

  getCollectionName() {
    return "transactions";
  }

  // convertToCSV(objArray) {
  //   const array =
  //     typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
  //   let str =
  //     `${Object.keys(array[0])
  //       .map((value) => `"${value}"`)
  //       .join(",")}` + "\r\n";
  //   for (let i = 0; i < array.length; i++) {
  //     let line = "";
  //     for (let index in array[i]) {
  //       if (line !== "") line += ",";
  //       line += `"${array[i][index]}"`;
  //     }
  //     str += line + "\r\n";
  //   }
  //   return str;
  // }
  convertToCSV(objArray) {
    const array =
      typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
    let str =
      `${Object.keys(array[0])
        .map((value) => `"${value}"`)
        .join(",")}` + "\r\n";
    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in array[i]) {
        if (line !== "") line += ",";
        line += `"${array[i][index]}"`;
      }
      str += line + "\r\n";
    }
    return str;
  }

  // exportToCSV = () => {
  //   const { storess } = this.state;
  //   const { averageSales } = this.calculateAverageSales(this.state.objects);
  //   const csvData = Object.entries(averageSales).map((sales, index) => {
  //     const hour = (5 + index) % 24; // Adjust according to your chart's starting hour
  //     return {
  //       brand: "Angels Pizza",
  //       store: storess || "All Stores",
  //       date: `${this.state.range.tran_date["$lte"].split("T")[0]} ${
  //         this.state.range.tran_date["$gte"].split("T")[0]
  //       }`,
  //       time: `${hour}:00`,
  //       averageSales: `${sales[0]} - ${sales[1] ? sales[1]?.toFixed(2) : "0"}`,
  //     };
  //   });

  //   const csvString = this.convertToCSV(csvData);
  //   const a = document.createElement("a");
  //   const blob = new Blob([csvString], { type: "text/csv" });
  //   const url = window.URL.createObjectURL(blob);

  //   a.href = url;
  //   a.download = `${"Angels Pizza"}-average-sales-time.csv`;
  //   a.click();
  //   window.URL.revokeObjectURL(url);
  // };

  exportToCSV = () => {
    const { storess } = this.state;
    const { averageSales } = this.calculateAverageSales(this.state.objects);
    const csvData = Object.entries(averageSales).flatMap(([day, sales]) => {
      const formattedSales = [
        { period: "Morning", amount: sales.morning },
        { period: "Afternoon", amount: sales.afternoon },
      ];
      return formattedSales.map((sale, index) => ({
        brand: "Angels Pizza",
        store: storess || "All Stores",
        date: `${this.state.range.tran_date["$lte"].split("T")[0]} ${
          this.state.range.tran_date["$gte"].split("T")[0]
        }`,
        time: sale.period,
        averageSales: `${day} - ${sale.amount.toFixed(2)}`,
      }));
    });

    const csvString = this.convertToCSV(csvData);
    const a = document.createElement("a");
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = `${"Angels Pizza"}-Total-Sales-Week.csv`;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  render() {
    const chartData = this.getChartData();
    const chartOptions = this.getChartOptions();
    const uniqueStoreCodes = this.getUniqueStoreCodes();
    const printId = "chart-container3";
    const formatSales = (sales) => {
      return sales?.toLocaleString("en-US", {
        style: "currency",
        currency: "PHP",
      });
    };

    return (
      <>
        <div className="d-flex justify-content-between align-items-center mb-1">
          <h2 className="fw-bold text-capitalize mt-4">
            <span style={{ color: "darkcolor" }}>Total</span>
            <span style={{ color: "#83c14a" }}> Sales</span>
            <span style={{ color: "darkcolor" }}> vs. </span>
            <span style={{ color: "#83c14a" }}>Day Of Week</span>
          </h2>
          <Export
            exportCsv={() => this.exportToCSV()}
            exportPdf={() => printChart(printId)}
          />
        </div>
        <div className="row mb-2">
          <div className="col-sm-4" style={{ maxWidth: "max-content" }}>
            <DateRangeReport
              onChange={this.onChangeDate.bind(this)}
              field="tran_date"
            />
          </div>

          <div className="col-sm-3">
            <select
              className="form-select"
              onChange={(e) => this.onChangeStore(e.target.value)}
              disabled={this.state.range ? false : true}
            >
              <option value="">Select Store</option>
              <option value="AllStore">All Store</option>
              {this.state.store.map((code, index) => (
                <option key={index} value={code.name}>
                  {code.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        {this.state.objects && this.state.objects.length > 0 ? (
          <div ref={this.chartRef1} id="chart-container3">
            <div className="card">
              <Bar data={chartData} options={chartOptions} />
            </div>
            <div className="print-only-content">
              <h2>Report Details</h2>
              <div className="print-only-content">
                <div className="">
                  <h3>Brand: Angels Pizza</h3>
                  <h3>Store: {this.state.storess}</h3>
                  <h3>Date: {this.state.selectedDate}</h3>
                </div>
              </div>
              <div className="" style={{ fontSize: "20px" }}>
                <table className="table text-center">
                  <thead>
                    <tr className="d-flex">
                      <th>Day</th>
                      <th className="ms-5">Morning Sales</th>
                      <th className="ms-5">Afternoon Sales</th>
                    </tr>
                  </thead>
                  <tbody>
                    {chartData.labels.map((label, index) => (
                      <tr className="d-flex" key={label}>
                        <td>{label}</td>
                        <td>
                          {formatSales(chartData.datasets[0].data[index])}
                        </td>
                        <td>
                          {formatSales(chartData.datasets[1].data[index])}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : this.state.storess && this.state.range ? (
          <div className="text-center mt-5">
            <Progress />
            <h6>Processing... Please wait.</h6>
          </div>
        ) : (
          <div className="text-center align-items-center mt-4">
            <img
              src="./choosefirst.png"
              style={{ height: "200px", width: "200px" }}
            />
            <h4>To proceed, please select a date first</h4>
          </div>
        )}
      </>
    );
  }
}

export default TotalSalesWeekDayPage;
