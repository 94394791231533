// import React, { Component } from "react";
// import { Bar } from "react-chartjs-2";
// import Chart from "chart.js/auto";
// import BaseListPage from "../../../../base/BaseListPage";
// import { findObjectUseCase } from "../../../../usecases/object";
// import TotalSalesDayPresenter from "./TotalSalesDayPresenter.js";
// import Export from "../../../../components/Export/index.js";
// import printChart from "../../../../PrintChart.js";
// import { Progress } from "nq-component";

// class TotalSalesDayPage extends BaseListPage {
//   constructor(props) {
//     super(props);
//     this.presenter = new TotalSalesDayPresenter(this, findObjectUseCase());

//     this.state = {
//       objects: [],
//       selectedDate: new Date().toISOString().split("T")[0],
//       selectedStoreCode: "",
//       totalSalesPerHour: [],
//       storess: "",
//       store: [],
//     };

//     this.handleDateChange = this.handleDateChange.bind(this);
//     this.handleStoreCodeChange = this.handleStoreCodeChange.bind(this);
//   }
//   setStore(store) {
//     console.log("storessss", store);
//     this.setState({ store });
//   }

//   onChangeStore(where) {
//     console.log("store Onchange", where);
//     this.setState({ storess: where });
//     this.presenter.onChangeStore(where);
//   }

//   // getUniqueStoreCodes() {
//   //   const storeCodes = this.state.objects.map(
//   //     (obj) => obj.store_code.split("_")[1]
//   //   );
//   //   return [...new Set(storeCodes)];
//   // }

//   getUniqueStoreCodes() {
//     const storeCodes = this.state.objects.map((obj) =>
//       // Remove "ANGELSPIZZA_" from the start and return the rest
//       obj.store_code.replace("ANGELSPIZZA_", "")
//     );

//     return [...new Set(storeCodes)]; // Remove duplicates
//   }

//   filterTransactions(transactions) {
//     const selectedDate = new Date(this.state.selectedDate);
//     selectedDate.setHours(0, 0, 0, 0);

//     return transactions
//       .filter((status) => status.status === "C")
//       .filter((transaction) => {
//         const transactionDate = new Date(transaction.createdAt);
//         transactionDate.setHours(0, 0, 0, 0);

//         const selectedDateMatch =
//           transactionDate.getTime() === selectedDate.getTime();
//         const storeCodeMatch = this.state.selectedStoreCode
//           ? transaction.store_code.replace("ANGELSPIZZA_", "") ===
//             this.state.selectedStoreCode
//           : true;

//         return selectedDateMatch && storeCodeMatch;
//       });
//   }

//   handleDateChange(event) {
//     this.setState({ selectedDate: event.target.value });
//   }

//   handleStoreCodeChange(event) {
//     this.setState({ selectedStoreCode: event.target.value });
//   }

//   calculateAverageSales(transactions) {
//     const averageSales = new Array(24).fill(0);
//     const hourCounts = new Array(24).fill(0);

//     transactions.forEach((transaction) => {
//       const date = new Date(transaction.tran_date);
//       const hour = date.getHours();

//       const index = hour < 5 ? hour + 19 : hour - 5;
//       console.log("index", index);

//       const grossSales =
//         parseFloat(transaction.total) +
//         parseFloat(transaction.discount_amount) +
//         parseFloat(transaction.total_tax);
//       const sales = grossSales - parseFloat(transaction.discount_amount);

//       averageSales[index] += sales;
//       hourCounts[index]++;
//     });

//     return { averageSales };
//   }

//   getChartData() {
//     const filteredTransactions = this.filterTransactions(this.state.objects);
//     const { averageSales } = this.calculateAverageSales(filteredTransactions);
//     if (
//       JSON.stringify(this.state.totalSalesPerHour) !==
//       JSON.stringify(averageSales)
//     ) {
//       this.setState({ totalSalesPerHour: averageSales });
//     }
//     const labels = Array.from({ length: 24 }, (_, i) => {
//       const hour = (5 + i) % 24;
//       return `${hour}:00`;
//     });
//     return {
//       labels: labels, // 0 to 23, labeled as hours
//       datasets: [
//         {
//           label: "Total Sales",
//           data: averageSales,
//           backgroundColor: "#006096",
//           borderColor: "#006096",
//           borderWidth: 1,
//         },
//       ],
//     };
//   }

//   getChartOptions() {
//     return {
//       scales: {
//         y: {
//           beginAtZero: true,
//         },
//       },
//     };
//   }

//   setObjects(objects) {
//     this.setState({ objects });
//   }

//   getCollectionName() {
//     return "transactions";
//   }

//   convertToCSV(objArray) {
//     const array =
//       typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
//     let str =
//       `${Object.keys(array[0])
//         .map((value) => `"${value}"`)
//         .join(",")}` + "\r\n";
//     for (let i = 0; i < array.length; i++) {
//       let line = "";
//       for (let index in array[i]) {
//         if (line !== "") line += ",";
//         line += `"${array[i][index]}"`;
//       }
//       str += line + "\r\n";
//     }
//     return str;
//   }

//   exportToCSV = () => {
//     const {
//       selectedBrand,
//       selectedStoreCode,
//       selectedDate,
//       totalSalesPerHour,
//       storess,
//     } = this.state;

//     const csvData = totalSalesPerHour.map((sales, index) => {
//       const hour = (5 + index) % 24; // Adjust according to your chart's starting hour
//       return {
//         brand: "Angels Pizza",
//         store: storess || "All Stores",
//         date: selectedDate,
//         time: `${hour}:00`,
//         averageSales: sales.toFixed(2),
//       };
//     });

//     const csvString = this.convertToCSV(csvData);
//     const a = document.createElement("a");
//     const blob = new Blob([csvString], { type: "text/csv" });
//     const url = window.URL.createObjectURL(blob);

//     a.href = url;
//     a.download = `total-sales-time.csv`;
//     a.click();
//     window.URL.revokeObjectURL(url);
//   };

//   render() {
//     const chartData = this.getChartData();
//     const chartOptions = this.getChartOptions();
//     // const uniqueStoreCodes = this.getUniqueStoreCodes();
//     const printId = "chart-container3";
//     const formatSales = (sales) => {
//       return sales.toLocaleString("en-US", {
//         style: "currency",
//         currency: "PHP",
//       });
//     };

//     return (
//       <>
//         <div className="d-flex justify-content-between align-items-center mb-1">
//           <h2 className="fw-bold text-capitalize mt-4">
//             <span style={{ color: "darkcolor" }}>Total</span>
//             <span style={{ color: "#83c14a" }}> Sales</span>
//             <span style={{ color: "darkcolor" }}> vs. </span>
//             <span style={{ color: "#83c14a" }}>Hour of Today</span>
//           </h2>
//           <Export
//             exportCsv={() => this.exportToCSV()}
//             exportPdf={() => printChart(printId)}
//           />
//         </div>
//         <div className="row mb-2">
//           <div className="col-sm-2">
//             <input
//               className="form-control"
//               type="date"
//               value={this.state.selectedDate}
//               onChange={this.handleDateChange}
//             />
//           </div>
//           <div className="col-sm-3">
//             <select
//               className="form-select"
//               // value={this.state.selectedStoreCode}
//               onChange={(e) => this.onChangeStore(e.target.value)}
//             >
//               <option value="">Select Store</option>
//               <option value="AllStore">All Store</option>
//               {this.state.store.map((code, index) => (
//                 <option key={index} value={code.name}>
//                   {code.name}
//                 </option>
//               ))}
//             </select>
//           </div>
//         </div>
//         {this.state.objects && this.state.objects.length > 0 ? (
//           <div ref={this.chartRef1} id="chart-container3">
//             <div className="card">
//               <Bar data={chartData} options={chartOptions} />
//             </div>
//             <div className="print-only-content">
//               <h2>Report Details</h2>
//               <div className=" print-only-content">
//                 <div className="">
//                   <h3>Brand: Angels Pizza</h3>
//                   <h3>Store: {this.state.storess}</h3>
//                   <h3>Date: {this.state.selectedDate}</h3>
//                 </div>
//               </div>
//               <div className="" style={{ fontSize: "20px" }}>
//                 <table className="table text-center">
//                   <thead>
//                     <tr className="d-flex">
//                       <th>Month</th>
//                       <th className="ms-5">Sales</th>{" "}
//                     </tr>
//                   </thead>

//                   <tbody>
//                     {chartData.labels.map((label, index) => (
//                       <tr className="d-flex" key={label}>
//                         <td>{label}</td>
//                         <td>
//                           {formatSales(chartData.datasets[0].data[index])}
//                         </td>
//                       </tr>
//                     ))}
//                   </tbody>
//                 </table>
//               </div>
//             </div>
//           </div>
//         ) : this.state.storess ? (
//           <div className="text-center mt-5">
//             <Progress />
//             <h6>Processing... Please wait.</h6>
//           </div>
//         ) : (
//           <div className="text-center align-items-center mt-4">
//             <img
//               src="./choosefirst.png"
//               style={{ height: "200px", width: "200px" }}
//             />
//             <h4>To proceed, please select a store first</h4>
//           </div>
//         )}
//       </>
//     );
//   }
// }

// export default TotalSalesDayPage;
import React, { Component } from "react";
import { Bar } from "react-chartjs-2";
import Chart from "chart.js/auto";
import BaseListPage from "../../../../base/BaseListPage";
import { findObjectUseCase } from "../../../../usecases/object";
import TotalSalesDayPresenter from "./TotalSalesDayPresenter.js";
import Export from "../../../../components/Export/index.js";
import printChart from "../../../../PrintChart.js";
import { Progress } from "nq-component";

class TotalSalesDayPage extends BaseListPage {
  constructor(props) {
    super(props);
    this.presenter = new TotalSalesDayPresenter(this, findObjectUseCase());

    this.state = {
      objects: [],
      selectedDate: new Date().toISOString().split("T")[0],
      selectedStoreCode: "",
      totalSalesPerHour: [],
      totalTransactionsPerHour: [],
      storess: "",
      store: [],
    };

    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleStoreCodeChange = this.handleStoreCodeChange.bind(this);
  }

  setStore(store) {
    console.log("storessss", store);
    this.setState({ store });
  }

  onChangeStore(where) {
    console.log("store Onchange", where);
    this.setState({ storess: where });
    this.presenter.onChangeStore(where);
  }

  getUniqueStoreCodes() {
    const storeCodes = this.state.objects.map((obj) =>
      obj.store_code.replace("ANGELSPIZZA_", "")
    );
    return [...new Set(storeCodes)];
  }

  filterTransactions(transactions) {
    const selectedDate = new Date(this.state.selectedDate);
    selectedDate.setHours(5, 0, 0, 0);

    const endDate = new Date(selectedDate);
    endDate.setDate(endDate.getDate() + 1);
    endDate.setHours(4, 59, 59, 999);

    return transactions
      .filter((status) => status.status === "C")
      .filter((transaction) => {
        const transactionDate = new Date(transaction.tran_date);

        const selectedDateMatch =
          transactionDate >= selectedDate && transactionDate <= endDate;
        const storeCodeMatch = this.state.selectedStoreCode
          ? transaction.store_code.replace("ANGELSPIZZA_", "") ===
            this.state.selectedStoreCode
          : true;

        return selectedDateMatch && storeCodeMatch;
      });
  }

  handleDateChange(event) {
    this.setState({ selectedDate: event.target.value });
  }

  handleStoreCodeChange(event) {
    this.setState({ selectedStoreCode: event.target.value });
  }

  calculateAverageSales(transactions) {
    const averageSales = new Array(24).fill(0);
    const hourCounts = new Array(24).fill(0);
    const totalTransactionsPerHour = new Array(24).fill(0);

    transactions.forEach((transaction) => {
      const date = new Date(transaction.tran_date);
      const hour = date.getHours();

      const index = (hour < 5 ? hour + 24 : hour) - 5;
      console.log("index", index);

      const grossSales = parseFloat(transaction.total);

      const sales = grossSales - parseFloat(transaction.total_tax);

      averageSales[index] += sales;
      hourCounts[index]++;
      totalTransactionsPerHour[index]++;
    });

    return { averageSales, totalTransactionsPerHour };
  }

  getChartData() {
    const filteredTransactions = this.filterTransactions(this.state.objects);
    console.warn("TOTAL: ", filteredTransactions);
    const fill = filteredTransactions.filter((item) => item.tran_date);
    console.warn("trans_date: ", fill);
    const { averageSales, totalTransactionsPerHour } =
      this.calculateAverageSales(filteredTransactions);
    if (
      JSON.stringify(this.state.totalSalesPerHour) !==
      JSON.stringify(averageSales)
    ) {
      this.setState({ totalSalesPerHour: averageSales });
      this.setState({ totalTransactionsPerHour });
    }
    const labels = Array.from({ length: 24 }, (_, i) => {
      const hour = (5 + i) % 24;
      return `${hour}:00`;
    });
    return {
      labels: labels, // 0 to 23, labeled as hours
      datasets: [
        {
          label: "Total Sales",
          data: averageSales,
          backgroundColor: "#006096",
          borderColor: "#006096",
          borderWidth: 1,
        },
      ],
    };
  }

  getChartOptions() {
    return {
      scales: {
        y: {
          beginAtZero: true,
        },
      },
      plugins: {
        tooltip: {
          callbacks: {
            label: (context) => {
              const hour = context.dataIndex;
              const totalTransactions =
                this.state.totalTransactionsPerHour[hour];
              const sales = context.raw.toLocaleString("en-US", {
                style: "currency",
                currency: "PHP",
              });
              return `Total Sales: ${sales}\nTotal Transactions: ${totalTransactions}`;
            },
          },
        },
      },
    };
  }

  setObjects(objects) {
    this.setState({ objects });
  }

  getCollectionName() {
    return "transactions";
  }

  setObjects(objects) {
    this.setState({ objects });
  }

  getCollectionName() {
    return "transactions";
  }

  convertToCSV(objArray) {
    const array =
      typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
    let str =
      `${Object.keys(array[0])
        .map((value) => `"${value}"`)
        .join(",")}` + "\r\n";
    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in array[i]) {
        if (line !== "") line += ",";
        line += `"${array[i][index]}"`;
      }
      str += line + "\r\n";
    }
    return str;
  }

  exportToCSV = () => {
    const {
      selectedBrand,
      selectedStoreCode,
      selectedDate,
      totalSalesPerHour,
      storess,
    } = this.state;

    const csvData = totalSalesPerHour.map((sales, index) => {
      const hour = (5 + index) % 24; // Adjust according to your chart's starting hour
      return {
        brand: "Angels Pizza",
        store: storess || "All Stores",
        date: selectedDate,
        time: `${hour}:00`,
        averageSales: sales.toFixed(2),
      };
    });

    const csvString = this.convertToCSV(csvData);
    const a = document.createElement("a");
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = `total-sales-time.csv`;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  render() {
    const chartData = this.getChartData();
    const chartOptions = this.getChartOptions();
    const printId = "chart-container3";
    const formatSales = (sales) => {
      return sales.toLocaleString("en-US", {
        style: "currency",
        currency: "PHP",
      });
    };

    return (
      <>
        <div className="d-flex justify-content-between align-items-center mb-1">
          <h2 className="fw-bold text-capitalize mt-4">
            <span style={{ color: "darkcolor" }}>Total</span>
            <span style={{ color: "#83c14a" }}> Sales</span>
            <span style={{ color: "darkcolor" }}> vs. </span>
            <span style={{ color: "#83c14a" }}>Hour of Today</span>
          </h2>
          <Export
            exportCsv={() => this.exportToCSV()}
            exportPdf={() => printChart(printId)}
          />
        </div>
        <div className="row mb-2">
          <div className="col-sm-2">
            <input
              className="form-control"
              type="date"
              value={this.state.selectedDate}
              onChange={this.handleDateChange}
            />
          </div>
          <div className="col-sm-3">
            <select
              className="form-select"
              onChange={(e) => this.onChangeStore(e.target.value)}
            >
              <option value="">Select Store</option>
              <option value="AllStore">All Store</option>
              {this.state.store.map((code, index) => (
                <option key={index} value={code.name}>
                  {code.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        {this.state.objects && this.state.objects.length > 0 ? (
          <div ref={this.chartRef1} id="chart-container3">
            <div className="card">
              <Bar data={chartData} options={chartOptions} />
            </div>
            <div className="print-only-content">
              <h2>Report Details</h2>
              <div className=" print-only-content">
                <div className="">
                  <h3>Brand: Angels Pizza</h3>
                  <h3>Store: {this.state.storess}</h3>
                  <h3>Date: {this.state.selectedDate}</h3>
                </div>
              </div>
              <div className="" style={{ fontSize: "20px" }}>
                <table className="table text-center">
                  <thead>
                    <tr className="d-flex">
                      <th>Month</th>
                      <th className="ms-5">Sales</th>{" "}
                    </tr>
                  </thead>

                  <tbody>
                    {chartData.labels.map((label, index) => (
                      <tr className="d-flex" key={label}>
                        <td>{label}</td>
                        <td>
                          {formatSales(chartData.datasets[0].data[index])}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : this.state.storess ? (
          <div className="text-center mt-5">
            <Progress />
            <h6>Processing... Please wait.</h6>
          </div>
        ) : (
          <div className="text-center align-items-center mt-4">
            <img
              src="./choosefirst.png"
              style={{ height: "200px", width: "200px" }}
            />
            <h4>To proceed, please select a store first</h4>
          </div>
        )}
      </>
    );
  }
}

export default TotalSalesDayPage;
