import React, { useMemo, useState } from "react";
import Export from "../../../../components/Export";
import printChart from "../../../../PrintChart";

function CustomerSalesPerStorePage({ transactions }) {
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedStore, setSelectedStore] = useState("All");
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const getUniqueStores = (transactions) => {
    const storeSet = new Set();
    transactions.forEach((transaction) => {
      const storeCode = transaction.store_code.split("_")[1];
      storeSet.add(storeCode);
    });
    return Array.from(storeSet);
  };

  const uniqueStores = useMemo(
    () => getUniqueStores(transactions),
    [transactions]
  );

  const getCustomerCategories = (transactions) => {
    const oneYearAgo = new Date();
    oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

    const ninetyDaysAgo = new Date();
    ninetyDaysAgo.setDate(ninetyDaysAgo.getDate() - 90);

    const customerMap = new Map();

    transactions.forEach((transaction) => {
      const storeCode = transaction.store_code.split("_")[1];
      if (selectedStore !== "All" && storeCode !== selectedStore) {
        return;
      }

      const tranDate = new Date(transaction.tran_date);
      const currentDate = new Date();

      if (tranDate >= oneYearAgo) {
        const customer = customerMap.get(transaction.cust_phone) || {
          custPhone: transaction.cust_phone,
          custName: transaction.cust_name,
          totalSpend: 0,
          purchaseCount: 0,
          lastPurchaseDate: tranDate,
          daysSinceLastPurchase: Math.floor(
            (currentDate - tranDate) / (1000 * 60 * 60 * 24)
          ),
        };

        customer.totalSpend += parseFloat(transaction.total);
        customer.purchaseCount += 1;

        if (tranDate > customer.lastPurchaseDate) {
          customer.lastPurchaseDate = tranDate;
        }

        customerMap.set(transaction.cust_phone, customer);
      }
    });

    customerMap.forEach((customer, custPhone) => {
      customer.stores = {};
      transactions.forEach((transaction) => {
        if (transaction.cust_phone === custPhone) {
          const storeCode = transaction.store_code.split("_")[1];
          if (!customer.stores[storeCode]) {
            customer.stores[storeCode] = 0;
          }
          customer.stores[storeCode] += parseFloat(transaction.total);
        }
      });
    });

    const categories = {
      loyalists: [],
      potentialLoyalists: [],
      promising: [],
      hesitant: [],
      needAttention: [],
      detractors: [],
    };

    customerMap.forEach((customer, name) => {
      const recentPurchase = customer.lastPurchaseDate >= thirtyDaysAgo;
      const pastMonthPurchase =
        customer.lastPurchaseDate >= ninetyDaysAgo &&
        customer.lastPurchaseDate < thirtyDaysAgo;
      const lastPurchaseOver90Days = customer.lastPurchaseDate < ninetyDaysAgo;

      if (
        customer.totalSpend >= 5000 &&
        customer.purchaseCount >= 5 &&
        recentPurchase
      ) {
        categories.loyalists.push(customer);
      } else if (
        customer.totalSpend >= 2000 &&
        customer.totalSpend < 5000 &&
        customer.purchaseCount >= 5 &&
        recentPurchase
      ) {
        categories.potentialLoyalists.push(customer);
      } else if (
        customer.totalSpend >= 2000 &&
        customer.totalSpend < 5000 &&
        customer.purchaseCount >= 2 &&
        customer.purchaseCount <= 4 &&
        recentPurchase
      ) {
        categories.promising.push(customer);
      } else if (
        customer.totalSpend < 2000 &&
        customer.purchaseCount >= 2 &&
        customer.purchaseCount <= 4 &&
        recentPurchase
      ) {
        categories.hesitant.push(customer);
      } else if (
        customer.totalSpend >= 2000 &&
        customer.totalSpend < 5000 &&
        customer.purchaseCount >= 2 &&
        customer.purchaseCount <= 4 &&
        pastMonthPurchase
      ) {
        categories.needAttention.push(customer);
      } else if (
        customer.totalSpend < 2000 &&
        customer.purchaseCount === 1 &&
        lastPurchaseOver90Days
      ) {
        categories.detractors.push(customer);
      }
    });

    return categories;
  };

  const filteredCustomers = useMemo(() => {
    const allCustomerCategories = getCustomerCategories(
      transactions,
      selectedStore
    );
    if (selectedFilter === "All") {
      return Object.values(allCustomerCategories).flat();
    }
    return allCustomerCategories[selectedFilter.toLowerCase()] || [];
  }, [transactions, selectedFilter, selectedStore]);

  const handleFilterChange = (event) => {
    setSelectedFilter(event.target.value);
  };

  const handleRowClick = (index) => {
    setSelectedItem(selectedItem === index ? null : index);
  };

  const visibleCustomers = useMemo(() => {
    if (!searchQuery) return filteredCustomers;

    return filteredCustomers.filter((customer) =>
      customer.custPhone.includes(searchQuery)
    );
  }, [filteredCustomers, searchQuery]);

  console.log("fil", filteredCustomers);

  const chartId = 120;

  function exportCsv() {
    let csvContent = "data:text/csv;charset=utf-8,";
    // CSV Header
    csvContent += `"Customer","Store","Net Sales"\r\n`;

    visibleCustomers.forEach((customer) => {
      Object.entries(customer.stores).forEach(([store, totalSales]) => {
        // Ensure numbers are formatted as plain numbers without currency symbols for CSV
        const formattedSales = totalSales.toFixed(2);
        // Enclose fields in double quotes to handle any commas or special characters
        const row = `"${customer.custName}","${store}","${formattedSales}"\r\n`;
        csvContent += row;
      });
    });

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "customer_sales_per_store.csv");
    document.body.appendChild(link); // Required for FF

    link.click(); // This will download the CSV file
    document.body.removeChild(link);
  }

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-1 mt-4">
        <h2 className="fw-bold text-capitalize">
          <span style={{ color: "#83c14a" }}>Customer</span>
          <span style={{ color: "black" }}> Sales</span>
          <span style={{ color: "#83c14a" }}> Per</span>
          <span style={{ color: "black" }}> Store</span>
        </h2>
        <Export exportCsv={exportCsv} exportPdf={() => printChart(chartId)} />
        {/* Filter dropdown */}
      </div>
      <div className="row mb-2">
        <div className="input-group">
          <i
            className="bi bi-search position-absolute"
            style={{
              zIndex: 4,
              width: "50px",
              height: "50px",
              lineHeight: "40px",
              textAlign: "center",
            }}
          />
          <input
            type="text"
            value={searchQuery}
            onChange={handleSearchChange}
            placeholder="Search by Phone Number"
            className=" form-control rounded-pill ps-5"
          />
        </div>
      </div>

      <div id="120">
        <table className="table">
          <thead>
            <tr>
              <th style={{ backgroundColor: "#83c14a", color: "white" }}>
                Customer
              </th>
              <th style={{ backgroundColor: "#83c14a", color: "white" }}>
                Store
              </th>
              <th style={{ backgroundColor: "#83c14a", color: "white" }}>
                Net Sales
              </th>
            </tr>
          </thead>
          <tbody>
            {visibleCustomers.length === 0 ? (
              <tr>
                <td
                  colSpan="3"
                  style={{ textAlign: "center", verticalAlign: "middle" }}
                >
                  No Data
                </td>
              </tr>
            ) : (
              visibleCustomers
                .filter((customer) => customer.custName !== "-")
                .map((customer, index) =>
                  Object.entries(customer.stores).map(([store, totalSales]) => (
                    <tr key={`${customer.custPhone}-${store}-${index}`}>
                      <td>{customer.custName}</td>
                      <td>{store}</td>
                      <td>
                        {new Intl.NumberFormat("en-PH", {
                          style: "currency",
                          currency: "PHP",
                        }).format(totalSales)}
                      </td>
                    </tr>
                  ))
                )
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default CustomerSalesPerStorePage;
