import React from "react";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import CumulativeTransactionPresenter from "./CumulativeTransactionPresenter";
import { findObjectUseCase } from "../../../usecases/object";
import BaseListPage from "../../../base/BaseListPage";
import withRouter from "../../../withRouter";
import Export from "../../../components/Export";
import printChart from "../../../PrintChart";
import { Progress } from "nq-component";

class CumulativeTransactionPage extends BaseListPage {
  constructor(props) {
    super(props);
    this.presenter = new CumulativeTransactionPresenter(
      this,
      findObjectUseCase()
    );
    this.state = {
      objects: [],
      selectedStoreCode: "",
      selectedMonth: "",
      selectedYear: "",
      store: [],
      storess: "",
      range: "",
      selectedMonth: "",
      selectedMonthName: ""
    };
    this.handleStoreCodeChange = this.handleStoreCodeChange.bind(this);
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
  }

  getCollectionName() {
    return "transactions";
  }

  setStore(store) {
    this.setState({ store });
  }

  onChangeStore(where) {
    this.setState({ storess: where });
    this.presenter.onChangeStore(where);
  }

  getUniqueStoreCodes() {
    const storeCodes = this.state.objects.map((obj) =>
      // Remove "ANGELSPIZZA_" from the start and return the rest
      obj.store_code.replace("ANGELSPIZZA_", "")
    );

    return [...new Set(storeCodes)]; // Remove duplicates
  }

  handleDropdownChange(event) {
    // const { name, value } = event.target;
    // this.setState({ [name]: value });
    const monthNames = {
      "January": "01",
      "February": "02",
      "March": "03",
      "April": "04",
      "May": "05",
      "June": "06",
      "July": "07",
      "August": "08",
      "September": "09",
      "October": "10",
      "November": "11",
      "December": "12"
    };
    let monthName
    this.setState({ selectedMonth: event.target.value });
    // console.log("b", event.target.value)

    if (event.target.value === "AllMonths") {
      this.presenter.onChangeDate("AllMonths");
    }
    else {
      this.setState({ range: event.target.value })
      this.presenter.onChangeDate(JSON.parse(event.target.value))
      const monthi = JSON.parse(event.target.value).$gte.split("-")
      monthName = Object.keys(monthNames).find(key => monthNames[key] === monthi[1]);
    }

    this.setState({ selectedMonthName: monthName });
  }

  getMonthOptions() {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return months.map((month) => (
      <option key={month} value={month}>
        {month}
      </option>
    ));
  }

  handleStoreCodeChange(event) {
    this.setState({ selectedStoreCode: event.target.value });
  }

  handleYearChange = (event) => {
    const year = event.target.value; // Assuming year is a string like "2024"

    // Assuming 'range' is a JSON string that needs to be parsed
    let parsedRange = JSON.parse(this.state.range);

    // Concatenate the year with the rest of the date, including the dash
    parsedRange.$gte = year + parsedRange.$gte.substring(parsedRange.$gte.indexOf("-"));
    parsedRange.$lte = year + parsedRange.$lte.substring(parsedRange.$lte.indexOf("-"));

    this.presenter.onChangeDate(parsedRange)
    this.setState({ selectedYear: event.target.value });
  };

  countTransactionTypesFromMonth = (transactions, month, year) => {
    const { objects, selectedStoreCode, selectedMonth, selectedYear } =
      this.state;

    // Filter data based on the selected store, month, and year
    const filteredSales = objects.filter(status => status.status === "C").filter((sale) => {
      const saleDate = new Date(sale.createdAt);
      const saleYear = saleDate.getFullYear();
      const saleMonth = saleDate.toLocaleString("default", { month: "long" });
      const saleStoreCode = sale.store_code.replace("ANGELSPIZZA_", "");

      const yearMatches = selectedYear
        ? saleYear === parseInt(selectedYear)
        : true;
      const monthMatches = selectedMonth ? saleMonth === this.state.selectedMonthName : true;
      const storeMatches = selectedStoreCode
        ? saleStoreCode === selectedStoreCode
        : true;

      return yearMatches && monthMatches && storeMatches;
    });

    const cumulative = filteredSales;

    let totalGuest = 0;
    let totalTransaction = 0;

    cumulative.forEach((transaction) => {
      const transactionDate = new Date(transaction.createdAt);
      if (
        transactionDate.getMonth() === month &&
        transactionDate.getFullYear() === year
      ) {
        totalGuest += Number(transaction.cust_count || "0");
        totalTransaction += 1;
      }
    });

    return { totalGuest, totalTransaction };
  };

  months() {
    const monthNames = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December",
    ];

    const monthsFormatted = monthNames.map((name, index) => {
      const monthNumber = (index + 1).toString().padStart(2, '0');
      const firstDayOfMonth = `${this.state.selectedYear}-${monthNumber}-01T00:00:00.000Z`;

      // Calculate the last day of the month
      const lastDayOfMonthDate = new Date(this.state.selectedYear, index + 1, 0); // The 0 here gets the last day of the previous month, i.e., the month we're interested in
      // const lastDayOfMonth = `${lastDayOfMonthDate.getFullYear()}-${(lastDayOfMonthDate.getMonth() + 1).toString().padStart(2, '0')}-${lastDayOfMonthDate.getDate().toString().padStart(2, '0')}T23:59:59.999Z`;
      const lastDayOfMonth = `${this.state.selectedYear}-${(lastDayOfMonthDate.getMonth() + 1).toString().padStart(2, '0')}-${lastDayOfMonthDate.getDate().toString().padStart(2, '0')}T23:59:59.999Z`;

      return {
        name, // The display name of the month
        createdAt: {
          $gte: firstDayOfMonth,
          $lte: lastDayOfMonth
        }
      };
    });

    return monthsFormatted;
  }

  getChartData() {
    const newCounts = this.countTransactionTypesFromMonth(this.state.objects);

    const currentDate = new Date();
    const monthsToShow = 3; // Number of months you want to show including the current month
    const labels = [];

    for (let i = monthsToShow - 1; i >= 0; i--) {
      // Get the first day of each month
      const date = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() - i,
        1
      );
      // Format it as 'Month Year'
      labels.push(
        date.toLocaleString("default", { month: "long", year: "numeric" })
      );
    }

    return {
      labels,
      datasets: [
        {
          label: "Total Guest",
          data: labels.map((label) => {
            const date = new Date(label);
            return this.countTransactionTypesFromMonth(
              this.state.objects,
              date.getMonth(),
              date.getFullYear()
            ).totalGuest;
          }),
          borderColor: "#1b74bb",
          backgroundColor: "rgba(53, 162, 235, 0.5)",
          fill: true,
        },
        {
          label: "Total Transaction",
          data: labels.map((label) => {
            const date = new Date(label);
            return this.countTransactionTypesFromMonth(
              this.state.objects,
              date.getMonth(),
              date.getFullYear()
            ).totalTransaction;
          }),
          borderColor: "rgb(255, 205, 86)",
          backgroundColor: "rgba(255, 205, 86, 0.5)",
          fill: true,
        },
      ],
    };
  }

  getChartOptions() {
    return {
      chartOptions: {
        responsive: true,
        plugins: {
          legend: {
            position: "top",
          },
          title: {
            display: true,
            text: "Total Transactions vs Time of Day (Hours)",
          },
        },
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
          },
        },
      },
    };
  }

  convertToCSV(objArray) {
    const array =
      typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
    let str =
      `${Object.keys(array[0])
        .map((value) => `"${value}"`)
        .join(",")}` + "\r\n";
    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in array[i]) {
        if (line !== "") line += ",";
        line += `"${array[i][index]}"`;
      }
      str += line + "\r\n";
    }
    return str;
  }

  exportToCSV = () => {
    const { selectedStoreCode, selectedMonth, selectedYear, storess, range } = this.state;
    const chartData = this.getChartData(); // Assuming this method returns the latest chart data

    // You would need to modify this to fit the data structure you are using
    const cumulativeData = chartData.datasets[0].data;
    const transactionData = chartData.datasets[1].data;
    const dateRange = JSON.parse(range);

    const csvRows = [
      ["Brand", "Store", "Date", "Cumulative", "Transaction"], // headers
    ];

    // Assuming labels are the months and are in sync with your data arrays
    chartData.labels.forEach((label, index) => {
      const row = [
        "Angels Pizza", // Replace with actual brand name if available
        storess || "All Stores",
        `${selectedYear}${dateRange.$lte.split("T")[0]} ${selectedYear}${dateRange.$gte.split("T")[0]}`,
        cumulativeData[index],
        transactionData[index],
      ];
      csvRows.push(row.join(",")); // Create comma-separated string from row array
    });

    // Convert array of strings into a single string separated by newlines
    const csvString = csvRows.join("\n");

    // Create a Blob from the CSV String
    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });

    // Create a link element, use it to download the Blob, and remove it
    const link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "CumulativeTransactions.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  render() {
    const chartData = this.getChartData();
    const chartOptions = this.getChartOptions();
    const uniqueStoreCodes = this.getUniqueStoreCodes();
    const years = [
      ...new Set(
        this.state.objects.map((obj) => new Date(obj.createdAt).getFullYear())
      ),
    ];
    const chartId = "chart-container12";

    return (
      <>
        <div className="d-flex justify-content-between align-items-center mb-1 mt-4">
          <h2 className="fw-bold text-capitalize">
            <span style={{ color: "#83c14a" }}>Cumulative</span>
            <span style={{ color: "black" }}> vs.</span>
            <span style={{ color: "#c5da55" }}> Transaction</span>
          </h2>
          <Export
            exportCsv={() => this.exportToCSV()}
            exportPdf={() => printChart(chartId)}
          />
        </div>
        <div className="row mb-2">
          <div className="col-sm-3">
            <select
              className="form-select"
              // value={this.state.selectedStoreCode}
              onChange={(e) => this.onChangeStore(e.target.value)}
            >
              <option value="">Select Store</option>
              <option value="AllStore">All Store</option>
              {this.state.store.map((code, index) => (
                <option key={index} value={code.name}>
                  {code.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-sm-3">
            <select
              className="form-select"
              name="selectedMonth"
              value={this.state.selectedMonth}
              onChange={this.handleDropdownChange}
            >
              <option defaultValue disabled={this.state.selectedMonth || this.state.selectedMonthName}>Select Month</option>
              <option value="AllMonths">All Months</option>
              {this.months().map((month, index) => (
                <option key={index} value={JSON.stringify(month.createdAt)}>
                  {month.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-sm-3">
            <select
              className="form-select"
              onChange={this.handleYearChange}
              value={this.state.selectedYear}
            >
              <option defaultValue disabled={this.state.selectedYear && this.state.selectedYear}>Select Year</option>
              <option value="2023">2023</option>
              <option value="2024">2024</option>
            </select>
          </div>
        </div>
        {this.state.objects && this.state.objects.length > 0 ? (
          <div ref={this.chartRef1} id="chart-container12">
            <div className="card">
              <Line data={chartData} options={chartOptions} />
            </div>
            <div className="print-only-content">
              <h2>Report Details</h2>
              <div className=" print-only-content">
                <div className="">
                  <h3>Brand: Angels Pizza</h3>
                  <h3>Store: {this.state.storess}</h3>
                </div>
              </div>
              <div className="" style={{ fontSize: "20px" }}>
                <table className="table text-center">
                  <thead>
                    <tr className="d-flex">
                      <th>Month</th>
                      <th className="ms-5">Total Guest</th>{" "}
                      <th className="ms-5">Total Transaction</th>{" "}
                    </tr>
                  </thead>

                  <tbody>
                    {chartData.labels.map((label, index) => (
                      <tr className="d-flex" key={label}>
                        <td>{label} ------ </td>
                        <td>{chartData.datasets[0].data[index]} ------ </td>
                        <td>{chartData.datasets[1].data[index]}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : this.state.storess ? (
          <div className="text-center mt-5">
            <Progress />
            <h6>Processing... Please wait.</h6>
          </div>
        ) : (
          <div className="text-center align-items-center mt-4">
            <img
              src="./choosefirst.png"
              style={{ height: "200px", width: "200px" }}
            />
            <h4>To proceed, please select a store first</h4>
          </div>
        )}
      </>
    );
  }
}

export default withRouter(CumulativeTransactionPage);
