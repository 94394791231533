import React from "react";
import Papa from "papaparse";
import BaseListPage from "../../base/BaseListPage";
import withRouter from "../../withRouter";
import { countObjectUseCase, findObjectUseCase } from "../../usecases/object";
import DateRange from "../../components/DateRange";
import ReportPresenter from "./ReportPresenter";
import { InputSelect, Progress } from "nq-component";
import { exportCSVUseCase } from "../../usecases/csv/usecases";
import NavBar2 from "../../components/NavBar2";
import printComponent from "../../printComponent";
import ExportPdfs from "../../components/ExportPdfs";
import DateRangeReport from "../../components/DateRangeReport";

class ReportPage extends BaseListPage {
  constructor(props) {
    super(props);
    this.presenter = new ReportPresenter(
      this,
      findObjectUseCase(),
      countObjectUseCase(),
      exportCSVUseCase()
    );

    this.state = {
      objects: [],
      aggregatedData: {},
      startDate: "",
      endDate: "",
      range: "",
      storess: "",
      store: [],
      storeCode: "",
      totalNet: 0,
      totalModeTransac: 0,
      percentageByHans: 0,
    };
  }

  getCollectionName() {
    return "transactions";
  }

  setStore(store) {
    this.setState({ store });
  }

  onChangeDate(where) {
    console.log("where", where);

    const startDate = where.tran_date?.$gte
      ? new Date(where.tran_date.$gte)
      : new Date();
    const endDate = where.tran_date?.$lte
      ? new Date(where.tran_date.$lte)
      : new Date();

    // If startDate and endDate are the same, adjust endDate to include the entire day
    if (startDate.toDateString() === endDate.toDateString()) {
      endDate.setHours(23, 59, 59, 999); // Set to end of the day
    }

    // Convert back to your desired format if necessary
    const formattedStartDate = startDate.toLocaleDateString();
    const formattedEndDate = endDate.toLocaleDateString();

    // Assuming this.presenter.onChangeDate() expects the date range in a specific format
    const adjustedWhere = {
      ...where,
      createdAt: {
        $gte: startDate,
        $lte: endDate,
      },
    };

    // Update the presenter and state with the adjusted dates
    this.presenter.onChangeDate(where);
    this.setState({ range: where });

    this.setState({
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    });

    // this.setState({totalNet: })
  }

  aggregateData() {
    const objects = this.state.objects;
    const aggregatedData = {};
    console.log("Aggregate: ", aggregatedData);

    objects
      .filter((obj) => obj.status === "C")
      .forEach((obj) => {
        const storeCode = obj.store_code;
        const tranType = this.getTranType(obj.tran_type);

        if (!aggregatedData[storeCode]) {
          aggregatedData[storeCode] = {};
        }

        if (!aggregatedData[storeCode][tranType]) {
          aggregatedData[storeCode][tranType] = { tc: 0, netSale: 0 };
        }

        const grossSale = parseFloat(obj.total);
        // +
        // parseFloat(obj.discount_amount) +
        // parseFloat(obj.total_tax);
        const netSale = grossSale;
        // - parseFloat(obj.discount_amount);

        aggregatedData[storeCode][tranType].tc += 1;
        aggregatedData[storeCode][tranType].netSale += netSale;
        console.log(
          "try",
          (aggregatedData[storeCode][tranType].netSale += netSale)
        );
      });
  }

  getTranType(tranType) {
    const tranTypeMapping = {
      U: "Delivery",
      D: "Dine In",
      T: "Take Out",
      G: "GrabFood",
      F: "FoodPanda",
      P: "Pick Up",
      B: "Del Time",
      K: "Drive-thru",
    };
    return tranTypeMapping[tranType];
  }

  renderTableHeader() {
    const allTransactionTypes = [
      "Delivery",
      "Dine In",
      "Take Out",
      "GrabFood",
      "FoodPanda",
      "Drive-thru",
      "undefined",
    ];

    return (
      <tr>
        <th className=" text-white" style={{ backgroundColor: "#83c14a" }}>
          STORE
        </th>
        {allTransactionTypes.map((type, index) => (
          <React.Fragment key={index}>
            <th className=" text-white" style={{ backgroundColor: "#83c14a" }}>
              {type}
            </th>
            <th className=" text-white" style={{ backgroundColor: "#83c14a" }}>
              TC
            </th>
            <th className=" text-white" style={{ backgroundColor: "#83c14a" }}>
              %
            </th>
          </React.Fragment>
        ))}
        <th className=" text-white" style={{ backgroundColor: "#83c14a" }}>
          Total Amount
        </th>
        <th className=" text-white" style={{ backgroundColor: "#83c14a" }}>
          Total Count
        </th>
      </tr>
    );
  }

  renderTableRow(store, transactionTypes) {
    const storeData = this.state.aggregatedData[store] || {};
    console.log("renderTableRow", store);

    const allTransactionTypes = [
      "Delivery",
      "Dine In",
      "Take Out",
      "GrabFood",
      "FoodPanda",
      "Drive-thru",
      "undefined",
    ];
    const currencyFormatter = new Intl.NumberFormat("en-PH", {
      style: "currency",
      currency: "PHP",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    // Assuming totalNet might need to be calculated dynamically or passed in
    const totalNet = allTransactionTypes.reduce(
      (acc, type) => acc + (storeData[type]?.netSale || 0),
      0
    );

    let totalCount = 0;

    console.log("aaaa", this.state.aggregatedData[store]);

    return (
      <tr key={store}>
        <td>{store}</td>
        {allTransactionTypes.map((type) => {
          const typeData = storeData[type] || {};
          const netSale = typeData.netSale || 0;
          const transactionCount = typeData.tc || 0;
          const percentageOfTotalPerTransaction =
            totalNet > 0 ? (netSale / totalNet) * 100 : 0;
          totalCount += transactionCount;
          console.log("total", totalNet);
          return (
            <React.Fragment key={type}>
              <td>{currencyFormatter.format(netSale)}</td>
              <td>{transactionCount}</td>
              <td>
                {isNaN(percentageOfTotalPerTransaction)
                  ? "0%"
                  : `${percentageOfTotalPerTransaction.toFixed(2)}%`}
              </td>
            </React.Fragment>
          );
        })}
        <td>{currencyFormatter.format(totalNet)}</td>
        <td>{totalCount}</td>
      </tr>
    );
  }

  onClickExport() {
    const { objects, aggregatedData, startDate, endDate } = this.state;
    const transactionTypes = this.getUniqueTransactionTypes(aggregatedData);
    const filteredObj = objects.filter((obj) => obj.status === "C");
    const allTransactionTypes = [
      "Delivery",
      "Dine In",
      "Take Out",
      "GrabFood",
      "FoodPanda",
      "Drive-thru",
      "undefined",
    ];

    // Calculate summary totals
    const gross_sales = filteredObj
      .map((object) => Number(object.total))
      .reduce((acc, curr) => acc + curr, 0);
    const totalDiscountAmount = filteredObj.reduce(
      (acc, object) => acc + Number(object.discount_amount),
      0
    );
    const totalPretax = filteredObj.reduce(
      (acc, object) => acc + Number(object.total_pretax),
      0
    );
    const totalTax = filteredObj.reduce(
      (acc, object) => acc + Number(object.total_tax),
      0
    );
    const totalTaxFree = filteredObj.reduce(
      (acc, object) => acc + Number(object.total_taxfree),
      0
    );
    const totalNetSales = gross_sales - totalTax;

    // Transform the aggregated data into a format suitable for CSV export
    const dataToExport = Object.keys(aggregatedData).map((store) => {
      const storeData = aggregatedData[store] || {};
      let rowData = { Store: store };
      let totalNet = 0;
      let totalCount = 0;

      allTransactionTypes.forEach((type) => {
        const typeData = storeData[type] || {};
        const netSale = typeData.netSale || 0;
        const transactionCount = typeData.tc || 0;
        const percentageOfTotalPerTransaction =
          totalNet > 0 ? (netSale / totalNet) * 100 : 0;
        totalNet += netSale;
        totalCount += transactionCount;

        rowData = {
          ...rowData,
          [`${type} Net Sale`]: netSale,
          [`${type} TC`]: transactionCount,
          [`${type} Percentage`]: percentageOfTotalPerTransaction.toFixed(2),
        };
      });

      rowData["Total Net Sale"] = totalNet;
      rowData["Total Count"] = totalCount;
      return rowData;
    });

    console.warn("filter: ", dataToExport);
    // Append summary totals to the data for export
    dataToExport.push({
      Store: "Summary Totals",
      "Gross Sales": gross_sales,
      "Total Discount Amount": totalDiscountAmount,
      "Total Pretax": totalPretax,
      "Total Tax": totalTax,
      "Total Tax Free": totalTaxFree,
      "Net Sales": totalNetSales,
    });

    // Define CSV headers based on the transaction types
    const csvHeaders = [
      "Store",
      ...allTransactionTypes.flatMap((type) => [
        `${type} Net Sale`,
        `${type} TC`,
        `${type} Percentage`,
      ]),
      "Total Net Sale",
      "Total Count",
    ];

    // Include summary totals in the headers if necessary
    csvHeaders.push(
      "Gross Sales",
      "Total Discount Amount",
      "Total Pretax",
      "Total Tax",
      "Total Tax Free",
      "Net Sales"
    );

    // Use PapaParse to convert the data to CSV
    const csv = Papa.unparse({
      fields: csvHeaders,
      data: dataToExport,
    });

    // Trigger the download
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.setAttribute("download", `report-${startDate}-to-${endDate}.csv`);
    document.body.appendChild(link); // Required for FF
    link.click();
    document.body.removeChild(link);
  }

  getUniqueTransactionTypes(aggregatedData) {
    const transactionTypes = new Set();
    Object.values(aggregatedData).forEach((storeData) => {
      Object.keys(storeData).forEach((type) => {
        transactionTypes.add(type);
      });
    });
    return Array.from(transactionTypes);
  }

  calculateTotalNetSale = () => {
    const { aggregatedData, storess } = this.state;
    let storesplit = storess;
    let totalNetSale = 0;

    if (aggregatedData && aggregatedData[storesplit]) {
      Object.keys(aggregatedData[storesplit]).forEach((transactionType) => {
        totalNetSale +=
          aggregatedData[storesplit][transactionType]?.netSale || 0;
      });
    }
    return totalNetSale;
  };

  componentDidMount() {
    // You need to fetch your data here and update `this.state.objects`
    this.presenter.componentDidMount();
    // this.aggregateData();
    this.calculateTotalNetSale();
  }

  componentDidUpdate(prevProps, prevState) {
    const { aggregatedData } = this.state;

    if (aggregatedData) {
      // Check if `totalNet` needs to be updated
      const totalNetSale = this.calculateTotalNetSale();

      if (totalNetSale !== this.state.totalNet) {
        this.setState({ totalNet: totalNetSale }, () => {
          console.log("totalNet updated:", this.state.totalNet);
        });
      }
    }

    if (this.state.storess !== prevState.storess) {
      // No need to call calculateTotalNetSale here, as it's already checked in the above block
      // this.calculateTotalNetSale();
    }
  }

  onChangeStore(where) {
    this.setState({ storess: where });
    this.presenter.onChangeStore(where);
  }

  render() {
    const { aggregatedData, storess } = this.state;
    const objects = this.state.objects?.filter((obj) => obj.status === "C");
    const objectsVoid = this.state.objects?.filter((obj) => obj.status === "V");

    const transactionTypes = Object.values(aggregatedData).flatMap(
      (storeData) => Object.keys(storeData)
    );

    const uniqueTransactionTypes = [...new Set(transactionTypes)];

    const nettsales = objects
      .map(
        (object) =>
          Number(object.total) -
          // Number(object.discount_amount) -
          Number(object.total_tax)
      )
      .reduce((acc, curr) => acc + curr, 0);

    const gross_sales = objects
      .map((object) => Number(object.total))
      .reduce((acc, curr) => acc + curr, 0);

    const totalDiscountAmount = objects.reduce(
      (acc, object) => acc + Number(object.discount_amount),
      0
    );
    const totalPretax = objects.reduce(
      (acc, object) => acc + Number(object.total_pretax),
      0
    );
    const totalTax = objects.reduce(
      (acc, object) => acc + Number(object.total_tax),
      0
    );
    const totalTaxFree = objects.reduce(
      (acc, object) => acc + Number(object.total_taxfree),
      0
    );
    const totalVoidAmount = objectsVoid
      .map((object) => Number(object.total))
      .reduce((acc, curr) => acc + curr, 0);
    const totalVoidCount = objectsVoid?.length;

    const totalNetSales = nettsales;

    const currencyFormatter = new Intl.NumberFormat("en-PH", {
      style: "currency",
      currency: "PHP",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    const sortedStores = Object.keys(aggregatedData).sort();

    return (
      <>
        <NavBar2
          action={() => (
            <div className="dropdown dropstart d-inline-block me-2">
              <i
                role="button"
                data-bs-toggle="dropdown"
                className="bi bi-three-dots-vertical"
              ></i>
              <div className="dropdown-menu fs-xs">
                <button
                  onClick={this.onClickExport.bind(this)}
                  className="dropdown-item py-3"
                >
                  <i className="bi bi-arrow-up-square pe-2" />
                  Export CSV Data
                </button>
              </div>
            </div>
          )}
        />
        <div className="">
          <div className="p-3 p-lg-4">
            <div className="d-flex justify-content-between align-items-center">
              <h1
                className="fw-bold mt-3 text-capitalize"
                style={{ color: "#83c14a" }}
              >
                Report Analytics
              </h1>
              <div className="">
                <h5 className="mt-1" style={{ fontSize: "12px" }}>
                  TOTAL NET SALES: {currencyFormatter.format(totalNetSales)}
                </h5>
                <h5 className="mt-1" style={{ fontSize: "12px" }}>
                  TOTAL GROSS SALES: {currencyFormatter.format(gross_sales)}
                </h5>

                <h5 className="mt-1" style={{ fontSize: "12px" }}>
                  TOTAL PRETAX: {currencyFormatter.format(totalPretax)}
                </h5>
                <h5 className="mt-1" style={{ fontSize: "12px" }}>
                  TOTAL TAX: {currencyFormatter.format(totalTax)}
                </h5>
                <h5 className="mt-1" style={{ fontSize: "12px" }}>
                  TOTAL TAX FREE: {currencyFormatter.format(totalTaxFree)}
                </h5>
                <h5 className="mt-1" style={{ fontSize: "12px" }}>
                  TOTAL DISCOUNT:
                  {currencyFormatter.format(totalDiscountAmount)}
                </h5>
                <h5 className="mt-1" style={{ fontSize: "12px" }}>
                  TOTAL VOID AMOUNT:
                  {currencyFormatter.format(totalVoidAmount)}
                </h5>
                <h5 className="mt-1" style={{ fontSize: "12px" }}>
                  TOTAL VOID COUNT: {totalVoidCount}
                </h5>
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-sm-4" style={{ maxWidth: "max-content" }}>
                <DateRangeReport
                  onChange={this.onChangeDate.bind(this)}
                  field="tran_date"
                />
              </div>

              <div className="col-sm-3">
                <select
                  className="form-select"
                  // value={this.state.selectedStoreCode}
                  onChange={(e) => {
                    this.onChangeStore(e.target.value);
                    this.setState({ storeCode: e.target.value });
                  }}
                  disabled={this.state.range ? false : true}
                >
                  <option value="">Select Store</option>
                  <option value="AllStore">All Store</option>
                  {this.state.store.map((code, index) => (
                    <option key={index} value={code.name}>
                      {code.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {(!this.state.range || !this.state.storess) && (
              <div className="text-center align-items-center mt-4">
                <img
                  src="./choosefirst.png"
                  style={{ height: "200px", width: "200px" }}
                />
                <h4>To proceed, please select a date first</h4>
              </div>
            )}

            <div className="overflow-auto row mt-1 g-3">
              {Object.keys(aggregatedData).length > 0 &&
                !this.presenter.progress && (
                  <table className="table">
                    <thead className=" text-white">
                      {this.renderTableHeader(uniqueTransactionTypes)}
                    </thead>
                    <tbody>
                      {/* {Object.keys(aggregatedData).map((store) =>
                        this.renderTableRow(store, uniqueTransactionTypes)
                      )} */}
                      {sortedStores.map((store) =>
                        this.renderTableRow(store, uniqueTransactionTypes)
                      )}
                    </tbody>
                  </table>
                )}
            </div>

            {this.presenter.progress &&
              this.state.storess &&
              this.state.objects.length < 1 && (
                <div className="text-center mt-5">
                  <Progress />
                  <h6>Processing... Please wait.</h6>
                </div>
              )}
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(ReportPage);
