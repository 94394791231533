import React from "react";

function ExportPdfDash2({ data }) {
  return (
    <div style={{ padding: "10px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <img width="100px" src="/figaro.png" alt="Figaro Logo" />
        <div style={{ textAlign: "center" }}>
          <h4 className="mb-0">FIGARO COFFEE SYSTEM, INC.</h4>
          <h4 className="mb-0">TOTAL SALES TRANSACTION</h4>
        </div>

        <img width="100px" src="/angels.png" alt="Angels Logo" />
      </div>
      <div
        style={{
          marginTop: "60px",
          display: "flex",
          flexWrap: "wrap",
          gap: "1rem",
        }}
      >
        {data.map((data, index) => (
          <div
            key={index}
            style={{
              boxShadow: "0 2px 5px rgba(0,0,0,0.15)",
              borderRadius: "4px",
              padding: "20px",
              width: "calc(33.333% - 1rem)", // 3 cards per row
              boxSizing: "border-box",
              backgroundColor: "#f1f6d4",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <h5>{data.trans_type}</h5>
            <p>
              {data.TotalSales.toLocaleString(undefined, {
                style: "currency",
                currency: "PHP",
              })}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ExportPdfDash2;
