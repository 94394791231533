import React, { useMemo, useState } from "react";
import Export from "../../../../components/ExportBreakdown";
import { Button, InfiniteScroll } from "nq-component";
import getCustomerCategories from "../customer-count/CustomerCategories";

function CustomerBreakdownPage({ transactions }) {
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedStore, setSelectedStore] = useState("All");
  const [nameSearch, setNameSearch] = useState("");
  const [numberSearch, setNumberSearch] = useState("");
  const [showTransaction, setShowTransaction] = useState(false);

  const getUniqueStores = (transactions) => {
    const storeSet = new Set();
    transactions.forEach((transaction) => {
      const storeCode = transaction.store_code.replace("ANGELSPIZZA_", "");
      storeSet.add(storeCode);
    });
    return Array.from(storeSet);
  };

  const uniqueStores = useMemo(
    () => getUniqueStores(transactions),
    [transactions]
  );

  const filteredCustomers = useMemo(() => {
    const allCustomerCategories = getCustomerCategories(
      transactions,
      selectedStore
    );
    const flattenedCustomers = Object.values(allCustomerCategories).flat();

    const filtered = flattenedCustomers.filter((item) => {
      const matchesName = nameSearch
        ? item.custName.toLowerCase().includes(nameSearch.toLowerCase())
        : true;
      const matchesNumber = numberSearch
        ? item.custMobile.includes(numberSearch)
        : true;
      const matchesFilter =
        selectedFilter === "All" || selectedFilter === ""
          ? true
          : (allCustomerCategories[selectedFilter] || []).includes(item);

      return matchesName && matchesNumber && matchesFilter;
    });
    filtered.sort((a, b) => {
      const isSpecialA =
        a.custMobile === "-" ||
        a.custMobile === ".." ||
        a.custPhone === "-" ||
        a.custPhone === "..";

      const isSpecialB =
        b.custMobile === "-" ||
        b.custMobile === ".." ||
        b.custPhone === "-" ||
        b.custPhone === "..";

      if (isSpecialA && !isSpecialB) return 1;
      if (!isSpecialA && isSpecialB) return -1;
      return 0;
    });

    return filtered;
  }, [transactions, selectedStore, selectedFilter, nameSearch, numberSearch]);

  const handleFilterChange = (event) => {
    setSelectedFilter(event.target.value);
  };

  const handleRowClick = (index) => {
    setSelectedItem(selectedItem === index ? null : index);
  };

  const exportCsv = () => {
    const header = "Customer,Email,Mobile Number,Phone Number,RFM Type\n";
    const rows = filteredCustomers
      .map(
        (customer) =>
          `"${customer.custName}","${customer.custEmail}","${customer.custMobile}","${customer.custPhone}","${selectedFilter}"`
      )
      .join("\n");

    const csvContent = "data:text/csv;charset=utf-8," + header + rows;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "customers_breakdown.csv");
    document.body.appendChild(link); // Required for FF

    link.click(); // This will download the file
    document.body.removeChild(link); // Clean up
  };

  // const exportTransactionsToCsv = (transactions) => {
  //   const header =
  //     "Customer Name, Transaction Count, Average Sales, Last Transaction Date, Number of Days last Transaction, RFM Type, Transaction Date, Spend Total\n";
  //   const rows = transactions
  //     .map((t) => `"${t.date}",${parseFloat(t.totalSpend).toFixed(2)}`)
  //     .join("\n");

  //   const csvContent = "data:text/csv;charset=utf-8," + header + rows;
  //   const encodedUri = encodeURI(csvContent);
  //   const link = document.createElement("a");
  //   link.setAttribute("href", encodedUri);
  //   link.setAttribute("download", "customer_transactions.csv");
  //   document.body.appendChild(link); // Required for FF
  //   link.click(); // This will download the file
  //   document.body.removeChild(link); // Clean up
  // };

  const exportTransactionsToCsv = (customer) => {
    // Assuming customer object contains all the necessary data including a transactions array
    const customerName = customer.custName;
    const transactionCount = customer.purchaseCount;
    const averageSales = (customer.totalSpend / transactionCount).toFixed(2);
    const lastTransactionDate = customer.lastPurchaseDate.toLocaleDateString();
    const daysSinceLastTransaction = customer.daysSinceLastPurchase;
    const rfmType = selectedFilter; // Ensure selectedFilter is accessible or passed to the function

    // Start with customer information
    let csvContent = `Customer Name,Transaction Count,Average Sales,Last Transaction Date,Number of Days Since Last Transaction,RFM Type\n`;
    csvContent += `"${customerName}",${transactionCount},${averageSales},"${lastTransactionDate}",${daysSinceLastTransaction},"${rfmType}"\n`;

    // Add a blank row
    csvContent += "\n"; // This adds a blank row
    // Add header for transactions
    csvContent += "Transaction Date,Spend Total\n";

    // Append transactions
    customer.transactions.forEach((transaction) => {
      const transactionDate = transaction.date;
      const spendTotal = transaction.totalSpend.toFixed(2);
      csvContent += `"${transactionDate}",${spendTotal}\n`;
    });

    // Convert the CSV string to a downloadable file
    const encodedUri = encodeURI("data:text/csv;charset=utf-8," + csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "customer_transactions.csv");
    document.body.appendChild(link); // Required for FF
    link.click(); // This will download the file
    document.body.removeChild(link); // Clean up
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-1 mt-4">
        <h2 className="fw-bold text-capitalize">
          <span style={{ color: "#83c14a" }}>Customer</span>
          <span style={{ color: "black" }}> Breakdown</span>
        </h2>
        <Export exportCsv={exportCsv} />
        {/* Filter dropdown */}
      </div>
      <div className="row">
        <div className="col-sm-3 mb-3">
          <select
            className="form-select"
            onChange={(e) => setSelectedStore(e.target.value)}
          >
            <option value="All">All Stores</option>
            {uniqueStores.map((store, index) => (
              <option key={index} value={store}>
                {store}
              </option>
            ))}
          </select>
        </div>
        <div className="col-sm-3 mb-3">
          <select className="form-select " onChange={handleFilterChange}>
            <option value="All">All</option>
            <option value="loyalists">Loyalist</option>
            <option value="potentialLoyalists">Potential Loyalist</option>
            <option value="promising">PROMISING</option>
            <option value="hesitant">HESITANT</option>
            <option value="needAttention">NEED ATTENTION</option>
            <option value="detractors">DETRACTORS</option>
          </select>
        </div>
        <div className="col-sm-3 mb-3">
          <input
            onChange={(e) => setNameSearch(e.target.value)}
            className="form-control"
            placeholder="Search Name"
          />
        </div>
        <div className="col-sm-3 mb-3">
          <input
            className="form-control"
            onChange={(e) => setNumberSearch(e.target.value)}
            placeholder="Search Mobile Number"
          />
        </div>
        <div>
          <p>Total: {filteredCustomers.length}</p>
        </div>
      </div>

      {/* Table displaying the filtered customers */}
      <InfiniteScroll>
        <table className="table">
          <thead>
            <tr>
              <th style={{ backgroundColor: "#83c14a", color: "white" }}>
                Customer
              </th>
              <th style={{ backgroundColor: "#83c14a", color: "white" }}>
                Email
              </th>
              <th style={{ backgroundColor: "#83c14a", color: "white" }}>
                Mobile Number
              </th>
              <th style={{ backgroundColor: "#83c14a", color: "white" }}>
                Phone Number
              </th>
              <th style={{ backgroundColor: "#83c14a", color: "white" }}>
                RFM Type
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredCustomers.length > 0 ? (
              filteredCustomers
                // .filter((customer) => customer.custName !== "-")
                .map((customer, index) => {
                  // const rfmType =
                  //   Object.keys(
                  //     getCustomerCategories(transactions, selectedStore)
                  //   ).find((key) =>
                  //     getCustomerCategories(transactions, selectedStore)[
                  //       key
                  //     ].includes(customer)
                  //   ) || selectedFilter;

                  return (
                    <React.Fragment key={customer.id || index}>
                      <tr onClick={() => handleRowClick(index)}>
                        <td>{customer.custName}</td>
                        <td>{customer.custEmail}</td>
                        <td>{customer.custMobile}</td>
                        <td>{customer.custPhone}</td>
                        <td>{selectedFilter}</td>
                      </tr>
                      {selectedItem === index && (
                        <tr>
                          <td colSpan="5">
                            <ul
                              style={{ listStyle: "none", paddingTop: "12px" }}
                            >
                              <li>
                                <span style={{ fontWeight: "bold" }}>
                                  Customer Name:
                                </span>{" "}
                                {customer.custName}
                              </li>
                              <li>
                                <span style={{ fontWeight: "bold" }}>
                                  Transaction Count:
                                </span>{" "}
                                {customer.purchaseCount}
                              </li>
                              <li>
                                <span style={{ fontWeight: "bold" }}>
                                  Average Sales:
                                </span>{" "}
                                <span>
                                  {new Intl.NumberFormat("en-PH", {
                                    style: "currency",
                                    currency: "PHP",
                                  }).format(customer.totalSpend)}
                                </span>
                              </li>
                              <li>
                                <span style={{ fontWeight: "bold" }}>
                                  Last Transaction Date:
                                </span>{" "}
                                {customer.lastPurchaseDate.toLocaleDateString()}
                              </li>
                              <li>
                                <span style={{ fontWeight: "bold" }}>
                                  Number of Days last Transaction:
                                </span>{" "}
                                {customer.daysSinceLastPurchase}
                              </li>
                              <li>
                                <span style={{ fontWeight: "bold" }}>
                                  RFM Type:
                                </span>{" "}
                                {selectedFilter}
                              </li>
                              <Button
                                className="me-2 btn"
                                style={{
                                  backgroundColor: "#83c14a",
                                  color: "white",
                                }}
                                onClick={() =>
                                  setShowTransaction(!showTransaction)
                                }
                              >
                                Show Customer Transaction
                              </Button>
                              <Button
                                className=" btn"
                                style={{
                                  backgroundColor: "#1064ab",
                                  color: "white",
                                }}
                                onClick={() =>
                                  exportTransactionsToCsv(customer)
                                }
                              >
                                Export Transactions
                              </Button>

                              {showTransaction &&
                                customer.transactions?.map(
                                  (transaction, transactionIndex) => (
                                    <li key={transactionIndex}>
                                      <span style={{ fontWeight: "bold" }}>
                                        Transaction Date:
                                      </span>{" "}
                                      {transaction.date}
                                      <span
                                        style={{
                                          fontWeight: "bold",
                                          paddingLeft: "10px",
                                        }}
                                      >
                                        Spend Total:
                                      </span>
                                      {new Intl.NumberFormat("en-US", {
                                        style: "currency",
                                        currency: "PHP", // Change currency code as per your requirement
                                      }).format(transaction.totalSpend)}
                                    </li>
                                  )
                                )}
                            </ul>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  );
                })
            ) : (
              <tr>
                <td colSpan="3" style={{ textAlign: "center" }}>
                  No Data
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </InfiniteScroll>
    </>
  );
}

export default CustomerBreakdownPage;
