import BaseListPresenter from "../../../../base/BaseListPresenter";
import { getCurrentUserUseCase } from "../../../../usecases/user";

class TopItemRevenuePresenter extends BaseListPresenter {
  componentDidMount() {
    this.init();
    this.getObjects();
  }
  init() {
    this.limit = 10000;
    this.where = {};
    this.search = {};
    this.filter = {};
    this.filterDate = {};
    this.filterStore = {};
    this.include = ["all"];
    this.keys = undefined; // if keys are specified, only those keys will be returned
    this.sort = { createdAt: -1 };
    this.progress = true;
    this.reset();
  }

  createQuery() {
    const skip = (this.current - 1) * this.limit;
    const query = {
      limit: this.limit,
      skip: skip,
      where: {
        ...this.where,
        ...this.search,
        ...this.filter,
        ...this.filterDate,
        store_code: this.filterStore
      },
      include: this.include,
    };
    if (this.sort) {
      query.sort = this.sort;
    }
    // const keys = this.keys || this.view.getKeys() || [];
    // if (keys.length > 0) {
    //   query.keys = keys;
    // }
    return query;
  }

  onChangeDate(where) {
    this.filterDate = where;
    this.getObjects();
  }

  onChangeStore(where) {
    this.filterStore = where;
    this.getObjects();
  }

  async findObjects() {
    const collection = this.view.getCollectionName();
    const query = this.createQuery();

    try {
      const user = await getCurrentUserUseCase().execute();
      const storeQuery = { include: ["all"], where: { email: user.email } };
      this.store = await this.findObjectUseCase.execute("users", storeQuery);
      const store = this.store[0].storeAnalytic;
      this.view.setStore(store);

      this.showProgress();
      this.findObjectUseCase.abort();

      const objects = await this.findObjectUseCase.execute(collection, query);

      this.objects = this.objects.concat(objects);
      console.log("KAHIT ANO 2", this.objects);
      this.view.setTotal(this.objects.length);
      this.view.setObjects(this.objects);

      // this.getObjects();
      this.hideProgress();
    } catch (error) {
      this.hideProgress();
      this.view.showError(error);
    }
    this.progress = false;
  }
}

export default TopItemRevenuePresenter;
