import React, { Component } from "react";
import NavBar from "../../components/navbar";
import TotalGuestAvgSpendPage from "./totalGuestAvg/TotalGuestAvgSpendPage";
import TotalGuestDayPage from "./totalGuestDay/TotalGuestDayPage";
import TotalGuestTimePage from "./totalGuestTime/TotalGuestTimePage";
import CumulativeTransactionPage from "./cumulativeTransaction/CumulativeTransactionPage";

class TotalGuestPage extends Component {
  render() {
    return (
      <>
        <NavBar />
        <div className="overflow-auto">
          <div className="p-3 p-lg-4">
            <div className="d-flex justify-content-between align-items-center">
              <h1
                className="fw-bold mt-1 text-capitalize"
                style={{ color: "#83c14a" }}
              >
                Total Guest
              </h1>
            </div>
            <TotalGuestAvgSpendPage />
            <CumulativeTransactionPage />
            <TotalGuestDayPage />
            <TotalGuestTimePage />
          </div>
        </div>
      </>
    );
  }
}

export default TotalGuestPage;
