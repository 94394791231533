import React from "react";
import BaseListPage from "../../../../base/BaseListPage";
import withRouter from "../../../../withRouter";
import { Bar } from "react-chartjs-2";
import BottomItemRevenuePresenter from "./BottomItemRevenuePresenter";
import {
  countObjectUseCase,
  findObjectUseCase,
} from "../../../../usecases/object";
import DateRange from "../../../../components/DateRange";
import { Progress } from "nq-component";
import Export from "../../../../components/Export";
import printJS from "print-js";

class BottomItemRevenuePage extends BaseListPage {
  constructor(props) {
    super(props);
    this.chartRef = React.createRef();
    this.presenter = new BottomItemRevenuePresenter(
      this,
      findObjectUseCase(),
      countObjectUseCase()
    );

    this.state = {
      objects: [],
      selected: [],
      progress: true,
      count: 0,
      where: {},
      itemData: {},
      range: "",
      storess: "",
      store: [],
      printThisData: [],
    };
  }

  getCollectionName() {
    return "transactions";
  }

  setObjects(objects) {
    this.setState({ objects });
  }

  getChartData() {
    const sortedItems = Object.entries(this.state.itemData)
      .sort((a, b) => a[1] - b[1])
      .slice(0, 30);

    const labels = sortedItems.map(([itemName]) => itemName);
    const salesData = sortedItems.map(([, sales]) => sales);

    return {
      labels: labels.reverse(),
      datasets: [
        {
          indexAxis: "y",
          label: "Total Sales",
          data: salesData.reverse(),
          backgroundColor: "#006096",
          borderColor: "#006096",
          borderWidth: 1,
        },
      ],
    };
  }

  componentDidMount() {
    this.presenter.componentDidMount();
    if (this.state.objects.length > 0) {
      this.calculateTotalSalesByItem();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.objects.length > 0 &&
      prevState.objects !== this.state.objects
    ) {
      this.calculateTotalSalesByItem();
    }
  }

  async calculateTotalSalesByItem() {
    const totalSalesByItem = await this.calculateTotalSalesByItemAsync(
      this.state.objects
    );
    this.setState({ itemData: totalSalesByItem });
  }

  async calculateTotalSalesByItemAsync(objects) {
    return objects.reduce((acc, transaction) => {
      transaction.items.forEach((item) => {
        const itemName = item.description;
        const itemQuantity = item.quantity;
        const unitPrice = parseFloat(
          item.customProperties.find((prop) => prop.codeName === "UNIT_PRICE")
            ?.value || 0
        );
        const totalSales = acc[itemName] || 0;
        acc[itemName] = totalSales + unitPrice * itemQuantity;
      });
      return acc;
    }, {});
  }

  onChangeDate(where) {
    this.presenter.onChangeDate(where);
    this.setState({ range: where });
  }

  onChangeStore(where) {
    this.setState({ storess: where });
    this.presenter.onChangeStore(where);
  }

  setStore(store) {
    this.setState({ store });
  }

  printChart() {
    setTimeout(() => {
      printJS({
        printable: "chart-container",
        type: "html",
        header: `<div style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 1rem;">
                   <img src="/figaro.png" alt="Figaro" style="height: 50px;"/>
                   <h4 style="margin: 0 auto;">FIGARO COFFEE GROUP</h4>
                   <img src="/angels.png" alt="Angel's Pizza" style="height: 50px;"/>
                 </div>`,
        css: "./index.css",
        scanStyles: false,
        style: ".print-only-content { display: block; }",
      });
    }, 500);
  }

  formatSales(sales) {
    return sales.toLocaleString("en-US", {
      style: "currency",
      currency: "PHP",
    });
  }

  convertToCSV(objArray) {
    const array =
      typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
    let str =
      `${Object.keys(array[0])
        .map((value) => `"${value}"`)
        .join(",")}` + "\r\n";

    for (let i = 0; i < array.length; i++) {
      let line = "";

      for (let index in array[i]) {
        if (line !== "") line += ",";
        line += `"${array[i][index]}"`;
      }
      str += line + "\r\n";
    }
    return str;
  }

  exportToCSV = () => {
    const { storess, range, itemData } = this.state;

    const csvData = Object.entries(itemData)
      ?.sort((a, b) => a[1] - b[1])
      .slice(0, 30)
      .map((data, index) => {
        return {
          Store: storess,
          Date: `${range.createdAt["$gte"].split("T")[0]} ${
            range.createdAt["$gte"].split("T")[0]
          }`,
          Item_Name: data[0],
          Revenue: data[1],
        };
      });

    const csvString = this.convertToCSV(csvData);
    const a = document.createElement("a");
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);

    a.href = url;
    const d = `${range.createdAt["$gte"].split("T")[0]} ${
      range.createdAt["$gte"].split("T")[0]
    }`;
    a.download = `${storess}-${d}-bottom-item-revenue.csv`;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  render() {
    const chartData = this.getChartData();
    return (
      <>
        <div className="d-flex justify-content-between align-items-center">
          <h1 className="fw-bold mt-3 text-capitalize text-black">
            Bottoms <span style={{ color: "#83c14a" }}>Menu Items</span> by{" "}
            <span style={{ color: "#D1DE48" }}>Revenue</span>
          </h1>
          <Export exportPdf={this.printChart} exportCsv={this.exportToCSV} />
        </div>
        {/* <div className="row mb-2 ">
					<div className="col-sm-4" style={{ maxWidth: "max-content" }}>
						<DateRange
							onChange={this.onChangeDate.bind(this)}
							field="createdAt"
						/>
					</div>

					<div className="col-sm-3">
						<select
							className="form-select"
							// value={this.state.selectedStoreCode}
							onChange={(e) => this.onChangeStore(e.target.value)}
							disabled={this.state.range ? false : true}
						>
							<option value="">Select Store</option>
							{this.state.store.map((code, index) => (
								<option key={index} value={code.name}>
									{code.name}
								</option>
							))}
						</select>
					</div>
				</div> */}
        {this.state.objects?.length > 0 ? (
          <div ref={this.chartRef} id="chart-container">
            <div className="card">
              <Bar data={chartData} />
            </div>
            <div className="print-only-content">
              <h2>Report Details</h2>
              <div className="d-flex justify-content-between align-items-center print-only-content">
                <h3>Brand: Angels Pizza</h3>
                <h4 className="mx-auto"></h4>
                <h3>Store: {this.state.storess}</h3>
                <h3>
                  Date Range:{" "}
                  <span>
                    {this.state.range &&
                      this.state.range?.createdAt["$gte"].split("T")[0]}
                  </span>{" "}
                  <span>
                    {this.state.range &&
                      this.state.range?.createdAt["$lte"].split("T")[0]}
                  </span>
                </h3>
                <h3>Bottom Menu Items By Revenue</h3>
              </div>
              <div className="print-only" style={{ fontSize: "20px" }}>
                <table className="table text-center">
                  <thead>
                    <tr className="d-flex">
                      <th>Menu Item</th>
                      <th className="ms-5">Revenue</th>
                    </tr>{" "}
                  </thead>
                  <tbody>
                    {Object.entries(this.state.itemData)
                      ?.sort((a, b) => a[1] - b[1])
                      .slice(0, 30)
                      .map((data, index) => (
                        <tr className="d-flex" key={data}>
                          <td>{data[0]}</td>
                          <td>{this.formatSales(data[1])}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : this.state.storess ? (
          <div className="text-center mt-5">
            <Progress />
            <h6>Processing... Please wait.</h6>
          </div>
        ) : (
          <div className="text-center align-items-center mt-4">
            <img
              src="./choosefirst.png"
              style={{ height: "200px", width: "200px" }}
            />
            <h4>To proceed, please select a date first</h4>
          </div>
        )}
      </>
    );
  }
}

export default withRouter(BottomItemRevenuePage);
